import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import { store } from "store/index";

import GridContainer from "components/grid/GridContainer.js";
import axios from "axios";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import moment from "moment";

//JS
import ModalSolicitud from "../Dialog/ModalSolicitud";

// IMG
import imgObservacion from "assets/img/solicitud_trabajo/iconos/motivo_celeste.svg";

//CSS
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

export default class Enviados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      abrirModal: false,
      dataSolicitud: null,
      tituloModalSolicitud: "",
      vistaModal: 0,
      seleccion: 1,
    };
  }

  componentDidMount() {
    this.solicitudes("2,5,6,9");
    this.colorTabla();
  }

  solicitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";

    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
          tipo: 2,
          filtro: 2,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            solicitudes: res.data.datos,
          });
        } else {
          this.setState({
            todas_solicitudes: [],
          });
        }
      });
  };

  colorTabla = () => {
    const tableHeaders = document.querySelectorAll(".MuiTableCell-head");
    tableHeaders.forEach((header) => {
      header.style.setProperty("border-bottom", "4px solid #3fb8b7", "important");
    });
  };

  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  irTicket = (cod) => {
    this.props.history.push({
      pathname: "/solicitud_trabajo",
      params: { cod },
    });
  };

  abrirModalMotivo = (data, titulo, vista) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo, vistaModal: vista });
  };

  render() {
    const { seleccion } = this.state;
    return (
      <>
        <GridContainer style={{ width: "90%" }}>
          <Grid item xs={12} sm={12}>
            <div className={SolicitudTrabajo.divBorderTableSolicitudCesfamEnviado} style={{ paddingLeft: "20px" }}>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "Fecha de ingreso",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.fecha_solicitud.split(" ")[0]}</p>
                        <p>{rowData.fecha_solicitud.split(" ")[1]} hrs</p>
                      </React.Fragment>
                    ),
                    field: "fecha_solicitud",
                  },
                  {
                    title: "Fecha de confirmación",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.fecha_recepcion.split(" ")[0]}</p>
                        <p>
                          {rowData.fecha_recepcion.split(" ")[1]} {rowData.fecha_recepcion.split(" ")[2]}
                        </p>
                      </React.Fragment>
                    ),
                    field: "fecha_recepcion",
                  },
                  {
                    title: "N° de ticket ",
                    field: "sol_codigo",
                  },
                  {
                    title: "Nombre",
                    field: "sol_autoriza",
                  },
                  {
                    title: "CESFAM",
                    field: "ces_nombre",
                  },
                  {
                    title: "Tipo de solicitud",
                    field: "tipo_solicitud",
                  },
                  {
                    title: "Motivo de ticket",
                    render: (rowData) => (
                      <React.Fragment>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img
                            src={imgObservacion}
                            style={{ width: "30px", cursor: "pointer" }}
                            onClick={() => this.abrirModalMotivo(rowData, "Motivo", 1)}
                          />
                        </div>
                      </React.Fragment>
                    ),
                    field: "sol_motivo",
                  },
                  {
                    title: "Fecha de entrega estimada",
                    field: "sol_entrega_aprox_cliente",
                  },
                  {
                    title: "Estado",
                    render: (rowData) => (
                      <React.Fragment>
                        <>
                          <p>
                            {rowData.sol_estado == 0
                              ? "En espera de aprobación de Cesfam"
                              : rowData.sol_estado == 1
                              ? "Rechazado por el Cesfam"
                              : rowData.sol_estado == 2
                              ? "Solicitud recibida por HoraSalud"
                              : rowData.sol_estado == 3
                              ? "Solicitud aprobada"
                              : rowData.sol_estado == 4
                              ? "Solicitud rechazada"
                              : rowData.sol_estado == 5
                              ? "Solicitud asignada a un ejecutivo"
                              : rowData.sol_estado == 6
                              ? "Solicitud asignada a un especialista en calidad"
                              : rowData.sol_estado == 7
                              ? "Entregada"
                              : rowData.sol_estado == 8
                              ? "Solicitud en pausa"
                              : rowData.sol_estado == 9
                              ? "Solicitud asignada a un especialista en calidad"
                              : null}
                          </p>
                        </>
                      </React.Fragment>
                    ),
                  },
                ]}
                data={this.state.solicitudes}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  toolbar: false,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: <span style={{ fontSize: "1.5rem" }}>No tiene tickets pendientes</span>,

                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </div>

            {this.state.abrirModal && (
              <ModalSolicitud
                data={this.state.dataSolicitud}
                titulo={this.state.tituloModalSolicitud}
                salir={this.cerrarModal}
                vista={this.state.vistaModal}
                solicitudes={this.solicitudes}
              />
            )}
          </Grid>
        </GridContainer>
      </>
    );
  }
}
