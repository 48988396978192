// core components
import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "store/index";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import axios from "axios";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import Countdown from "react-countdown";

//JS
import ModalSolicitud from "../Dialog/ModalSolicitud";

// IMG
import respuesta from "assets/img/solicitud_trabajo/iconos/respuesta.svg";

//CSS
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

export default class SolicitudesRespuestas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      abrirModal: false,
    };
  }

  componentDidMount() {
    this.solicitudes();
  }

  solicitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
          tipo: 6,
          visitas: 1,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.estado) {
          this.setState({
            todas_solicitudes: res.data.datos,
          });
        } else {
          this.setState({
            todas_solicitudes: [],
          });
        }
      });
  };

  abrirModalMotivo = (data, titulo, vista) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo, vistaModal: vista });
  };
  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  render() {
    return (
      <>
        <GridContainer style={{ height: "85%", width: "100%" }}>
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <div style={{ marginBottom: 100, overflowY: "scroll" }}>
                <Grid item xs={12} sm={12}>
                  <div className="divBordeTablaHistorialHoras" style={{ paddingLeft: "20px" }}>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: "Fecha de ingreso",
                          field: "fecha_solicitud",
                          cellStyle: {
                            padding: "10px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "N° de Ticket",
                          render: (rowData) => (
                            <React.Fragment>
                              <p onClick={() => this.irTicket(rowData.sol_codigo)}>{rowData.sol_codigo}</p>
                            </React.Fragment>
                          ),
                          field: "sol_codigo",
                          cellStyle: {
                            padding: "10px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Tipo de solicitud",
                          field: "tipo_solicitud",
                          cellStyle: {
                            padding: "10px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Nombre",
                          field: "sol_autoriza",
                          cellStyle: {
                            padding: "10px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Motivo de ticket",
                          render: (rowData) => (
                            <React.Fragment>
                              <textarea
                                className={SolicitudTrabajo.textAreaIngresado}
                                style={{ background: "transparent" }}
                                value={rowData.sol_motivo}
                                disabled></textarea>
                            </React.Fragment>
                          ),
                          field: "sol_motivo",

                          cellStyle: (rowData) => ({
                            width: "300px",
                            padding: "10px", // Aplica tus estilos aquí
                          }),
                        },

                        {
                          title: "Fecha respuesta de HoraSalud",
                          field: "fecha_respuesta",
                          cellStyle: {
                            padding: "10px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Respuesta",
                          render: (rowData) => (
                            <React.Fragment>
                              {rowData.fcv_sol_tipo && rowData.fcv_fecha_aceptada == 1
                                ? "Fecha Aceptada"
                                : rowData.fcv_sol_tipo && rowData.fcv_fecha_aceptada == 0
                                ? "Fechas de visita"
                                : rowData.fcv_sol_tipo && rowData.fcv_fecha_aceptada == 2
                                ? "Se solicita otra fecha"
                                : "En proceso"}
                              <br />
                              {rowData.fcv_sol_tipo && rowData.fcv_fecha_aceptada == 0 && (
                                <img
                                  src={respuesta}
                                  style={{ width: 30, cursor: "pointer" }}
                                  onClick={
                                    rowData.fcv_sol_tipo == 1 && rowData.fcv_fecha_aceptada == 0
                                      ? () => this.abrirModalMotivo(rowData, "Respuesta a solicitud", 12)
                                      : rowData.fcv_sol_tipo == 2 && rowData.fcv_fecha_aceptada == 0
                                      ? () => this.abrirModalMotivo(rowData, "Respuesta a solicitud", 13)
                                      : null
                                  }
                                />
                              )}
                            </React.Fragment>
                          ),
                          field: "sol_estado",
                          cellStyle: {
                            padding: "10px", // Aplica tus estilos aquí
                          },
                        },
                      ]}
                      data={this.state.todas_solicitudes}
                      options={{
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        rowStyle: (rowData) => ({
                          padding: 5,
                        }),
                      }}
                      localization={{
                        header: {
                          actions: "Acciones",
                        },
                        pagination: {
                          labelDisplayedRows: "{from}-{to} de {count}",
                          labelRowsSelect: "filas",
                          firstTooltip: "primera",
                          previousTooltip: "anterior",
                          nextTooltip: "siguiente",
                          lastTooltip: "última",
                        },
                        toolbar: {
                          nRowsSelected: "{0} fila(s) seleccionadas",
                          searchTooltip: "Buscar",
                          searchPlaceholder: "Buscar",
                        },
                        body: {
                          emptyDataSourceMessage: <span style={{ fontSize: "1.5rem" }}>No tiene tickets pendientes</span>,

                          filterRow: {
                            filterTooltip: "Buscar",
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>
              </div>
            </div>
          </GridItem>
        </GridContainer>

        {this.state.abrirModal && (
          <ModalSolicitud
            data={this.state.dataSolicitud}
            titulo={this.state.tituloModalSolicitud}
            salir={this.cerrarModal}
            vista={this.state.vistaModal}
            solicitudes={this.solicitudes}
            cuentaSolicitudesTerreno={() => this.props.cuentaSolicitudesTerreno()}
          />
        )}
      </>
    );
  }
}
