import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import { store } from "./../../store/index";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid, List, ListItem } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { confirmAlert } from "react-confirm-alert"; // Import
import { NotificationManager } from "react-notifications";

import Tooltip from "@material-ui/core/Tooltip";
import vista_1 from "../../assets/img/usuarios/iconos_colores/1_datos_personales.svg";
import vista_2 from "../../assets/img/profesionales/nuevos_iconos/2_agenda_dia.svg";
import vista_3 from "../../assets/img/profesionales/nuevos_iconos/3_editar_horas.svg";
import vista_4 from "../../assets/img/profesionales/nuevos_iconos/4_cargar_horarios.svg";

//JS
import Titulo from "./../../components/titulo/Titulo.js";
import AgregarProfesional from "./../../components/Profesionales/AgregarProfesional";
import ModificarProfesional from "./../../components/Profesionales/ModificarProfesional";
import DatosProfesional from "./../../components/Profesionales/DatosProfesional";
import VerHorario from "./../../components/Profesionales/VerHorario";
import BloqueoHoras from "./../../components/Profesionales/BloqueoHoras";
import CargarHorario from "./../../components/Profesionales/CargarHorario";
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

//IMG
import signo from "./../../assets/img/signo.svg";
import lapiz from "../../assets/img/profesionales/nuevos_iconos/lapiz.svg";
import equis from "../../assets/img/profesionales/icono_eliminar.svg";
import tamanio_letra from "../../assets/img/usuarios/iconos_colores/tamaño_letra.svg";

// CSS
import "./../../assets/css/general.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./../../assets/css/profesionales.css";
import InformacionUsuarioCSS from "./../../assets/css/usuarios.module.css";

export default class BuscarProfesional extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profesionales: [],
      proId: "",
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      formErrors: {},
      nombreprofesional: "",
      agregarProfesional: false,
      menuDatosPersonales: false,
      menuVerHorario: false,
      menuBloqueoHorario: false,
      menuCargaHorario: false,
      busquedaProfesional: false,
      modificarProfesional: false,
      herramientas: store.getState().herramientas,

      tamanio: store.getState().letraTam,
    };

    this.initialState = this.state;
  }
  componentDidMount() {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Buscar profesional <span title='Permite ver información de profesionales y sus horarios, así como también gestionar su agenda'><img src=" + signo + "></img></span>";
    this.traerProfesionales();
  }

  eliminarProfesional = () => {
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea eliminar este profesional?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: this.confirmarEliminarProfesional,
          className: "btnAceptar",
        },
      ],
    });
  };

  confirmarEliminarProfesional = () => {
    setTimeout(() => {
      confirmAlert({
        title: "",
        message: "¿Esta completamente segur@ que desea eliminar este profesional?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => {
              var data = {
                uspId: this.state.uspId,
              };
              let apiUrl = global.config.modulos.profesionales + "por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam;
              const options = {
                method: "DELETE",
                data: data,
                headers: authHeader(),
              };
              axios(apiUrl, options).then((res) => {
                this.limpiarProfesional();
                this.mostrarAlertaEliminado();
                this.setState({
                  menuDatosPersonales: false,
                  nombreprofesional: "",
                });
                this.traerProfesionales();
              });
            },
            className: "btnAceptar",
          },
        ],
      });
    }, 50);
  };

  asignarTamanio = () => {
    const apiUrl = global.config.modulos.usuariospanel + "por_id/" + store.getState().usp;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ tamanio: res.data.usp_tam_letra });
      this.cambiarTamanio(res.data.usp_tam_letra);
    });
  };

  mostrarAlertaEliminado() {
    NotificationManager.success("Profesional eliminado con éxito");
  }

  modificarProfesional = (e) => {
    this.setState({ modificarProfesional: true });
  };

  ocultarModificar = () => {
    this.setState({ modificarProfesional: false, menuDatosPersonales: false });
    this.setState({ menuDatosPersonales: true });
  };

  mostrarAlertaEliminado() {
    NotificationManager.success("El profesional fue eliminado con éxito");
  }

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + this.state.cesfam;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        this.setState(
          {
            profesionales: res.data.datos,
          },
          () => {
            this.asignarTamanio();
          }
        );
      }
    });
  }

  validaciones() {
    const { proId } = this.state;
    let formErrors = {};
    let formIsValid = true;

    var prof = document.getElementById("cmbProfesionales").value;

    if (prof == "") {
      formIsValid = false;
      formErrors["profErr"] = "Elije un profesional";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  buscarProfesional = () => {
    if (this.validaciones()) {
      this.setState({ menuDatosPersonales: false }, () => {
        axios.get(global.config.modulos.profesionales + "por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam, { headers: authHeader() }).then((res) => {
          this.setState({
            busquedaProfesional: true,
            menuDatosPersonales: true,
          });
        });
      });
    }
  };

  seleccionarMenu = () => {
    document.getElementById("datosPersonales").src = vista_1;
    document.getElementById("verHorario").src = vista_2;
    if (this.state.herramientas.includes("H5")) {
      document.getElementById("bloqueoHorario").src = vista_3;
    }
    if (this.state.herramientas.includes("H6")) {
      document.getElementById("cargaHorario").src = vista_4;
    }

    document.getElementById("verHorario").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    document.getElementById("datosPersonales").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    if (this.state.herramientas.includes("H5")) {
      document.getElementById("bloqueoHorario").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    }
    if (this.state.herramientas.includes("H6")) {
      document.getElementById("cargaHorario").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    }
  };

  cambioOpciones = (tipo) => {
    this.seleccionarMenu();

    if (tipo == 1) {
      const element = document.getElementById("datosPersonales");
      const cssClass = this.state.tamanio < 3 ? InformacionUsuarioCSS.imagenUsuarioSeleccionado : InformacionUsuarioCSS.imagenUsuarioSeleccionado_2;

      element.classList.add(cssClass);

      this.activarVista("menuDatosPersonales");
    } else if (tipo == 2) {
      if (this.state.herramientas.includes("H5")) {
        const element = document.getElementById("bloqueoHorario");
        const cssClass = this.state.tamanio < 3 ? InformacionUsuarioCSS.imagenUsuarioSeleccionado : InformacionUsuarioCSS.imagenUsuarioSeleccionado_2;

        element.classList.add(cssClass);

        this.activarVista("menuBloqueoHorario");
      }
    } else if (tipo == 3) {
      if (this.state.herramientas.includes("H6")) {
        const element = document.getElementById("cargaHorario");
        const cssClass = this.state.tamanio < 3 ? InformacionUsuarioCSS.imagenUsuarioSeleccionado : InformacionUsuarioCSS.imagenUsuarioSeleccionado_2;

        element.classList.add(cssClass);

        this.activarVista("menuCargaHorario");
      }
    } else if (tipo == 4) {
      const element = document.getElementById("verHorario");
      const cssClass = this.state.tamanio < 3 ? InformacionUsuarioCSS.imagenUsuarioSeleccionado : InformacionUsuarioCSS.imagenUsuarioSeleccionado_2;

      element.classList.add(cssClass);
      this.activarVista("menuVerHorario");
    }
  };

  activarVista(nombre) {
    this.setState({
      menuDatosPersonales: false,
      menuBloqueoHorario: false,
      menuCargaHorario: false,
      menuVerHorario: false,
      [nombre]: true,
    });
  }

  agregarProfesional = (e) => {
    this.setState({ agregarProfesional: true });
  };

  ocultarAgregar = () => {
    this.setState({ agregarProfesional: false });
    this.traerProfesionales();
  };

  limpiarProfesional = () => {
    this.setState({
      busquedaProfesional: false,
      menuDatosPersonales: false,
      menuBloqueoHorario: false,
      menuVerHorario: false,
      menuCargaHorario: false,
    });
  };

  cambiarTamanio = (tipo, transition) => {
    this.removerClasses();
    const allElements = document.querySelectorAll("[name='labelTam']");
    const allElements_2 = document.querySelectorAll("[name='aCircle']");
    const AgeLapiz = document.querySelectorAll("[name='lapizAge']");
    console.log(AgeLapiz);

    const imgCambioOpciones = document.querySelectorAll("[name='img_cambioOpciones']");

    const classMap = {
      1: InformacionUsuarioCSS.label_11,
      2: InformacionUsuarioCSS.label_13,
      3: InformacionUsuarioCSS.label_16,
      4: InformacionUsuarioCSS.label_20,
    };

    const imageIdMap = {
      1: "img1",
      2: "img2",
      3: "img3",
      4: "img4",
    };

    if (allElements.length === 0) {
      if (tipo in classMap) {
        const imgId = imageIdMap[tipo];
        document.getElementById(imgId).style.opacity = 1;
      }
    } else {
      allElements.forEach((element) => {
        if (tipo in classMap) {
          element.classList.add(classMap[tipo]);
          if (transition == 1) {
            element.style.transition = "all 300ms linear";
          }
          const imgId = imageIdMap[tipo];
          document.getElementById(imgId).style.opacity = 1;
        }
      });
    }
    allElements_2.forEach((element) => {
      if (tipo == "1" || tipo == "2") {
        element.classList.add(InformacionUsuarioCSS.btn_peque);
      } else {
        element.classList.remove(InformacionUsuarioCSS.btn_peque);
      }
    });

    AgeLapiz.forEach((element) => {
      if (tipo == "1" || tipo == "2") {
        element.classList.add(InformacionUsuarioCSS.lapizAgenda_peque);
      } else {
        element.classList.remove(InformacionUsuarioCSS.lapizAgenda_peque);
      }
    });

    imgCambioOpciones.forEach((element) => {
      if (tipo == "1" || tipo == "2") {
        element.classList.replace(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2, InformacionUsuarioCSS.imagenUsuarioSeleccionado);

        element.classList.add(InformacionUsuarioCSS.img_tam);
      } else if (tipo == "3" || tipo == "4") {
        element.classList.replace(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
        element.classList.add(InformacionUsuarioCSS.img_tam_2);
      }
    });

    axios
      .patch(
        global.config.modulos.usuariospanel + "cambio_tamanio_letra/",
        {
          usuarioPanel: store.getState().usp,
          tipo_letra: tipo,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        this.setState({ tamanio: tipo });
      });
  };

  removerClasses = () => {
    const allElements = document.querySelectorAll("[name='labelTam']");
    const allElements_img = document.querySelectorAll("[name='img_tamTexto']");
    const allElements_img_2 = document.querySelectorAll("[name='img_cambioOpciones']");

    allElements.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.label_11, InformacionUsuarioCSS.label_13, InformacionUsuarioCSS.label_16, InformacionUsuarioCSS.label_20);
    });
    allElements_img.forEach((element) => {
      element.style.opacity = 0.3;
    });
    allElements_img_2.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.img_tam, InformacionUsuarioCSS.img_tam_2);
    });
  };

  render() {
    const { profErr } = this.state.formErrors;

    const elegirProfesional = (proid, nombreprofesional) => {
      this.setState({
        proId: proid,
        nombreprofesional: nombreprofesional,
      });
    };
    return (
      <div className="middle-area" style={{ width: "84%" }}>
        <div className={InformacionUsuarioCSS.seleccioonTamanio}>
          <img src={tamanio_letra} id="img4" name="img_tamTexto" onClick={() => this.cambiarTamanio(4, 1)} style={{ width: "60%", cursor: "pointer", opacity: "0.3" }} />
          <img src={tamanio_letra} name="img_tamTexto" id="img3" onClick={() => this.cambiarTamanio(3, 1)} style={{ width: "50%", cursor: "pointer", opacity: "0.3" }} />
          <img src={tamanio_letra} id="img2" name="img_tamTexto" onClick={() => this.cambiarTamanio(2, 1)} style={{ width: "45%", cursor: "pointer", opacity: "0.3" }} />
          <img src={tamanio_letra} name="img_tamTexto" id="img1" onClick={() => this.cambiarTamanio(1, 1)} style={{ width: "40%", cursor: "pointer", opacity: "0.3" }} />
        </div>
        <React.Fragment>
          <Grid container spacing={3} className={InformacionUsuarioCSS.buscarProfesional}>
            {!this.state.busquedaProfesional ? (
              <React.Fragment>
                <Grid item xs={12} sm={8} style={{ maxWidth: "85%" }}>
                  <List>
                    <ListItem style={{ display: "inherit" }}>
                      <Autocomplete
                        id="cmbProfesionales"
                        onChange={function (event, res) {
                          if (res === null) {
                          } else {
                            elegirProfesional(res.prc_profesional, res.nombreprofesional);
                          }
                        }}
                        size="small"
                        options={Object.values(this.state.profesionales)}
                        getOptionLabel={(option) => option.nombreprofesional}
                        filterOptions={createFilterOptions({
                          // matchFrom: 'start',
                          stringify: (option) => option.nombreprofesional + option.especialidades,
                        })}
                        style={{
                          width: 390,
                          height: 40,
                          float: "left",
                        }}
                        renderInput={(params) => <TextField {...params} label="Profesionales" variant="outlined" />}
                      ></Autocomplete>

                      <Button
                        variant="contained"
                        onClick={this.buscarProfesional}
                        color="primary"
                        style={{
                          marginLeft: 20,
                          boxShadow: "none",
                        }}
                        className="fondoNaranjo"
                      >
                        Buscar
                      </Button>
                      {this.state.herramientas.includes("H7") ? (
                        <Button variant="contained" color="primary" className="botonesAlineados fondoVerde" onClick={this.agregarProfesional}>
                          Agregar Profesional
                        </Button>
                      ) : null}
                    </ListItem>
                    {profErr && <div style={{ color: "red", paddingBottom: 10 }}>{profErr}</div>}
                  </List>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    width: "100vw",
                    display: "flex",
                    maxWidth: "100% !important",
                    alignItems: "center",
                    paddingTop: "0",
                  }}
                >
                  <label style={{ fontSize: 20, width: "40%", marginLeft: "1vw" }}>{this.state.nombreprofesional}</label>
                  <Button
                    variant="contained"
                    color="primary"
                    className=" fondoNaranjo"
                    onClick={this.limpiarProfesional}
                    style={{
                      boxShadow: "none",
                      width: "5vw",
                      height: "5vh",
                      margin: "0 0.5vw 0 0.5vw",
                    }}
                  >
                    Limpiar
                  </Button>
                  {this.state.busquedaProfesional ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <React.Fragment>
                        <div className="divImageUser">
                          <img id="datosPersonales" name="img_cambioOpciones" className={InformacionUsuarioCSS.imagenUsuarioSeleccionado} src={vista_1} onClick={() => this.cambioOpciones(1)} />
                        </div>

                        <div className="divImageUser">
                          <img id="verHorario" name="img_cambioOpciones" className={InformacionUsuarioCSS.imagenUsuario} src={vista_2} onClick={() => this.cambioOpciones(4)} />
                        </div>

                        {this.state.herramientas.includes("H5") ? (
                          <div className="divImageUser">
                            <img id="bloqueoHorario" className={InformacionUsuarioCSS.imagenUsuario} src={vista_3} name="img_cambioOpciones" onClick={() => this.cambioOpciones(2)} />
                          </div>
                        ) : null}
                        {this.state.herramientas.includes("H6") ? (
                          <div className="divImageUser">
                            <img id="cargaHorario" className={InformacionUsuarioCSS.imagenUsuario} src={vista_4} name="img_cambioOpciones" onClick={() => this.cambioOpciones(3)} />
                          </div>
                        ) : null}
                      </React.Fragment>
                    </Grid>
                  ) : null}
                </Grid>
              </React.Fragment>
            )}

            {this.state.busquedaProfesional ? (
              <Grid item xs={12} sm={12}>
                <React.Fragment>
                  {this.state.menuDatosPersonales ? (
                    <React.Fragment>
                      {this.state.herramientas.includes("H9") ? (
                        <Tooltip title="Eliminar" aria-label="Eliminar">
                          <img className={this.state.tamanio > 2 ? InformacionUsuarioCSS.imagenBotonAccion_G : InformacionUsuarioCSS.imagenBotonAccion_P} src={equis} onClick={this.eliminarProfesional} />
                        </Tooltip>
                      ) : null}
                      {this.state.herramientas.includes("H8") ? (
                        <Tooltip title="Modificar" aria-label="Modificar">
                          <img className={this.state.tamanio > 2 ? InformacionUsuarioCSS.imagenBotonAccion_G : InformacionUsuarioCSS.imagenBotonAccion_P} src={lapiz} onClick={this.modificarProfesional} />
                        </Tooltip>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              </Grid>
            ) : null}
          </Grid>
        </React.Fragment>
        <div className="middle-content" style={{ marginTop: "0px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="buscarProfesional">
                {this.state.menuDatosPersonales ? <DatosProfesional asignarTam={this.asignarTamanio} tamanio={this.state.tamanio} profesional={this.state.proId}></DatosProfesional> : null}
                {this.state.menuVerHorario ? <VerHorario profesional={this.state.proId}></VerHorario> : null}
                {this.state.agregarProfesional ? <AgregarProfesional ocultar={this.ocultarAgregar}></AgregarProfesional> : null}
                {this.state.menuBloqueoHorario ? <BloqueoHoras asignarTam={this.asignarTamanio} tamanio={this.state.tamanio} profesional={this.state.proId} /> : null}
                {this.state.modificarProfesional ? <ModificarProfesional profesional={this.state.proId} ocultar={this.ocultarModificar}></ModificarProfesional> : null}
                {this.state.menuCargaHorario ? <CargarHorario profesional={this.state.proId}></CargarHorario> : null}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
