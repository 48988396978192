import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GraficoPrincipal from "components/Graficos/General/GraficoDemanda.js";
import Graficopr from "components/Graficos/General/GraficoHoraFinal.js";
import Grafico2 from "components/Graficos/General/GraficosDeBarras.js";
import GrafSecDem from "components/Graficos/General/GraficoDeSec.js";
import GrOfSec from "components/Graficos/General/GraficoOfertaFina.js";
import GrDemEdad from "components/Graficos/General/GraficoDemEdad.js";
import GrDemCanal from "components/Graficos/General/GraficoDemCanal.js";
import GrDemEsp from "components/Graficos/General/GraficoDemEsp.js";
import GrDemGenero from "components/Graficos/General/GraficoDemSexo.js";
import GrDemSector from "components/Graficos/General/GraficoDeSecSolo.js";
import GrOfInEsp from "components/Graficos/General/GraficoOfInicialEsp.js";
import GrOfInPro from "components/Graficos/General/GraficoOfInicialProfe.js";
import GrOfInSec from "components/Graficos/General/GraficoOfInicialSec.js";
import GrHbEsp from "components/Graficos/General/GraficoHorasBloqueadas.js";
import GrHbProfe from "components/Graficos/General/GraficoHbProfesional.js";
import GrHbMotivo from "components/Graficos/General/GraficoHbMotivo.js";
import GrHbSector from "components/Graficos/General/GraficoHbSector.js";
import GrOfFinEsp from "components/Graficos/General/GraficoOfFinalEsp.js";
import GrOfFinPro from "components/Graficos/General/GraficoOfFinalProf.js";
import GrOfFinSector from "components/Graficos/General/GraficoOfFinSector.js";
import GrResEdad from "components/Graficos/General/GraficoResEdad.js";
import GrResCanal from "components/Graficos/General/GraficoResCanal.js";
import GrResEsp from "components/Graficos/General/GraficoResEsp.js";
import GrResGenero from "components/Graficos/General/GraficoResSexo.js";
import GrResSector from "components/Graficos/General/GraficoResSector.js";
import GrHbQuienBloq from "components/Graficos/General/GraficoHbQuiB.js";

import grafico_boton from "assets/img/imagenesMenu/grafico_boton.svg";
import home from "assets/img/home.svg";
import img from "../../../assets/img/icono calendario.svg";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Titulo from "components/titulo/Titulo.js";
import axios from "axios";
import { connect } from "react-redux";
import { store } from "store/index";
import { TramRounded } from "@material-ui/icons";
import DatePicker from "react-multi-date-picker";
import { MenuItem } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import "assets/css/graficosDM.css";
import graf from "assets/css/ofertademanda.module.css";
import { Select } from "@material-ui/core";

export default class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_actual: "",
      fecha_inicio: "",
      fecha_termino: "",
      fec_act: null,
      fec_ini: null,
      fec_ter: null,
      fecha_semana: "",
      fecha_mes: "",
      fecha_año: "",
      cesfams: [],
      cesfam: "",
      GrPr: false,
      GrPr2: false,
      Gr2: false,
      GrSec: false,
      GrOfSec: false,
      GrDemEdad: false,
      GrDemCanal: false,
      GrDemEsp: false,
      GrDemGenero: false,
      GrDemSector: false,
      GrOfInEsp: false,
      GrOfInPro: false,
      GrOfInSec: false,
      GrHbEsp: false,
      GrHbProfe: false,
      GrHbMotivo: false,
      GrHbSector: false,
      GrOfFinEsp: false,
      GrOfFinPro: false,
      GrOfFinSector: false,
      GrResEdad: false,
      GrResCanal: false,
      GrResEsp: false,
      GrResGenero: false,
      GrResSector: false,
      mostrarListaDem: false,
      mostrarListaOfIni: false,
      mostrarListaHb: false,
      mostrarListaOfFin: false,
      mostrarListaHorRese: false,
      GrHbQuienBloq: false,
      displayBotones: "none",
      colorBotones: "",
      colorBotones1: "",
      colorBotones2: "",
      colorBotones3: "",
      colorBotones4: "",
      colorBotones5: "",
      colorBotones6: "",
      idCliente: store.getState().cliente,
      usp: store.getState().usp,
      herramientas: store.getState().herramientas,
      cesfamvisibles: [],
      today: new Date(),
      tresdiasdespues: "",
      heigtHome: "40%",
      cesNombre: "",
      comuna: "",
      color: "",
      label: "",
      leftBotones: "0%",
      leftBotonGeneral: "0%",
      leftBotonDem: "0%",
      leftBotonOfIni: "0%",
      leftBotonHb: "0%",
      leftBotonOfFin: "0%",
      leftBotonHr: "0%",
      btnGenActivo: false,
      btnDemActivo: false,
      btnOfIniActivo: false,
      btnHbActivo: false,
      btnOfFinActivo: false,
      btnHrActivo: false,
      colorDate: "#6cb5dd",
      clase: "dateGraf",
      backboton: "#6cb5dd",
      labelBoton: "#6cb5dd",
      clase1: "dateGrafact",
      colorDate1: "#59b14c",
      minDate: "",
      tipo: "",
      tf: 0,
      tipoFiltro: 0,
      mostrarFiltro: false,
    };
  }

  datosCesfam = (usp) => {
    if (this.state.idCliente == 7) {
      this.setState({
        minDate: "2023/05/01",
      });
    }
    axios
      .get(global.config.modulos.usuariospanel + `por_id/` + usp, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data) {
          const cesfamsVisibles = res.data["usp_cesfams_visibles"]
            .split(",")
            .map((numero) => parseInt(numero));
          this.setState({
            cesfams: [],
            tresdiasdespues: new Date(
              this.state.today.getTime() + 3 * 24 * 60 * 60 * 1000
            ),
            rol: res.data["rol"],
          });
          axios
            .get(global.config.modulos.grafico_demanda + "cesfamCliente/", {
              headers: authHeader(),
              params: {
                comuna: this.state.idCliente,
              },
            })
            .then((res) => {
              if (!res.data.estado) {
                NotificationManager.info("No hay datos");
                return;
              }
              this.setState({ comuna: res.data.datos[0]["comuna"] });
              let posicion = "";
              for (let index = 0; index < res.data.datos.length; index++) {
                if (cesfamsVisibles.includes(res.data.datos[index]["id"])) {
                  if (!this.state.herramientas.includes("H48")) {
                    if (posicion === "") {
                      posicion = index;
                    }

                    this.cambioCesfam(
                      res.data.datos[posicion]["id"],
                      res.data.datos[posicion]["color"],
                      res.data.datos[posicion]["nombre"]
                    );
                  }
                  this.setState((prevState) => ({
                    cesfams: [...prevState.cesfams, res.data.datos[index]],
                  }));
                }
                this.setState({ total_cesfam: this.state.cesfams.length });
              }

              if (this.state.total_cesfam === 0) {
                NotificationManager.info("no tiene cesfams asociados");
                this.setState({ GrPr: false, GrPr2: false });
              }
            });
        }
      });
  };

  handleMouseEnter = () => {
    this.setState({
      mostrarListaDem: false,
      mostrarListaOfIni: false,
      mostrarListaHb: false,
      mostrarListaOfFin: false,
      mostrarListaHorRese: false,
    });
  };

  handleMouseleave = () => {
    this.setState({
      mostrarListaDem: false,
      mostrarListaOfIni: false,
      mostrarListaHb: false,
      mostrarListaOfFin: false,
      mostrarListaHorRese: false,
    });
  };

  handleMouseEnterDem = () => {
    this.setState({
      mostrarListaDem: true,
      mostrarListaOfIni: false,
      mostrarListaHb: false,
      mostrarListaOfFin: false,
      mostrarListaHorRese: false,
    });
  };

  handleMouseEnterOfIni = () => {
    this.setState({
      mostrarListaOfIni: true,
      mostrarListaDem: false,
      mostrarListaHb: false,
      mostrarListaOfFin: false,
      mostrarListaHorRese: false,
    });
  };

  handleMouseEnterHb = () => {
    this.setState({
      mostrarListaOfIni: false,
      mostrarListaDem: false,
      mostrarListaHb: true,
      mostrarListaOfFin: false,
      mostrarListaHorRese: false,
    });
  };

  handleMouseEnterOfFin = () => {
    this.setState({
      mostrarListaOfIni: false,
      mostrarListaDem: false,
      mostrarListaHb: false,
      mostrarListaOfFin: true,
      mostrarListaHorRese: false,
    });
  };

  handleMouseEnterHorRese = () => {
    this.setState({
      mostrarListaOfIni: false,
      mostrarListaDem: false,
      mostrarListaHb: false,
      mostrarListaOfFin: false,
      mostrarListaHorRese: true,
    });
  };

  mostrarPrincipal = () => {
    this.setState(
      {
        label: "none",
        GrPr: false,
        Gr2: false,
        GrPr2: false,
        displayBotones: "none",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        GrHbQuienBloq: false,
      },
      () => {
        this.setState({ GrPr: true, GrPr2: true, GrResEdad: false });
        if (this.state.idCliente == 1) {
          this.setState({
            heigtHome: "80%",
          });
        }
      }
    );
  };

  mostrar2 = () => {
    this.setState(
      {
        cesNombre: "",
        label: "none",
        GrPr: false,
        Gr2: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "none",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        GrHbQuienBloq: false,
        clase: "dateGraf",
        colorDate: "#6cb5dd",
        backboton: "#6cb5dd",
        labelBoton: "#6cb5dd",
        colorDate1: "#59b14c",
        clase1: "dateGrafact",
      },
      () => {
        this.setState({ Gr2: true });
      }
    );
  };

  cambioCesfam = (i, c, nombre_centro) => {
    nombre_centro = nombre_centro.replace("Posta de Salud", "P.S");
    axios
      .get(global.config.modulos.cesfams + `?cesid=` + i, {
        headers: authHeader(),
      })
      .then((res) => {
        var data = res.data;
        var tipo = data.tipo;
        if (tipo == "Posta") {
          tipo = "";
        }
        this.setState(
          {
            cesfam: i,
            color: c,
            tipo: tipo,
            cesNombre: nombre_centro,
            label: "",
            leftBotones: "-10%",
            leftBotonGeneral: "0%",
            btnGenActivo: true,
            GrPr: false,
            Gr2: false,
            GrPr2: false,
            GrSec: false,
            GrOfSec: false,
            displayBotones: "none",
            GrDemEdad: false,
            GrDemCanal: false,
            GrDemEsp: false,
            GrDemGenero: false,
            GrDemSector: false,
            GrOfInEsp: false,
            GrOfInPro: false,
            GrOfInSec: false,
            GrHbEsp: false,
            GrHbProfe: false,
            GrHbMotivo: false,
            GrHbSector: false,
            GrOfFinEsp: false,
            GrOfFinPro: false,
            GrOfFinSector: false,
            GrResEdad: false,
            GrResCanal: false,
            GrResEsp: false,
            GrResGenero: false,
            GrResSector: false,
            GrHbQuienBloq: false,
            btnDemActivo: false,
            btnOfIniActivo: false,
            mostrarListaDem: false,
            mostrarListaOfIni: false,
            mostrarListaHb: false,
            mostrarListaOfFin: false,
            mostrarListaHorRese: false,
            btnHbActivo: false,
            btnOfFinActivo: false,
            btnHrActivo: false,
          },
          () => {
            if (this.state.color === "#FF9327") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones1:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones2:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones3:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones4:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones5:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones6:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorDate:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorDate1:
                  "linear-gradient(to bottom, #0615a3, #654dbb, #9c85d3)",
                backboton: "linear-gradient(to bottom, #FCB960, #EAA028)",
                labelBoton: "#E08314",
              });
            }
            if (
              this.state.color === "#FF9327" &&
              this.state.btnGenActivo === true
            ) {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones1:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones2:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones3:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones4:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones5:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorBotones6:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorDate:
                  "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
                colorDate1:
                  "linear-gradient(to bottom, #0615a3, #654dbb, #9c85d3)",
                backboton: "linear-gradient(to bottom, #FCB960, #EAA028)",
                labelBoton: "#E08314",
              });
              if (
                this.state.color === "#FF9327" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
                });
              }
            }

            if (this.state.color === "#EF4597") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorBotones1:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorBotones2:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorBotones3:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorBotones4:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorBotones5:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorBotones6:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorDate:
                  "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
                colorDate1:
                  "linear-gradient(to bottom, #04C311, #00c588, #00c58f)",
                backboton: "linear-gradient(to bottom, #FF7DC1, #EF4597)",
                labelBoton: "#DD1F95",
              });
              if (
                this.state.color === "#EF4597" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
                });
              }
            }
            if (this.state.color === "#30b59b") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorBotones1:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorBotones2:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorBotones3:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorBotones4:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorBotones5:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorBotones6:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorDate:
                  "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
                colorDate1:
                  "linear-gradient(to bottom, #c98604, #df6b1f, #ee4b3d)",
                backboton: "linear-gradient(to bottom, #5dd1bd, #30b59b)",
                labelBoton: "#1fa389",
              });
              if (
                this.state.color === "#30b59b" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
                });
              }
            }
            if (this.state.color === "#225093") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorBotones1:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorBotones2:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorBotones3:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorBotones4:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorBotones5:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorBotones6:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorDate:
                  "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                colorDate1:
                  "linear-gradient(to bottom, #a35406, #c07d48, #daa782)",
                backboton: "linear-gradient(to bottom, #3675C1, #225093)",
                labelBoton: "#143275",
              });
              if (
                this.state.color === "#225093" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #00203F, #225093, #00203F)",
                });
              }
            }
            if (this.state.color === "#23BAE8") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorBotones1:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorBotones2:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorBotones3:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorBotones4:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorBotones5:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorBotones6:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorDate:
                  "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
                colorDate1:
                  "linear-gradient(to bottom, #53cf03, #5ee30d, #76de00)",
                backboton: "linear-gradient(to bottom, #64DDF9, #23BAE8)",
                labelBoton: "#09A6D1",
              });
              if (
                this.state.color === "#23BAE8" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
                });
              }
            }
            if (this.state.color === "#8156C1") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorBotones1:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorBotones2:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorBotones3:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorBotones4:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorBotones5:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorBotones6:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorDate:
                  "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                colorDate1:
                  "linear-gradient(to bottom, #d0ce1c, #b9b719, #a3a117)",
                backboton: "linear-gradient(to bottom, #9B76E2, #8156C1)",
                labelBoton: "#6E45AF",
              });
              if (
                this.state.color === "#8156C1" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
                });
              }
            }
            if (this.state.color === "#63C189") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorBotones1:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorBotones2:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorBotones3:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorBotones4:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorBotones5:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorBotones6:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorDate:
                  "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                colorDate1:
                  "linear-gradient(to bottom, #e81f1f, #e8410e, #e36d00)",
                backboton: "linear-gradient(to bottom, #79d89f, #63c189)",
                labelBoton: "#40b56F",
              });
              if (
                this.state.color === "#63C189" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #207743, #63c189, #207743)",
                });
              }
            }
            if (this.state.color === "#F45175") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorBotones1:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorBotones2:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorBotones3:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorBotones4:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorBotones5:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorBotones6:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorDate:
                  "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                colorDate1:
                  "linear-gradient(to bottom, #0dcf03, #50d63b, #72dd5b)",
                backboton: "linear-gradient(to bottom, #FF7B9E, #F45175)",
                labelBoton: "#D8275E",
              });
              if (
                this.state.color === "#F45175" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
                });
              }
            }
            if (this.state.color === "#E8C42A") {
              this.setState({
                colorBotones:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorBotones1:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorBotones2:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorBotones3:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorBotones4:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorBotones5:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorBotones6:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorDate:
                  "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                colorDate1:
                  "linear-gradient(to bottom, #a106a3, #af3caf, #bc5bba)",
                backboton: "linear-gradient(to bottom, #F7D857, #E8C42A)",
                labelBoton: "#D8B41D",
              });
              if (
                this.state.color === "#E8C42A" &&
                this.state.btnGenActivo === true
              ) {
                this.setState({
                  colorBotones1:
                    "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
                });
              }
            }
            this.setState({
              GrSec: true,
              GrOfSec: true,
              displayBotones: "flex",
            });
          }
        );
      });
  };

  graficosPrinci = () => {
    this.setState(
      {
        cesNombre: "",
        label: "none",
        Gr2: false,
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "none",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        GrHbQuienBloq: false,
        colorDate: "#6cb5dd",
        clase: "dateGraf",
        backboton: "#6cb5dd",
        labelBoton: "#6cb5dd",
        colorDate1: "#59b14c",
        clase1: "dateGrafact",
      },
      () => {
        this.setState({
          GrPr: true,
          GrPr2: true,
        });
      }
    );
  };

  graficoDemEdad = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "none",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaDem: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: true,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrDemEdad: true,
          displayBotones: "flex",
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoDemCanal = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaDem: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: true,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrDemCanal: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63C189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoDemEsp = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaDem: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: true,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrDemEsp: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63C189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoDemSexo = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaDem: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: true,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrDemGenero: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoDemSector = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaDem: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: true,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrDemSector: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoOfInEsp = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaOfIni: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: true,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrOfInEsp: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoOfInPro = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaOfIni: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: true,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrOfInPro: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoOfInSector = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaOfIni: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: true,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrOfInSec: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnOfIniActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoHbEsp = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHb: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: true,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrHbEsp: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoHbprofe = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHb: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: true,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrHbProfe: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoHbMotivo = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHb: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: true,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrHbMotivo: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoHbSector = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHb: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: true,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrHbSector: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoOfFinEsp = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaOfFin: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: true,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrOfFinEsp: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoOfFinProfe = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaOfFin: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: true,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrOfFinPro: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoOfFinSector = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaOfFin: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: true,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrOfFinSector: true,
        });
        if (
          this.state.color === "#FF9327" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (
          this.state.color === "#EF4597" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (
          this.state.color === "#225093" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (
          this.state.color === "#23BAE8" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (
          this.state.color === "#8156C1" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (
          this.state.color === "#63C189" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (
          this.state.color === "#F45175" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (
          this.state.color === "#E8C42A" &&
          this.state.btnOfFinActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoResEdad = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHorRese: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: true,
      },
      () => {
        this.setState({
          GrResEdad: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoResCanal = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHorRese: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: true,
      },
      () => {
        this.setState({
          GrResCanal: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (
          this.state.color === "#30b59b" &&
          this.state.btnDemActivo === true
        ) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoResEsp = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHorRese: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: true,
      },
      () => {
        this.setState({
          GrResEsp: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoResGenero = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHorRese: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: true,
      },
      () => {
        this.setState({
          GrResGenero: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoResSector = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHorRese: false,
        GrHbQuienBloq: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: false,
        btnOfFinActivo: false,
        btnHrActivo: true,
      },
      () => {
        this.setState({
          GrResSector: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHrActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  graficoHbQuienBloqueo = () => {
    this.setState(
      {
        GrPr: false,
        GrPr2: false,
        GrSec: false,
        GrOfSec: false,
        displayBotones: "flex",
        GrDemEdad: false,
        GrDemCanal: false,
        GrDemEsp: false,
        GrDemGenero: false,
        GrDemSector: false,
        GrOfInEsp: false,
        GrOfInPro: false,
        GrOfInSec: false,
        GrHbEsp: false,
        GrHbProfe: false,
        GrHbMotivo: false,
        GrHbSector: false,
        GrOfFinEsp: false,
        GrOfFinPro: false,
        GrOfFinSector: false,
        GrResEdad: false,
        GrResCanal: false,
        GrResEsp: false,
        GrResGenero: false,
        GrResSector: false,
        mostrarListaHorRese: false,
        GrHbQuienBloq: false,
        mostrarListaHb: false,
        btnGenActivo: false,
        btnDemActivo: false,
        btnOfIniActivo: false,
        btnHbActivo: true,
        btnOfFinActivo: false,
        btnHrActivo: false,
      },
      () => {
        this.setState({
          GrHbQuienBloq: true,
        });
        if (this.state.color === "#FF9327" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones2:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones3:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones5:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones6:
              "linear-gradient(to bottom, #E08314, #EAA028, #FCB960)",
            colorBotones4:
              "linear-gradient(to bottom, #C66700, #EAA028, #C66700)",
          });
        }
        if (this.state.color === "#EF4597" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones2:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones3:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones5:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones6:
              "linear-gradient(to bottom, #DD1F95, #EF4597, #FF7DC1)",
            colorBotones4:
              "linear-gradient(to bottom, #910671, #DD1F95, #910671)",
          });
        }
        if (this.state.color === "#30b59b" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones2:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones3:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones5:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones6:
              "linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd)",
            colorBotones4:
              "linear-gradient(to bottom, #044c42, #30b59b, #044c42)",
          });
        }
        if (this.state.color === "#225093" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones2:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones3:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones5:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones6:
              "linear-gradient(to bottom, #143275, #225093, #3675C1)",
            colorBotones4:
              "linear-gradient(to bottom, #00203F, #225093, #00203F)",
          });
        }
        if (this.state.color === "#23BAE8" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones2:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones3:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones5:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones6:
              "linear-gradient(to bottom, #09A6D1, #23BAE8, #64DDF9)",
            colorBotones4:
              "linear-gradient(to bottom, #027689, #23BAE8, #027689)",
          });
        }
        if (this.state.color === "#8156C1" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones2:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones3:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones5:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones6:
              "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
            colorBotones4:
              "linear-gradient(to bottom, #3E1A82, #8156C1, #3E1A82)",
          });
        }
        if (this.state.color === "#63C189" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones2:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones3:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones5:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones6:
              "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
            colorBotones4:
              "linear-gradient(to bottom, #207743, #63c189, #207743)",
          });
        }
        if (this.state.color === "#F45175" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones2:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones3:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones5:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones6:
              "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
            colorBotones4:
              "linear-gradient(to bottom, #A00A47, #F45175, #A00A47)",
          });
        }
        if (this.state.color === "#E8C42A" && this.state.btnHbActivo === true) {
          this.setState({
            colorBotones1:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones2:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones3:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones5:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones6:
              "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
            colorBotones4:
              "linear-gradient(to bottom, #AD8C00, #E8C42A, #AD8C00)",
          });
        }
      }
    );
  };

  actualizarDatos = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  titulo = function () {
    document.getElementsByClassName("content-titulo")[0].style.display = "none";
  };

  actualizarFechaInicial = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: "",
          fecha_termino: "",
          fec_ini: "",
          fec_ter: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState(
            {
              fecha_actual:
                fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
              fec_act: fecha,
            },
            () => {
              console.log(this.state.fec_act, this.state.fecha_actual);
            }
          );
        }
      );
    }
  };

  actualizarFechaComi = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha_inicio: "",
          fec_ini: null,
          fec_act: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState({
            fecha_inicio:
              fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
            fec_ini: fecha,
          });
        }
      );
    }
  };

  actualizarFechaTer = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha_termino: "",
          fec_ter: null,
          fec_act: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState({
            fecha_termino:
              fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
            fec_ter: fecha,
          });
        }
      );
    }
  };

  actualizarSemana = (e) => {
    console.log(e);
    if (e) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: null,
          fecha_termino: null,
          fec_ini: null,
          fec_ter: null,
          fecha_semana: null,
          fecha_mes: null,
          fecha_año: null,
        },
        () => {
          this.setState({
            fecha_semana: e.target.value,
          });
        }
      );
    }
  };

  actualizarMes = (e) => {
    if (e) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: "",
          fecha_termino: "",
          fec_ini: "",
          fec_ter: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState({
            fecha_mes: e.target.value,
          });
        }
      );
    }
  };

  actualizarAño = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: "",
          fecha_termino: "",
          fec_ini: "",
          fec_ter: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState({
            fecha_año: fecha["year"],
          });
        }
      );
    }
  };

  botonLimpiar = () => {
    this.setState(
      {
        fec_act: null,
        fec_ini: null,
        fec_ter: null,
        fecha_actual: "",
        fecha_inicio: "",
        fecha_termino: "",
        fecha_semana: "",
        fecha_mes: "",
        fecha_año: "",
        titulo_archivo: "",
        tf: this.state.tipoFiltro,
      },
      () => {
        this.setState(
          {
            tipoFiltro: 0,
          },
          () => {
            this.setState({
              tipoFiltro: this.state.tf,
            });
          }
        );
      }
    );
  };

  BuscarDatos = () => {
    if (this.state.GrPr === true) {
      this.mostrarPrincipal();
    } else if (this.state.Gr2 === true) {
      this.mostrar2();
    } else if (this.state.GrSec === true) {
      this.setState(
        {
          GrSec: false,
          GrOfSec: false,
        },
        () => {
          this.setState({
            GrSec: true,
            GrOfSec: true,
          });
        }
      );
    } else if (this.state.GrDemEdad === true) {
      this.setState({ GrDemEdad: false }, () => {
        this.setState({
          GrDemEdad: true,
        });
      });
    } else if (this.state.GrDemCanal === true) {
      this.setState({ GrDemCanal: false }, () => {
        this.setState({
          GrDemCanal: true,
        });
      });
    } else if (this.state.GrDemEsp === true) {
      this.setState({ GrDemEsp: false }, () => {
        this.setState({
          GrDemEsp: true,
        });
      });
    } else if (this.state.GrDemGenero === true) {
      this.setState({ GrDemGenero: false }, () => {
        this.setState({
          GrDemGenero: true,
        });
      });
    } else if (this.state.GrDemSector === true) {
      this.setState({ GrDemSector: false }, () => {
        this.setState({
          GrDemSector: true,
        });
      });
    } else if (this.state.GrOfInEsp === true) {
      this.setState({ GrOfInEsp: false }, () => {
        this.setState({
          GrOfInEsp: true,
        });
      });
    } else if (this.state.GrOfInPro === true) {
      this.setState({ GrOfInPro: false }, () => {
        this.setState({
          GrOfInPro: true,
        });
      });
    } else if (this.state.GrOfInSec === true) {
      this.setState({ GrOfInSec: false }, () => {
        this.setState({
          GrOfInSec: true,
        });
      });
    } else if (this.state.GrHbEsp === true) {
      this.setState({ GrHbEsp: false }, () => {
        this.setState({
          GrHbEsp: true,
        });
      });
    } else if (this.state.GrHbProfe === true) {
      this.setState({ GrHbProfe: false }, () => {
        this.setState({
          GrHbProfe: true,
        });
      });
    } else if (this.state.GrHbQuienBloq === true) {
      this.setState({ GrHbQuienBloq: false }, () => {
        this.setState({
          GrHbQuienBloq: true,
        });
      });
    } else if (this.state.GrHbMotivo === true) {
      this.setState({ GrHbMotivo: false }, () => {
        this.setState({
          GrHbMotivo: true,
        });
      });
    } else if (this.state.GrHbSector === true) {
      this.setState({ GrHbSector: false }, () => {
        this.setState({
          GrHbSector: true,
        });
      });
    } else if (this.state.GrOfFinEsp === true) {
      this.setState({ GrOfFinEsp: false }, () => {
        this.setState({
          GrOfFinEsp: true,
        });
      });
    } else if (this.state.GrOfFinPro === true) {
      this.setState({ GrOfFinPro: false }, () => {
        this.setState({
          GrOfFinPro: true,
        });
      });
    } else if (this.state.GrOfFinSector === true) {
      this.setState({ GrOfFinSector: false }, () => {
        this.setState({
          GrOfFinSector: true,
        });
      });
    } else if (this.state.GrResEdad === true) {
      this.setState({ GrResEdad: false }, () => {
        this.setState({
          GrResEdad: true,
        });
      });
    } else if (this.state.GrResCanal === true) {
      this.setState({ GrResCanal: false }, () => {
        this.setState({
          GrResCanal: true,
        });
      });
    } else if (this.state.GrResEsp === true) {
      this.setState({ GrResEsp: false }, () => {
        this.setState({
          GrResEsp: true,
        });
      });
    } else if (this.state.GrResGenero === true) {
      this.setState({ GrResGenero: false }, () => {
        this.setState({
          GrResGenero: true,
        });
      });
    } else if (this.state.GrResSector === true) {
      this.setState({ GrResSector: false }, () => {
        this.setState({
          GrResSector: true,
        });
      });
    }
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState(
      {
        tipoFiltro: event.target.value,
        mostrarFiltro: false,
        fec_act: null,
        fec_ini: null,
        fec_ter: null,
        fecha_actual: "",
        fecha_inicio: "",
        fecha_termino: "",
        fecha_semana: "",
        fecha_mes: "",
        fecha_año: "",
      },
      () => {
        this.setState({
          mostrarFiltro: true,
        });
      }
    );
  };

  componentDidMount() {
    this.datosCesfam(this.state.usp);
    this.mostrarPrincipal();
    this.titulo();
  }

  render() {
    const { cesfams, colorDate } = this.state;

    return (
      <div
        className="middle-area"
        style={{
          left: "-3%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "97%",
            display: "flex",
          }}
        >
          <div
            style={{
              height: "35rem",
              width: "10rem",
              display: this.state.displayBotones,
              flexDirection: "column",
              justifyContent: "center",
              left: "1%",
              position: "fixed",
              top: "30%",
            }}
          >
            <Button
              className="general"
              style={{
                background: this.state.colorBotones1,
                color: "white",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                this.cambioCesfam(
                  this.state.cesfam,
                  this.state.color,
                  this.state.cesNombre
                );
              }}
              onMouseEnter={this.handleMouseEnter}
            >
              General
            </Button>

            <Button
              className="general"
              style={{
                background: this.state.colorBotones2,
                color: "white",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={this.handleMouseEnterDem}
            >
              Demanda
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {">"}
            </Button>
            {this.state.mostrarListaDem && (
              <ul
                onMouseLeave={this.handleMouseleave}
                style={{
                  position: "absolute",
                  left: "102%",
                  top: "34%",
                  background: this.state.colorBotones,
                  width: "9rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "10px",
                }}
              >
                <Button
                  onClick={this.graficoDemEdad}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Edad
                </Button>
                <Button
                  onClick={this.graficoDemCanal}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Canal
                </Button>
                <Button
                  onClick={this.graficoDemEsp}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Tipo de atencion
                </Button>
                <Button
                  onClick={this.graficoDemSexo}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Sexo
                </Button>
                <Button
                  onClick={this.graficoDemSector}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Sector
                </Button>
              </ul>
            )}

            <Button
              className="general"
              style={{
                background: this.state.colorBotones3,
                color: "white",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => this.handleMouseEnterOfIni()}
            >
              Oferta inicial
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {">"}
            </Button>
            {this.state.mostrarListaOfIni && (
              <ul
                onMouseLeave={this.handleMouseleave}
                style={{
                  position: "absolute",
                  left: "102%",
                  top: "42%",
                  background: this.state.colorBotones,
                  width: "9rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "10px",
                }}
              >
                <Button
                  onClick={this.graficoOfInEsp}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Tipo de atencion
                </Button>
                <Button
                  onClick={this.graficoOfInPro}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Profesional
                </Button>
                <Button
                  onClick={this.graficoOfInSector}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Sector
                </Button>
              </ul>
            )}

            <Button
              className="general"
              style={{
                background: this.state.colorBotones4,
                color: "white",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => this.handleMouseEnterHb()}
            >
              Horas bloqueadas&nbsp;&nbsp; {">"}
            </Button>
            {this.state.mostrarListaHb && (
              <ul
                onMouseLeave={this.handleMouseleave}
                style={{
                  position: "absolute",
                  left: "102%",
                  top: "50%",
                  background: this.state.colorBotones,
                  width: "9rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "10px",
                }}
              >
                <Button
                  onClick={this.graficoHbEsp}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Tipo de atencion
                </Button>
                <Button
                  onClick={this.graficoHbprofe}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Profesional
                </Button>
                <Button
                  onClick={this.graficoHbQuienBloqueo}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Quién bloqueo
                </Button>
                <Button
                  onClick={this.graficoHbMotivo}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Motivo
                </Button>
                <Button
                  onClick={this.graficoHbSector}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Sector
                </Button>
              </ul>
            )}

            <Button
              className="general"
              style={{
                background: this.state.colorBotones5,
                color: "white",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => this.handleMouseEnterOfFin()}
            >
              Oferta Final
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {">"}
            </Button>
            {this.state.mostrarListaOfFin && (
              <ul
                onMouseLeave={this.handleMouseleave}
                style={{
                  position: "absolute",
                  left: "102%",
                  top: "58%",
                  background: this.state.colorBotones,
                  width: "9rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "10px",
                }}
              >
                <Button
                  onClick={this.graficoOfFinEsp}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Tipo de atencion
                </Button>
                <Button
                  onClick={this.graficoOfFinProfe}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Profesional
                </Button>
                <Button
                  onClick={this.graficoOfFinSector}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Sector
                </Button>
              </ul>
            )}

            <Button
              className="general"
              style={{
                background: this.state.colorBotones6,
                color: "white",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => this.handleMouseEnterHorRese()}
            >
              Horas reservadas&nbsp;&nbsp; {">"}
            </Button>
            {this.state.mostrarListaHorRese && (
              <ul
                onMouseLeave={this.handleMouseleave}
                style={{
                  position: "absolute",
                  left: "102%",
                  top: "40%",
                  background: this.state.colorBotones,
                  width: "9rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "10px",
                }}
              >
                <Button
                  onClick={this.graficoResEdad}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Edad
                </Button>
                <Button
                  onClick={this.graficoResCanal}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Canal
                </Button>
                <Button
                  onClick={this.graficoResEsp}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Tipo de atencion
                </Button>
                <Button
                  onClick={this.graficoResGenero}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Sexo
                </Button>
                <Button
                  onClick={this.graficoResSector}
                  style={{ color: "white", borderBottom: "1px solid white" }}
                >
                  Sector
                </Button>
              </ul>
            )}
          </div>
          <div
            style={{
              position: "fixed",
              top: "6%",
              left: "43%",
              width: "15%",
              display: "flex",
              height: "10%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                alignItems: "center",
              }}
            >
              {this.state.herramientas.includes("H48") && (
                <>
                  <img
                    className="1"
                    src={home}
                    style={{
                      cursor: "pointer",
                      zIndex: 2,
                      height: "50%",
                      marginRight: "10px",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => this.graficosPrinci()}
                  ></img>
                  <img
                    className="1"
                    src={grafico_boton}
                    style={{
                      cursor: "pointer",
                      zIndex: 2,
                      height: "50%",
                      marginRight: "10px",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => this.mostrar2()}
                  ></img>
                </>
              )}
              {this.state.cesNombre && (
                <Button
                  className="1"
                  style={{
                    zIndex: 2,
                    width: "max-content",
                    backgroundColor: "#09A6D1",
                    color: "white",
                    height: "5vh",
                  }}
                >
                  <b style={{ fontSize: "1vw" }}>
                    {this.state.tipo} {this.state.cesNombre}
                  </b>
                </Button>
              )}
            </div>
          </div>

          <div
            className="divCentral"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "2%",
              }}
            >
              <div
                style={{
                  width: "92.3rem",
                }}
              >
                {cesfams.map((cesfam) => (
                  <Button
                    className="1"
                    value={cesfam.id}
                    style={{
                      background: cesfam.color2,
                      color: "white",
                      zIndex: 2,
                      marginLeft: "12px",
                      width: "10.5rem",
                      marginTop: "0.5rem",
                    }}
                    onClick={() =>
                      this.cambioCesfam(cesfam.id, cesfam.color, cesfam.nombre)
                    }
                  >
                    {cesfam.nombre.replace("Posta de Salud", "P.S")}
                  </Button>
                ))}
              </div>
              <div
                style={{
                  marginTop: "1%",
                  display: "flex",
                  zIndex: "2",
                  alignItems: "center",
                }}
                className={graf.divFechas}
              >
                <FormControl
                  style={{
                    width: "13%",
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel
                    className={graf.selecciones}
                    id="Filtro-label"
                    style={{
                      marginLeft: "1%",
                    }}
                  >
                    Seleccione el Filtro
                  </InputLabel>

                  <Select
                    style={{
                      border: "2px solid orange",
                      borderRadius: "4px",
                      color: "rgb(128, 128, 128)",
                      paddingLeft: "6px",
                      fontSize: "14px",
                    }}
                    labelId="Filtro-label"
                    id="Filtro-select"
                    disableUnderline
                    value={this.state.tipoFiltro}
                    onChange={this.handleChange}
                  >
                    <MenuItem value={0}>Seleccione el Filtro</MenuItem>
                    <MenuItem value={1}>Por día</MenuItem>
                    <MenuItem value={2}>Por Semana</MenuItem>
                    <MenuItem value={3}>Por Mes</MenuItem>
                    <MenuItem value={4}>Por Año</MenuItem>
                    <MenuItem value={5}>por Rango</MenuItem>
                  </Select>
                </FormControl>

                {this.state.tipoFiltro == 1 && (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "left",
                      background: "transparent",
                      color: "rgb(128, 128, 128)",
                      width: "10rem",
                      marginLeft: "12px",
                      borderRadius: "4px",
                      border: "2px solid orange",
                      fontSize: "1vw",
                      position: "relative",
                      alignItems: "center",
                      height: "2.16rem",
                      top: "8px",
                    }}
                  >
                    <Tooltip title={"Seleccione Dia especifico de los datos"}>
                      <DatePicker
                        name="fecha"
                        value={this.state.fec_act}
                        onChange={this.actualizarFechaInicial}
                        weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                        months={[
                          "Enero",
                          "Febrero",
                          "Marzo",
                          "Abril",
                          "Mayo",
                          "Junio",
                          "Julio",
                          "Agosto",
                          "Septiembre",
                          "Octtubre",
                          "Noviembre",
                          "Diciembre",
                        ]}
                        placeholder="Dia especifico"
                        style={{
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                        format="DD/MM/YYYY"
                        minDate={this.state.minDate}
                        maxDate={this.state.tresdiasdespues}
                      ></DatePicker>
                    </Tooltip>
                    <img
                      style={{
                        width: "17px",
                        position: "absolute",
                        right: "4%",
                        top: "22%",
                        zIndex: "-5",
                      }}
                      src={img}
                    ></img>
                  </div>
                )}

                {this.state.tipoFiltro == 2 && (
                  <div style={{ position: "relative" }}>
                    <img className={graf.image} src={img}></img>
                    <input
                      onChange={(e) => this.actualizarSemana(e)}
                      className={graf.inputCalendario}
                      type="week"
                      id="weekInput"
                      name="weekInput"
                      min={this.state.minDate}
                    />
                  </div>
                )}

                {this.state.tipoFiltro == 3 && (
                  <div style={{ position: "relative" }}>
                    <img className={graf.image} src={img}></img>
                    <input
                      onChange={(e) => this.actualizarMes(e)}
                      className={graf.inputCalendarioMes}
                      type="month"
                      id="weekInput"
                      name="mes"
                      min={this.state.primeraCargaMes}
                    />
                  </div>
                )}
                {this.state.tipoFiltro == 4 && (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "left",
                      background: "transparent",
                      color: "rgb(128, 128, 128)",
                      width: "10rem",
                      marginLeft: "12px",
                      borderRadius: "4px",
                      border: "2px solid orange",
                      fontSize: "1vw",
                      position: "relative",
                      alignItems: "center",
                      height: "2.16rem",
                      top: "8px",
                    }}
                  >
                    <DatePicker
                      onChange={this.actualizarAño}
                      placeholder="---- Año"
                      style={{
                        background: "transparent",
                        border: "none",
                        width: "100%",
                      }}
                      onlyYearPicker
                      name="año"
                      minDate={this.state.primeraCargaAño}
                    />
                    <img
                      style={{
                        width: "17px",
                        position: "absolute",
                        right: "4%",
                        top: "22%",
                        zIndex: "-5",
                      }}
                      src={img}
                    ></img>
                  </div>
                )}

                {this.state.tipoFiltro == 5 && (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "left",
                      background: "transparent",
                      color: "rgb(128, 128, 128)",
                      width: "10rem",
                      marginLeft: "12px",
                      borderRadius: "4px",
                      border: "2px solid orange",
                      fontSize: "1vw",
                      position: "relative",
                      alignItems: "center",
                      height: "2.16rem",
                      top: "8px",
                    }}
                  >
                    <Tooltip
                      title={"Seleccione Desde que fecha desea buscar datos"}
                    >
                      <DatePicker
                        name="fecha"
                        value={this.state.fec_ini}
                        onChange={this.actualizarFechaComi}
                        weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                        months={[
                          "Enero",
                          "Febrero",
                          "Marzo",
                          "Abril",
                          "Mayo",
                          "Junio",
                          "Julio",
                          "Agosto",
                          "Septiembre",
                          "Octtubre",
                          "Noviembre",
                          "Diciembre",
                        ]}
                        style={{
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                        placeholder="Desde"
                        format="DD/MM/YYYY"
                        minDate={this.state.minDate}
                        maxDate={this.state.tresdiasdespues}
                      ></DatePicker>
                    </Tooltip>
                    <img
                      style={{
                        width: "17px",
                        position: "absolute",
                        right: "4%",
                        top: "22%",
                        zIndex: "-5",
                      }}
                      src={img}
                    ></img>
                  </div>
                )}
                {this.state.tipoFiltro == 5 && (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "left",
                      background: "transparent",
                      color: "rgb(128, 128, 128)",
                      width: "10rem",
                      marginLeft: "12px",
                      borderRadius: "4px",
                      border: "2px solid orange",
                      fontSize: "1vw",
                      position: "relative",
                      alignItems: "center",
                      height: "2.16rem",
                      top: "8px",
                    }}
                  >
                    <Tooltip
                      title={"Seleccione Hasta que fecha desea buscar datos"}
                    >
                      <DatePicker
                        name="fecha"
                        value={this.state.fec_ter}
                        onChange={this.actualizarFechaTer}
                        weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                        months={[
                          "Enero",
                          "Febrero",
                          "Marzo",
                          "Abril",
                          "Mayo",
                          "Junio",
                          "Julio",
                          "Agosto",
                          "Septiembre",
                          "Octtubre",
                          "Noviembre",
                          "Diciembre",
                        ]}
                        style={{
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                        format="DD/MM/YYYY"
                        minDate={this.state.minDate}
                        maxDate={this.state.tresdiasdespues}
                        placeholder="Hasta"
                      ></DatePicker>
                    </Tooltip>
                    <img
                      style={{
                        width: "17px",
                        position: "absolute",
                        right: "4%",
                        top: "22%",
                        zIndex: "-5",
                      }}
                      src={img}
                    ></img>
                  </div>
                )}

                <Tooltip title="Buscar datos">
                  <Button
                    style={{
                      background: "#ff9900",
                      marginLeft: "1vw",
                      height: "2REM",
                      color: "white",
                      top: "8px",
                    }}
                    onClick={this.BuscarDatos}
                  >
                    {" "}
                    Buscar{" "}
                  </Button>
                </Tooltip>

                <Tooltip title="Limpia filtros de fechas">
                  <Button
                    style={{
                      background: "#ff9900",
                      marginLeft: "1vw",
                      height: "2REM",
                      color: "white",
                      top: "8px",
                    }}
                    onClick={this.botonLimpiar}
                  >
                    {" "}
                    Limpiar{" "}
                  </Button>
                </Tooltip>
              </div>
            </div>

            <div>
              {this.state.GrPr && (
                <GraficoPrincipal
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrPr2 && (
                <Graficopr
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.Gr2 && (
                <Grafico2
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrSec && (
                <GrafSecDem
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrOfSec && (
                <GrOfSec
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrDemEdad && (
                <GrDemEdad
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrDemCanal && (
                <GrDemCanal
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrDemEsp && (
                <GrDemEsp
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrDemGenero && (
                <GrDemGenero
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrDemSector && (
                <GrDemSector
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrOfInEsp && (
                <GrOfInEsp
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrOfInPro && (
                <GrOfInPro
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrOfInSec && (
                <GrOfInSec
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrHbEsp && (
                <GrHbEsp
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrHbProfe && (
                <GrHbProfe
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrHbMotivo && (
                <GrHbMotivo
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrHbSector && (
                <GrHbSector
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrOfFinEsp && (
                <GrOfFinEsp
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrOfFinPro && (
                <GrOfFinPro
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrOfFinSector && (
                <GrOfFinSector
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrResEdad && (
                <GrResEdad
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrResCanal && (
                <GrResCanal
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrResEsp && (
                <GrResEsp
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrResGenero && (
                <GrResGenero
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrResSector && (
                <GrResSector
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
              {this.state.GrHbQuienBloq && (
                <GrHbQuienBloq
                  fecha_actual={this.state.fecha_actual}
                  fecha_inicio={this.state.fecha_inicio}
                  fecha_termino={this.state.fecha_termino}
                  fecha_semana={this.state.fecha_semana}
                  fecha_mes={this.state.fecha_mes}
                  fecha_año={this.state.fecha_año}
                  cesfam={this.state.cesfam}
                  idCliente={this.state.idCliente}
                  color={this.state.color}
                  cesNombre={this.state.cesNombre}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
