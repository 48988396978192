import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { withStyles, makeStyles } from "@material-ui/core/styles";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import { store } from "./../../store/index";
import axios from "axios";
import moment from "moment";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Floater from "react-floater";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";

import { FaTrash, FaUnlock, FaClone } from "react-icons/fa";
import TimePicker from "rc-time-picker";

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./../../assets/css/horarios.css";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
  },
}))(Tooltip);

export default class CrearHorarioBloqueo extends Component {
  constructor(props) {
    super(props);

    var fechaActual = moment().format("YYYY-MM-DD");
    this.state = {
      profesionales: [],
      profesional: this.props.profesional,

      especialidades: [],
      especialidad: "",
      especialidadHorario: this.props.especialidad,

      horasConAgenda: this.props.horasConAgenda,
      motivoBloqueo: this.props.motivoBloqueoHora,

      horariosABloquear: this.props.horarios,

      direcciones: [],
      direccion: null,

      sectores: [],
      sector: "",
      rendimientos: [],
      rendimiento: "",
      tabla: "",

      horaInicio: moment(fechaActual + " 08:00"),
      horaTermino: moment(fechaActual + " 17:00"),

      semana: moment().format("GGGG-[W]WW"),
      semanaHoy: moment().format("GGGG-[W]WW"),

      proId: this.props.profesional,
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      arregloHorario: [],
      arregloDias: [],
      arregloTemp: [],
      arregloSum: [],
      arregloInicio: [],
      arregloTermino: [],
      cargahorario: false,
      crearHoras: false,
      open: true,
    };
  }

  salirExito = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarExito();
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.traerDirecciones();
    this.traerHorarioCesfam();
    this.traerProfesionales();
    this.traerEspecialidades(this.state.profesional);
    this.traerSectores(this.state.profesional);
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  mostrarAlertaErrorAlCargarHorario() {
    confirmAlert({
      title: "",
      message: "El Horario ha cargar tuvo un problema y no se ha cargado la información",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + "por_especialidades/";

    var data = {
      cesfam: this.state.cesfam,
      especialidades: [{ value: this.state.especialidadHorario }],
      cesfam: this.state.cesfam,
      profesional: this.state.profesional,
    };

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        this.setState({
          profesionales: res.data.datos,
        });
      }
    });
  }

  traerHorarioCesfam() {
    const apiUrl = global.config.modulos.cesfams + "cesfam_horario/?cesfam=" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var horarioFuncionamiento = res.data.horarioFuncionamiento;
        var fechaActual = moment().format("YYYY-MM-DD");
        this.setState({
          horaInicio: moment(fechaActual + " " + horarioFuncionamiento.hora_inicio),
          horaTermino: moment(fechaActual + " " + horarioFuncionamiento.hora_termino),
        });
      });
  }

  traerSectores(proId) {
    const apiUrl = global.config.modulos.profesionales + "sectores_por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.datos.length > 0) {
          this.setState({
            sectores: res.data.datos,
            sector: res.data.datos[0]["sec_id"],
          });
        } else {
          this.setState({
            sectores: [],
            sector: "",
          });
        }
      });
  }

  traerDirecciones() {
    const apiUrl = global.config.modulos.agendamientos + "horarios_direcciones/?cesfam=" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var direcciones = res.data["direcciones"];
        if (direcciones.length > 0) {
          this.setState({
            direcciones: res.data["direcciones"],
            direccion: res.data["direcciones"][0]["hds_id"],
          });
        }
      });
  }

  traerEspecialidades(proId) {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_carga_horario/" + this.state.cesfam + "/" + proId;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            especialidades: res.data,
            especialidad: res.data[0]["esp_id"],
          });
          this.traerRendimientos(res.data[0]["esp_id"], 0);
        } else {
          this.setState({
            especialidades: [],
            especialidad: "",
          });
          this.traerRendimientos(0, 0);
        }
      });
  }

  traerRendimientos(espid) {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_rendimiento/" + espid;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            rendimientos: res.data,
            rendimiento: res.data[0]["esr_rendimiento"],
            tipo: 1,
          });
        } else {
          this.setState({
            rendimientos: [
              {
                esr_rendimiento: 15,
              },
              {
                esr_rendimiento: 20,
              },
              {
                esr_rendimiento: 30,
              },
              {
                esr_rendimiento: 40,
              },
              {
                esr_rendimiento: 45,
              },
              {
                esr_rendimiento: 60,
              },
            ],
            rendimiento: 15,
            tipo: 1,
          });
        }
      });
  }

  crearHorarios = (semana, rendimiento, tiempoInicio, tiempoTermino, tipo) => {
    var horarioInicio = tiempoInicio.format("HH:mm");
    var horarioTermino = tiempoTermino.format("HH:mm");

    var sem = semana;
    var arrSemana = sem.split("-W");

    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];

    var fechaInicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var fechaTerminoSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

    var data = {
      fechaInicio: fechaInicioSemana,
      fechaTermino: fechaTerminoSemana,
      cesfam: this.state.cesfam,
      profesional: this.state.profesional,
      uspId: this.state.uspId,
      tipo: tipo,
      tipoCarga: 1,
    };

    let apiUrl = global.config.modulos.agendamientos + "horario_temporal_semanal/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var horarioMedico = res.data.datos;
      var feriados = res.data.feriados;
      var horarioFuncionamiento = res.data.horarioFuncionamiento;
      var reuniones = res.data.reuniones;
      var excepciones = res.data.excepciones;

      var fechaInicio = fechaInicioSemana + " " + horarioInicio;
      var fechaTermino = fechaInicioSemana + " " + horarioTermino;

      var fecha = fechaInicio;

      var minutosAsumar = parseFloat(rendimiento);

      var arregloRendimiento = [];
      arregloRendimiento.push(fecha);
      do {
        fecha = moment(fecha).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");
        arregloRendimiento.push(fecha);
      } while (fecha < fechaTermino);

      var arrfechaBloqueo = [];
      for (var e = 0; e < horarioMedico.length; e++) {
        arrfechaBloqueo.push(horarioMedico[e]["cht_atencion"].replace("T", " "));
      }

      var tabla = [];

      var rowsHead = [];
      rowsHead.push(<TableCell className={"tablaRow cellInicio"} key={0}></TableCell>);

      var fecha = moment(fechaInicioSemana);
      for (var i = 0; i <= 6; i++) {
        rowsHead.push(
          <TableCell className={i == 6 ? "tablaRow cellTermino" : "tablaRow"}>
            <p style={{ textAlign: "center" }}>{fecha.format("dddd")} </p>
            <p style={{ textAlign: "center" }}>{fecha.format("DD-MM-YYYY")}</p>
          </TableCell>
        );
        fecha = moment(fecha).add(1, "day");
      }

      tabla.push(
        <TableHead>
          <TableRow>{rowsHead}</TableRow>
        </TableHead>
      );

      var rows = [];
      for (var j = 0; j < arregloRendimiento.length; j++) {
        var cell = [];

        var fecha = arregloRendimiento[j].split(" ");
        var horasF = fecha[1];
        var horaR = horasF.substring(5, 0);

        cell.push(<TableCell className={"tablaRow"}>{horaR}</TableCell>);

        var fechaInicioS = moment(fechaInicioSemana).format("YYYY-MM-DD");

        for (var x = 0; x <= 6; x++) {
          var fechaHorario = moment(fechaInicioS).format("YYYY-MM-DD");

          var Hora = horaR + ":00";
          var fechaHora = fechaHorario + " " + Hora;

          var bloqueoPorReunion = 0;
          var diaFeriado = 0;
          var fueraDeHorario = 0;
          var horarioCopado = 0;
          var coompFecha = 0;
          var rendimiento2 = "";
          var restriccionEdad = "";

          var horId = "";
          var horAtencion = "";
          var horEspecialidad = "";
          var horEdadMenor = "";
          var horEdadMayor = "";
          var horSector = "";
          var horEspecialidadId = "";
          var horMotivoBloqueo = "";
          var horTipo = "";
          var horRendimiento = "";
          var horEstado = "";

          var tipoReunion = "";
          var direccion = null;

          var arrfechaFuncionamiento = [];
          for (var l = 0; l < horarioFuncionamiento.length; l++) {
            var dia_semana = horarioFuncionamiento[l]["dia_semana"];
            var inicio_funcionamiento = horarioFuncionamiento[l]["hora_inicio"];
            var termino_funcionamiento = horarioFuncionamiento[l]["hora_termino"];

            arrfechaFuncionamiento.push(dia_semana);

            if ((Hora < inicio_funcionamiento && dia_semana == x) || (Hora > termino_funcionamiento && dia_semana == x)) {
              fueraDeHorario = 1;
            }
          }

          for (var m = 0; m < reuniones.length; m++) {
            var fecha_reunion = reuniones[m]["fecha_reunion"];
            var inicio_reunion = reuniones[m]["hora_inicio"];
            var termino_reunion = reuniones[m]["hora_termino"];

            if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
              bloqueoPorReunion = 1;
              tipoReunion = reuniones[m]["reunion"];
            }
          }

          for (var ex = 0; ex < excepciones.length; ex++) {
            var fecha_reunion = excepciones[ex]["fecha_reunion"];
            var inicio_reunion = excepciones[ex]["hora_inicio"];
            var termino_reunion = excepciones[ex]["hora_termino"];

            if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
              bloqueoPorReunion = 0;
            }
          }

          if (feriados.indexOf(fechaHorario) > 0) {
            diaFeriado = 1;
          } else if (arrfechaFuncionamiento.indexOf(x) < 0) {
            fueraDeHorario = 1;
          }

          for (var d = 0; d < horarioMedico.length; d++) {
            horAtencion = moment(horarioMedico[d]["cht_atencion"]);
            horRendimiento = horarioMedico[d]["cht_rendimiento"];

            var fechaAtencion = horAtencion.format("YYYY-MM-DD");
            var horaAtencion = horAtencion.format("HH:mm:ss");
            horAtencion = horAtencion.format("YYYY-MM-DD HH:mm:ss");

            var minutosAsumar = parseFloat(horRendimiento);

            var fechaFinal = moment(horAtencion).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");

            if (arrfechaBloqueo.indexOf(fechaHora) < 0 && horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora && fechaAtencion == fechaHorario && horRendimiento != rendimiento) {
              horarioCopado = 1;
            } else if (arrfechaBloqueo.indexOf(fechaHora) < 0 && horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora && fechaAtencion == fechaHorario && horRendimiento == rendimiento) {
              horarioCopado = 1;
            } else if (horAtencion == fechaHora && fechaAtencion == fechaHorario && diaFeriado == 0 && fueraDeHorario == 0 && bloqueoPorReunion == 0) {
              horId = horarioMedico[d]["hor_id"];
              horEspecialidad = horarioMedico[d]["cht_especialidad__esp_nombre"];
              horEdadMenor = horarioMedico[d]["cht_edad_mayor"];
              horEdadMayor = horarioMedico[d]["cht_edad_mayor"];
              horSector = horarioMedico[d]["cht_sector__sec_nombre"];
              horEspecialidadId = horarioMedico[d]["cht_especialidad"];
              horMotivoBloqueo = horarioMedico[d]["cht_motivo"];
              horTipo = horarioMedico[d]["cht_tipo"];
              horEstado = horarioMedico[d]["cht_estado"];
              direccion = horarioMedico[d]["direccion"];

              horarioCopado = 0;
              coompFecha += 1;
              fueraDeHorario = 0;
              diaFeriado = 0;
              bloqueoPorReunion = 0;

              rendimiento2 = horRendimiento + " minutos ";

              if ((horEdadMenor == null || horEdadMenor == 0) && (horEdadMayor == null || horEdadMayor == 0)) {
                restriccionEdad = " Sin restricción";
              } else {
                restriccionEdad = " de " + horEdadMenor + " a " + horEdadMayor + " años";
              }
            }
          }

          if (fueraDeHorario == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title="FUERA DE HORARIO DEL CENTRO"
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Fuera de horario
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else if (bloqueoPorReunion == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title={"REUNIÓN " + tipoReunion}
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Bloqueado por<br></br>
                          {tipoReunion}
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else if (diaFeriado == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title="HORARIO BLOQUEADO"
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Día feriado
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else if (horarioCopado == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title="HORARIO BLOQUEADO"
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Horario Bloqueado
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else {
            if (coompFecha != 0) {
              if (horTipo == "2") {
                cell.push(
                  <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          <div>
                            <Table class="table table-striped table-hover tablaPopover">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{horEspecialidad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{rendimiento2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                  <TableCell>{horSector}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                  <TableCell>{restriccionEdad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Motivo bloqueo&nbsp;</TableCell>
                                  <TableCell>{horMotivoBloqueo}</TableCell>
                                </TableRow>
                                {direccion != null && (
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Dirección&nbsp;</TableCell>
                                    <TableCell>{direccion}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </div>
                        }
                      >
                        <button
                          title="HORARIO BLOQUEADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label
                            className={"textoBotonesCarga"}
                            style={{
                              color: "#444444",
                            }}
                          >
                            {horEspecialidad}
                          </label>
                        </button>
                      </HtmlTooltip>
                      <br></br>
                    </div>
                  </TableCell>
                );
              } else if (horEstado == "0") {
                cell.push(
                  <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          <div>
                            <Table class="table table-striped table-hover tablaPopover">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{horEspecialidad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{rendimiento2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                  <TableCell>{horSector}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                  <TableCell>{restriccionEdad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Motivo bloqueo&nbsp;</TableCell>
                                  <TableCell>{horMotivoBloqueo}</TableCell>
                                </TableRow>
                                {direccion != null && (
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Dirección&nbsp;</TableCell>
                                    <TableCell>{direccion}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </div>
                        }
                      >
                        <button
                          title="HORARIO BLOQUEADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label
                            className={"textoBotonesCarga"}
                            style={{
                              color: "#444444",
                            }}
                          >
                            {horEspecialidad}
                          </label>
                        </button>
                      </HtmlTooltip>
                      <br></br>
                    </div>
                  </TableCell>
                );
              } else {
                cell.push(
                  <TableCell className={"tablaRow horarioCreado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          <div>
                            <Table class="table table-striped table-hover tablaPopover">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{horEspecialidad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{rendimiento2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                  <TableCell>{horSector}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                  <TableCell>{restriccionEdad}</TableCell>
                                </TableRow>
                                {direccion != null && (
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Dirección&nbsp;</TableCell>
                                    <TableCell>{direccion}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </div>
                        }
                      >
                        <button
                          title="HORARIO CARGADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "rgb(38, 165, 48)",
                            borderColor: "rgb(38, 165, 48)",
                            color: "#fff",
                          }}
                        >
                          <label className={"textoBotonesCarga"}>{horEspecialidad}</label>
                        </button>
                      </HtmlTooltip>
                      <br></br>
                    </div>
                  </TableCell>
                );
              }
            } else {
              cell.push(
                <TableCell className={"tablaRow horarioASeleccionar"} style={{ textAlign: "center" }}>
                  <div>
                    <span>
                      <span className={"contenedorDatos"}>
                        <button id={"seleccionar" + fechaHora} className={"botonesCargaMasiva porSeleccionar"} onClick={this.seleccionar.bind(this, "seleccionar" + fechaHora, fechaHora)}>
                          <label className={"textoBotonesCarga"}>Seleccionar</label>
                        </button>
                      </span>
                    </span>
                  </div>
                </TableCell>
              );
            }
          }

          fechaInicioS = moment(fechaInicioS).add(1, "day").format("YYYY-MM-DD");
        }
        rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
      }
      tabla.push(<TableBody>{rows}</TableBody>);

      this.setState({
        cargahorario: true,
        crearHoras: true,
        tabla: (
          <Table stickyHeader className="bordeTabla" size="small" aria-label="a dense table">
            {tabla}
          </Table>
        ),
      });
    });
  };

  seleccionar(idDiv, fecha) {
    const { arregloDias, arregloTemp, arregloSum, arregloInicio, arregloTermino } = this.state;

    var link = document.getElementById(idDiv);

    if (arregloDias.includes(fecha)) {
      if (arregloTemp.length == 1) {
        this.setState({ arregloTemp: [], arregloSum: [] });

        arregloTermino.push(fecha);
        link.innerHTML = '<i class="fa fa-check-circle-o" style="color: #ffffff" aria-hidden="true"></i> <label style="color:#ffffff;cursor: pointer;">Horario 1 cupo</label>';
        link.style.backgroundColor = "rgb(38, 165, 48)";
        link.style.borderColor = "rgb(38, 165, 48)";
      } else {
        link.innerHTML = '<label class="textoBotonesCarga">Seleccionar</label>';
        link.style.backgroundColor = "#fff0";
        link.style.borderColor = "#000";
        var index = arregloDias.indexOf(fecha);
        arregloDias.splice(index, 1);

        var indexSum = arregloSum.indexOf(fecha);
        arregloSum.splice(indexSum, 1);

        var indexIni = arregloInicio.indexOf(fecha);
        arregloInicio.splice(indexIni, 1);

        var indexTer = arregloTermino.indexOf(fecha);
        arregloTermino.splice(indexTer, 1);
      }
    } else {
      arregloDias.push(fecha);
      arregloSum.push(fecha);

      if (arregloSum.length > 0 && arregloSum.indexOf(fecha) == 0) {
        arregloTemp.push(fecha);
        if (arregloTemp.length > 2) {
          arregloTemp = [];
        }
        arregloInicio.push(fecha);
        link.innerHTML = '<i class="fa fa-check-circle-o" style="color: #ffffff"></i> <label style="color:#ffffff;cursor: pointer;">Inicio</label>';
        link.style.backgroundColor = "rgb(38, 83, 249)";
        link.style.borderColor = "rgb(38, 83, 249)";
      } else {
        arregloTermino.push(fecha);
        link.innerHTML = '<i class="fa fa-check-circle-o" style="color: #ffffff"></i> <label style="color:#ffffff;cursor: pointer;">Termino</label>';
        link.style.backgroundColor = "rgb(189, 37, 37)";
        link.style.borderColor = "rgb(189, 37, 37)";

        this.setState({ arregloSum: [], arregloTemp: [] });
      }
    }
  }

  horariosParaCargar = () => {
    const { arregloHorario, arregloInicio, arregloTermino, rendimiento } = this.state;

    if (arregloInicio.length != 0) {
      var fechaInicio = arregloInicio[0];
    }

    for (var x = 0; x < arregloInicio.length; x++) {
      var fechaInicio = arregloInicio[x];
      var fechaTermino = arregloTermino[x];

      if (fechaInicio == fechaTermino) {
        arregloHorario.push(fechaInicio);
      } else {
        var fecha = fechaInicio;

        var minutosAsumar = parseFloat(rendimiento);

        arregloHorario.push(fecha);
        do {
          fecha = moment(fecha).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");
          arregloHorario.push(fecha);
        } while (fecha < fechaTermino);
      }
    }

    if (arregloInicio.length != arregloTermino.length) {
      confirmAlert({
        title: "",
        message: "Cada inicio debe tener su termino, revise los dias a cargar",
        buttons: [
          {
            label: "Aceptar",
          },
        ],
      });

      this.setState({
        arregloHorario: [],
      });
    } else {
      var horarios = this.state.arregloHorario;
      if (horarios.length == 0) {
        this.mostrarAlerta("No tienes horarios para cargar");
      } else {
        const espNombre = document.getElementById("especialidad").textContent;
        confirmAlert({
          title: "",
          message: "Estas cargando horas para " + espNombre + ", \n" + "Estas seguro que quieres cargar estas horas?",
          buttons: [
            {
              label: "Sí",
              onClick: () => this.cargarHorarioTemporal(),
            },
            {
              label: "No",
            },
          ],
        });
      }
    }
  };

  cargarHorarioTemporal = (e) => {
    var data = {
      horarios: this.state.arregloHorario,
      especialidad: this.state.especialidad,
      tipo: 3,
      profesional: this.state.profesional,
      motivoBloqueo: this.state.motivoBloqueo,
      direccion: this.state.direccion,
      sector: this.state.sector,
      cesfam: this.state.cesfam,
      rendimiento: parseInt(this.state.rendimiento),
      uspId: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "crear_horario_temporal_por_bloqueo/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options)
      .then((res) => {
        if (res.data.estado) {
          this.enviarFormulario(1);
        } else {
          this.setState({
            arregloHorario: [],
            arregloDias: [],
            arregloTemp: [],
            arregloSum: [],
            arregloInicio: [],
            arregloTermino: [],
            tabla: "",
          });

          this.crearHorarios(this.state.semana, this.state.rendimiento, this.state.horaInicio, this.state.horaTermino, 2);
          this.mostrarAlerta(res.data.mensaje);
        }
      })
      .catch((err) => {});
  };

  enviarFormulario = (validarHoras) => {
    var sem = this.state.semana;
    var arrSemana = sem.split("-W");

    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];

    var fechaInicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var fechaTerminoSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

    var data = {
      validarHoras: validarHoras,
      horariosABloquear: this.state.horariosABloquear,
      motivoBloqueo: this.state.motivoBloqueo,
      horasConAgenda: this.state.horasConAgenda,
      sector: this.state.sector,
      cesfam: this.state.cesfam,
      uspId: this.state.uspId,
      fechaInicioSemana: fechaInicioSemana,
      fechaTerminoSemana: fechaTerminoSemana,
      direccion: this.state.direccion,
    };

    let apiUrl = global.config.modulos.agendamientos + "crear_horario_por_bloqueo/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options)
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            arregloHorario: [],
            arregloDias: [],
            arregloTemp: [],
            arregloSum: [],
            arregloInicio: [],
            arregloTermino: [],
            tabla: "",
          });

          this.mostrarAlerta(res.data.mensaje);
          this.salirExito();
        } else {
          this.setState({
            arregloHorario: [],
            arregloDias: [],
            arregloTemp: [],
            arregloSum: [],
            arregloInicio: [],
            arregloTermino: [],
            tabla: "",
          });

          if (res.data.error == "faltanHoras") {
            confirmAlert({
              title: "",
              message: res.data.mensaje + ", Las horas disponibles no permitirá que todos los usuarios reserven, dejándolos sin atención. \n" + "Estas seguro que quieres continuar sin habilitar nuevas horas?",
              buttons: [
                {
                  label: "Continuar con el bloqueo",
                  onClick: () => this.enviarFormulario(2),
                },
                {
                  label: "Cargar más horas",
                  onClick: () => this.crearHorarios(this.state.semana, this.state.rendimiento, this.state.horaInicio, this.state.horaTermino, 2),
                },
              ],
            });
          } else {
            this.mostrarAlerta(res.data.mensaje);
          }
        }
      })
      .catch((err) => {});
  };

  cancelarSubida = () => {
    this.setState({
      arregloHorario: [],
      arregloDias: [],
      arregloTemp: [],
      arregloSum: [],
      arregloInicio: [],
      arregloTermino: [],
      tabla: "",
      crearHoras: false,
      cargahorario: false,
    });
  };

  cancelarCarga = () => {
    confirmAlert({
      title: "",
      message: "¿Seguro(a) que deseas salir recuerda que las horas no subidas se eliminaran?",
      buttons: [
        {
          label: "Sí",
          onClick: () => this.cancelarSubida(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  cambiarProfesionalLista = () => {
    confirmAlert({
      title: "",
      message: "¿Seguro(a) que deseas cambiar de profesional,asegurese de subir todas las horas marcadas antes de cambiar de profesional?",
      buttons: [
        {
          label: "Sí",
          onClick: () => this.cancelarSubida(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  cambiarValoresHora = (valor, hora) => {
    if (hora == "inicio") {
      if (valor > this.state.horaTermino) {
        NotificationManager.warning("La hora de inicio no puede ser mayor a la de término");
      } else {
        this.setState({
          horaInicio: valor,
          arregloHorario: [],
          arregloDias: [],
          arregloTemp: [],
          arregloSum: [],
          arregloInicio: [],
          arregloTermino: [],
          tabla: "",
        });

        this.crearHorarios(this.state.semana, this.state.rendimiento, valor, this.state.horaTermino, 2);
      }
    }
    if (hora == "termino") {
      if (valor < this.state.horaInicio) {
        NotificationManager.warning("La hora de término no puede ser menor a la de inicio");
      } else {
        this.setState({
          horaTermino: valor,
          arregloHorario: [],
          arregloDias: [],
          arregloTemp: [],
          arregloSum: [],
          arregloInicio: [],
          arregloTermino: [],
          tabla: "",
        });

        this.crearHorarios(this.state.semana, this.state.rendimiento, this.state.horaInicio, valor, 2);
      }
    }
  };

  render() {
    const { direcciones, direccion, profesionales, profesional, especialidades, especialidad, sectores, sector, rendimientos, rendimiento, semana, semanaHoy, horaInicio, horaTermino } = this.state;

    const cambioProfesional = (event) => {
      this.setState({
        profesional: event.target.value,
      });

      this.traerEspecialidades(event.target.value);
      this.traerSectores(event.target.value);
    };

    const cambioEspecialidad = (event) => {
      this.setState({
        especialidad: event.target.value,
      });
      this.traerRendimientos(event.target.value);
    };

    const cambioSector = (event) => {
      this.setState({
        sector: event.target.value,
      });
    };

    const cambioRendimiento = (event) => {
      this.setState({
        rendimiento: event.target.value,
        arregloHorario: [],
        arregloDias: [],
        arregloTemp: [],
        arregloSum: [],
        arregloInicio: [],
        arregloTermino: [],
        tabla: "",
      });

      this.crearHorarios(this.state.semana, event.target.value, this.state.horaInicio, this.state.horaTermino, 2);
    };

    const cambiarSemana = (event) => {
      if (this.state.crearHoras) {
        var semana = event.target.value;
        if (semana != "") {
          this.setState({
            semana: semana,
            arregloHorario: [],
            arregloDias: [],
            arregloTemp: [],
            arregloSum: [],
            arregloInicio: [],
            arregloTermino: [],
            tabla: "",
          });

          this.crearHorarios(event.target.value, this.state.rendimiento, this.state.horaInicio, this.state.horaTermino, 1);
        }
      } else {
        this.setState({
          semana: event.target.value,
        });
      }
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalCopiarSemana">
        <DialogContent>
          <div className="cargarHorario" style={{ marginTop: 30, marginBottom: 200 }}>
            <GridContainer>
              <GridItem xs={12}>
                <h2>Crear horas por horas bloqueadas</h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={2} style={{ float: "left", width: "100%" }}>
                    <InputLabel>Profesionales</InputLabel>
                    <Select name="profesional" className={"selectSimple"} value={profesional} style={{ width: "100%" }} onChange={cambioProfesional} disabled={this.state.crearHoras} displayEmpty>
                      {profesionales &&
                        profesionales.map((pr, index) => (
                          <MenuItem value={pr.pro_id} key={index}>
                            {pr.nombreProfesional}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <InputLabel>Semana del horario</InputLabel>
                    <TextField
                      id="semana"
                      name="semana"
                      type="week"
                      value={semana}
                      onChange={cambiarSemana}
                      inputProps={{
                        min: semanaHoy,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} style={{ float: "left", width: "100%" }}>
                    <InputLabel>Tipo de Atención</InputLabel>
                    <Select id="especialidad" name="especialidad" className={"selectSimple"} value={especialidad} style={{ width: "100%" }} onChange={cambioEspecialidad} displayEmpty>
                      {especialidades &&
                        especialidades.map((ta, index) => (
                          <MenuItem value={ta["esp_id"]} key={index}>
                            {ta["esp_nombre"]}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} style={{ float: "left", width: "100%" }}>
                    <InputLabel>Sectores</InputLabel>
                    <Select name="sector" className={"selectSimple"} value={sector} style={{ width: "100%" }} onChange={cambioSector} displayEmpty>
                      {sectores &&
                        sectores.map((sec, index) => (
                          <MenuItem value={sec.sec_id} key={index}>
                            {sec.sec_nombre}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>

                  {direcciones.length > 0 && (
                    <Grid item xs={12} sm={6} md={2} style={{ float: "left" }}>
                      <InputLabel>Dirección</InputLabel>
                      <Select name="direccion" className={"selectSimple"} value={direccion} style={{ width: "100%" }} onChange={this.cambiarValores} displayEmpty>
                        {direcciones.map((dir, index) => (
                          <MenuItem value={dir.hds_id} key={index}>
                            {dir.hds_direccion}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={2} style={{ float: "left", width: "100%" }}>
                    <InputLabel>Rendimiento</InputLabel>
                    <Select name="rendimiento" className={"selectSimple"} value={rendimiento} style={{ width: "100%" }} onChange={cambioRendimiento} displayEmpty>
                      {rendimientos &&
                        rendimientos.map((ren, index) => (
                          <MenuItem value={ren.esr_rendimiento} key={index}>
                            {ren.esr_rendimiento}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={1}>
                        <p>
                          <label
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                          >
                            Hora inicio
                          </label>
                        </p>
                        <TimePicker
                          minuteStep={5}
                          showSecond={false}
                          name="horaInicio"
                          id="horaInicio"
                          value={horaInicio}
                          style={{
                            width: 100,
                          }}
                          onChange={(e) => this.cambiarValoresHora(e, "inicio")}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <p>
                          <label
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                          >
                            Hora Termino
                          </label>
                        </p>
                        <TimePicker
                          minuteStep={5}
                          showSecond={false}
                          name="horaTermino"
                          id="horaTermino"
                          value={horaTermino}
                          style={{
                            width: 100,
                          }}
                          onChange={(e) => this.cambiarValoresHora(e, "termino")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} style={{ float: "right" }}>
                      <Button variant="contained" color="primary" style={{ marginRight: 20 }} onClick={this.crearHorarios.bind(this, this.state.semana, this.state.rendimiento, this.state.horaInicio, this.state.horaTermino, 1)} disabled={this.state.crearHoras} className={this.state.crearHoras ? "btnAccionesDisabledCrearHorario" : "fondoVerde"}>
                        Crear Horario
                      </Button>

                      <Button variant="contained" onClick={this.horariosParaCargar} disabled={!this.state.crearHoras} color="primary" style={{ marginRight: 20 }} className={!this.state.crearHoras ? "btnAccionesDisabledCrearHorario" : "fondoVerde"}>
                        Cargar horario
                      </Button>

                      <Button variant="contained" onClick={this.cancelarCarga} disabled={!this.state.crearHoras} color="primary" style={{ marginRight: 20 }} className={!this.state.crearHoras ? "btnAccionesDisabledCrearHorario" : "fondoNaranjo"}>
                        Cancelar carga
                      </Button>

                      <Button variant="contained" onClick={this.cambiarProfesionalLista} disabled={!this.state.crearHoras} color="primary" style={{ marginRight: 20 }} className={!this.state.crearHoras ? "btnAccionesDisabledCrearHorario" : "fondoNaranjo"}>
                        Elegir otro profesional
                      </Button>
                    </Grid>
                  </Grid>

                  {this.state.cargahorario ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div
                        id="tablahorarioProfesional"
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <TableContainer>{this.state.tabla}</TableContainer>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
