import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GraficoPrincipal from "components/Graficos/Ranking/GraficoPrincipal";
import GraficoVersusCanales from "components/Graficos/Ranking/GraficoCanales";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import signo from "assets/img/signo.svg";

import Cargando from "components/Helpers/Cargando";
import { store } from "../../../store/index";

import DatePicker from "react-multi-date-picker";
import axios from "axios";
import img from "../../../assets/img/icono calendario.svg";
import "assets/css/graficosDM.css";
import "assets/css/usuarios.css";

export default class Ranking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarGraficoPrincipal: false,
      mostrarGraficoCanales: false,
      fecha: "",
      filtro: false,
      comuna: "",
      sectore: "",
      cesfam: "",
      sexo: "",
      fecha_inicio: "",
      fecha_final: "",
      sexos: [],
      comunas: [],
      cesfams: [],
      cesfamsVisibles: [],
      especialidad: "",
      especialidades: [],
      sectores: [],
      canal: "",
      canales: [],
      today: new Date(),
      tipo: "Persona",
      displayP: "",
      displayC: "",
      displayCanal: "",
      cargando: true,
      displayPirncipal: "",
      idCliente: store.getState().cliente,
      usp: store.getState().usp,
      listaTipGraf: false,
      listaComunas: false,
      listaCesfam: false,
      listaSector: false,
      listaSexo: false,
      listaCanales: false,
      listaEspc: false,
      btn1: "Tipo de Grafico",
      com_nombre: "Comuna",
      ces_nombre: "Cesfam",
      sec_nombre: "Sector",
      sex_nombre: "Genero",
      can_nombre: "Canal",
      esp_nombre: "Especialidad",
      fec: null,
      fec_ini: null,
      fec_ter: null,
    };
  }

  actualizarDatos = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.datosSelecCesfam();
      this.datosSelecSector();
      this.datosSelecEsp();
      this.datosSelecCanales();
    });
  };

  actualizarFecha = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha: null,
          fec: null,
          fecha_inicio: null,
          fec_ini: null,
          fecha_final: null,
          fec_ter: null,
        },
        () => {
          this.setState(
            {
              fecha: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
              fec: fecha,
              listaTipGraf: false,
              listaComunas: false,
              listaCesfam: false,
              listaSector: false,
              listaSexo: false,
              listaCanales: false,
              listaEspc: false,
            },
            () => {
              this.mostrarPrincipal();
            }
          );
        }
      );
    }
  };

  fechaLimpiar = () => {
    this.setState({
      fecha: null,
      fec: null,
    });
  };

  actualizarFechaInicial = (fecha) => {
    if (fecha) {
      this.setState(
        { fecha_inicio: null, fec_ini: null, fecha: null, fec: null },
        () => {
          this.setState(
            {
              fecha_inicio:
                fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
              fec_ini: fecha,
              listaTipGraf: false,
              listaComunas: false,
              listaCesfam: false,
              listaSector: false,
              listaSexo: false,
              listaCanales: false,
              listaEspc: false,
            },
            () => {
              if (this.state.fecha_final) {
                this.mostrarPrincipal();
              }
            }
          );
        }
      );
    }
  };

  fechaInicialLimpiar = () => {
    this.setState({
      fecha_inicio: null,
      fec_ini: null,
    });
  };

  actualizarFechaFinal = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha_final: null,
          fec_ter: null,
          fecha: null,
          fec: null,
        },
        () => {
          this.setState(
            {
              fecha_final:
                fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
              fec_ter: fecha,
              listaTipGraf: false,
              listaComunas: false,
              listaCesfam: false,
              listaSector: false,
              listaSexo: false,
              listaCanales: false,
              listaEspc: false,
            },
            () => {
              if (this.state.fecha_inicio) {
                this.mostrarPrincipal();
              }
            }
          );
        }
      );
    }
  };

  fechaFinalLimpiar = () => {
    this.setState({
      fecha_final: null,
      fec_ter: null,
    });
  };

  handleMouseEnterTipGraf = () => {
    this.setState({
      listaTipGraf: true,
      listaComunas: false,
      listaCesfam: false,
      listaSector: false,
      listaSexo: false,
      listaCanales: false,
      listaEspc: false,
    });
  };

  handleMouseOutTipGraf = () => {
    this.setState({
      listaTipGraf: false,
    });
  };

  handleMouseEnterComuna = () => {
    this.setState({
      listaComunas: true,
      listaTipGraf: false,
      listaCesfam: false,
      listaSector: false,
      listaSexo: false,
      listaCanales: false,
      listaEspc: false,
    });
  };

  handleMouseOutComuna = () => {
    this.setState({
      listaComunas: false,
    });
  };

  handleMouseEnterCesfam = () => {
    this.setState({
      listaComunas: false,
      listaTipGraf: false,
      listaCesfam: true,
      listaSector: false,
      listaSexo: false,
      listaCanales: false,
      listaEspc: false,
    });
  };

  handleMouseOutCesfam = () => {
    this.setState({
      listaCesfam: false,
    });
  };

  handleMouseEnterSector = () => {
    this.setState({
      listaComunas: false,
      listaTipGraf: false,
      listaCesfam: false,
      listaSector: true,
      listaSexo: false,
      listaCanales: false,
      listaEspc: false,
    });
  };

  handleMouseOutSector = () => {
    this.setState({
      listaSector: false,
    });
  };

  handleMouseEnterSexo = () => {
    this.setState({
      listaComunas: false,
      listaTipGraf: false,
      listaCesfam: false,
      listaSector: false,
      listaSexo: true,
      listaCanales: false,
      listaEspc: false,
    });
  };

  handleMouseOutSexo = () => {
    this.setState({
      listaSexo: false,
    });
  };

  handleMouseEnterCanal = () => {
    this.setState({
      listaComunas: false,
      listaTipGraf: false,
      listaCesfam: false,
      listaSector: false,
      listaSexo: false,
      listaCanales: true,
      listaEspc: false,
    });
  };

  handleMouseOutCanal = () => {
    this.setState({
      listaCanales: false,
    });
  };

  handleMouseEnterEsp = () => {
    this.setState({
      listaComunas: false,
      listaTipGraf: false,
      listaCesfam: false,
      listaSector: false,
      listaSexo: false,
      listaCanales: false,
      listaEspc: true,
    });
  };

  handleMouseOutEsp = () => {
    this.setState({
      listaEspc: false,
    });
  };

  clickGrafPer = () => {
    this.setState(
      {
        tipo: "Persona",
        displayP: "none",
        displayC: "none",
        displayCanal: "none",
        btn1: "Grafico Por Persona",
      },
      () => {
        this.setState(
          {
            displayP: "",
          },
          () => {
            this.mostrarPrincipal();
          }
        );
      }
    );
  };

  clickGrafCom = () => {
    this.setState(
      {
        tipo: "Comuna",
        displayP: "none",
        displayC: "none",
        displayCanal: "none",
        btn1: "Grafico Por Comuna",
      },
      () => {
        this.setState(
          {
            displayC: "",
          },
          () => {
            this.mostrarPrincipal();
          }
        );
      }
    );
  };

  clickGrafCesf = () => {
    this.setState(
      {
        tipo: "Cesfam",
        displayP: "none",
        displayC: "none",
        displayCanal: "none",
        btn1: "Grafico Por Cesfam",
      },
      () => {
        this.setState({}, () => {
          this.mostrarPrincipal();
        });
      }
    );
  };

  clickVersusCana = () => {
    this.setState(
      {
        tipo: "Canal",
        displayP: "none",
        displayC: "none",
        displayCanal: "none",
        btn1: "Versus Canales",
      },
      () => {
        this.setState(
          {
            displayCanal: "",
          },
          () => {
            this.mostrarPrincipal();
          }
        );
      }
    );
  };

  clickComuna = (id, nom) => {
    this.setState(
      {
        comuna: id,
        com_nombre: nom,
      },
      () => {
        this.datosSelecCesfam();
        this.datosSelecCanales();
        this.datosSelecEsp();
        this.datosSelecSector();
        this.datosSelecSexos();
      }
    );
  };
  clickCesfam = (id, nom) => {
    this.setState(
      {
        cesfam: id,
        ces_nombre: nom,
        sec_nombre: "Sector",
        sex_nombre: "Genero",
        can_nombre: "Canal",
        esp_nombre: "Especialidad",
        especialidad: "",
        sectore: "",
        sexo: "",
        canal: "",
      },
      () => {
        this.datosSelecCanales();
        this.datosSelecEsp();
        this.datosSelecSector();
        this.datosSelecSexos();
        this.mostrarPrincipal();
      }
    );
  };
  clickSector = (nom) => {
    this.setState(
      {
        sectore: nom,
        sec_nombre: nom,
      },
      () => {
        this.datosSelecEsp();
        this.datosSelecSexos();
        this.datosSelecCanales();
        this.mostrarPrincipal();
      }
    );
  };
  clickSexo = (nom) => {
    this.setState(
      {
        sexo: nom,
        sex_nombre: nom,
      },
      () => {
        this.mostrarPrincipal();
      }
    );
  };
  clickCanal = (nom) => {
    this.setState(
      {
        canal: nom,
        can_nombre: nom,
      },
      () => {
        this.datosSelecEsp();
        this.mostrarPrincipal();
      }
    );
  };
  clickEsp = (nom) => {
    this.setState(
      {
        especialidad: nom,
        esp_nombre: nom,
      },
      () => {
        this.mostrarPrincipal();
      }
    );
  };

  datosSelecComuna = () => {
    this.setState({ comunas: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "RankingClientes/", {
        headers: authHeader(),
        params: {
          fecha: this.state.fecha,
          fecha_inicio: this.state.fecha_inicio,
          fecha_final: this.state.fecha_final,
          cli_id: this.state.idCliente,
        },
      })
      .then((resp) => {
        if (!resp.data.estado) {
          return;
        }
        this.setState({ comunas: resp.data.datos }, () => {
          this.setState(
            {
              comuna: this.state.comunas[0]["id_cliente"],
              com_nombre: this.state.comunas[0]["Nombre"],
            },
            () => {
              this.datosSelecCesfam();
              this.datosSelecEsp();
              this.datosSelecSector();
              this.datosSelecCanales();
            }
          );
        });
      });
  };

  datosSelecCesfam = () => {
    this.setState({ cesfams: [] });
    axios
      .get(global.config.modulos.usuariospanel + `por_id/` + this.state.usp, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data) {
          const cesfamsVisibles = res.data["usp_cesfams_visibles"]
            .split(",")
            .map((numero) => parseInt(numero));
          this.setState({ cesfams: [] });

          axios
            .get(global.config.modulos.grafico_demanda + "cesfamCliente/", {
              headers: authHeader(),
              params: {
                comuna: this.state.idCliente,
              },
            })
            .then((res) => {
              if (!res.data.estado) {
                NotificationManager.info("No hay datos");
                return;
              }
              this.setState({
                comuna: res.data.datos[0]["comuna"],
                cesfams: [],
                cesfamsVisibles: [],
              });

              for (let index = 0; index < res.data.datos.length; index++) {
                if (cesfamsVisibles.includes(res.data.datos[index]["id"])) {
                  this.setState((prevState) => ({
                    cesfams: [...prevState.cesfams, res.data.datos[index]],
                    cesfamsVisibles: [
                      ...prevState.cesfamsVisibles,
                      res.data.datos[index]["id"],
                    ],
                  }));
                  console.log(res.data.cesfamsVisibles);
                }

                this.setState({ total_cesfam: this.state.cesfams.length });
              }

              if (this.state.total_cesfam === 0) {
                NotificationManager.info("no tiene cesfams asociados");
              }

              this.mostrarPrincipal();
              this.datosSelecEsp();
            });
        }
      });
  };

  datosSelecEsp = () => {
    this.setState({ especialidades: [] });

    axios
      .get(
        global.config.modulos.grafico_demanda + "RankingEspecialidadesCli/",
        {
          headers: authHeader(),
          params: {
            fecha: this.state.fecha,
            fecha_inicio: this.state.fecha_inicio,
            fecha_final: this.state.fecha_final,
            cesfams: this.state.cesfamsVisibles,
            ces_id: this.state.cesfam,
            sec_id: this.state.sectore,
            can_id: this.state.canal,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.estado) {
          return;
        }
        this.setState({ especialidades: resp.data.datos, cargando: false });
      });
  };

  datosSelecSector = () => {
    this.setState({ sectores: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "RankingSectoresCli/", {
        headers: authHeader(),
        params: {
          fecha: this.state.fecha,
          fecha_inicio: this.state.fecha_inicio,
          fecha_final: this.state.fecha_final,
          cli_id: this.state.idCliente,
          ces_id: this.state.cesfam,
        },
      })
      .then((resp) => {
        if (!resp.data.estado) {
          return;
        }
        this.setState({
          sectores: resp.data.datos,
        });
      });
  };

  datosSelecSexos = () => {
    this.setState({ sexos: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "RankingSexoCli/", {
        headers: authHeader(),
        params: {
          fecha: this.state.fecha,
          fecha_inicio: this.state.fecha_inicio,
          fecha_final: this.state.fecha_final,
          cliente: this.state.idCliente,
          ces: this.state.cesfam,
          sec: this.state.sectore,
        },
      })
      .then((resp) => {
        if (!resp.data.estado) {
          return;
        }
        this.setState({ sexos: resp.data.datos });
      });
  };

  datosSelecCanales = () => {
    this.setState({ canales: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "RankingCanalCli/", {
        headers: authHeader(),
        params: {
          fecha: this.state.fecha,
          fecha_inicio: this.state.fecha_inicio,
          fecha_final: this.state.fecha_final,
          id_cliente: this.state.idCliente,
          id_cesfam: this.state.cesfam,
          sec: this.state.sectore,
        },
      })
      .then((resp) => {
        if (!resp.data.estado) {
          return;
        }
        this.setState({ canales: resp.data.datos });
      });
  };

  mostrarPrincipal = () => {
    if (this.state.tipo !== "Canal") {
      this.setState(
        {
          mostrarGraficoPrincipal: false,
          mostrarGraficoCanales: false,
          filtro: false,
        },
        () => {
          this.setState({ mostrarGraficoPrincipal: true, filtro: true });
        }
      );
    }
    if (this.state.tipo == "Canal") {
      this.setState(
        {
          mostrarGraficoPrincipal: false,
          mostrarGraficoCanales: false,
          filtro: false,
        },
        () => {
          this.setState({
            mostrarGraficoCanales: true,
            filtro: true,
            cargando: false,
          });
        }
      );
    }
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML =
      "Ranking de Policonsultantes  <span title=''><img src=" +
      signo +
      "></img></span>";
  };

  volver = () => {
    if (this.state.mostrarGraficoPrincipal == true) {
      this.setState(
        {
          mostrarGraficoPrincipal: false,
        },
        () => {
          this.setState({
            mostrarGraficoPrincipal: true,
            mostrarGraficoCanales: false,
            sectore: "",
            sexo: "",
            canal: "",
            displayP: "",
            tipo: "Persona",
            displayC: "",
          });
        }
      );
    } else {
      this.mostrarPrincipal();
    }
  };

  botonBorrar = () => {
    this.setState(
      {
        fecha: "",
        comuna: "",
        cesfam: "",
        especialidad: "",
        sectore: "",
        sexo: "",
        canal: "",
        fecha_inicio: "",
        fecha_final: "",
        ces_nombre: "Cesfam",
        sec_nombre: "Sector",
        sex_nombre: "Genero",
        can_nombre: "Canal",
        esp_nombre: "Especialidad",
        fec: null,
        fec_ini: null,
        fec_ter: null,
      },
      () => {
        this.datosSelecCesfam();
        this.datosSelecCanales();
        this.datosSelecEsp();
        this.datosSelecSector();
        this.datosSelecSexos();
      }
    );
  };

  cambiodecomuna = () => {
    this.setState({
      cesfam: "",
      especialidad: "",
      sectore: "",
      sexo: "",
      canal: "",
    });
  };

  cambiodecesfam = () => {
    this.setState({
      especialidad: "",
      sectore: "",
      sexo: "",
      canal: "",
    });
  };

  filtrarDatos = () => {
    this.setState(
      {
        filtro: false,
      },
      () => {
        this.setState({ filtro: true });
        if (this.state.tipo !== "Persona") {
          this.setState({
            displayP: "none",
            displayCanal: "none",
          });
        } else {
          this.setState({
            displayP: "",
            displayCanal: "",
          });
        }
        if (this.state.tipo !== "Comuna") {
          this.setState({
            displayC: "",
          });
        } else {
          this.setState({
            displayC: "none",
          });
        }
        if (this.state.tipo === "Canal") {
          this.setState({
            displayP: "",
            displayCanal: "none",
          });
        }
      }
    );
  };

  componentDidMount() {
    this.datosSelecComuna();
    this.datosSelecCesfam();
    this.datosSelecSector();
    this.datosSelecSexos();
    this.datosSelecCanales();
    this.datosSelecEsp();
    this.titulo();
  }

  render() {
    const {
      fecha,
      comuna,
      cesfam,
      especialidad,
      sectore,
      sexo,
      fecha_inicio,
      fecha_final,
      canal,
    } = this.state;

    return (
      <div
        className="middle-area"
        style={{
          overflowX: "auto",
          height: "81%",
        }}
      >
        <div
          id="asdfg"
          className="Graficos"
          style={{ display: "flex", width: "74rem" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "64rem",
            }}
          >
            <button
              className="arrow-down-button1"
              onClick={() => this.handleMouseEnterTipGraf()}
            >
              <span className="button-text"> {this.state.btn1}</span>
              <span className="arrow">&#9660;</span>
            </button>
            {this.state.listaTipGraf && (
              <ul
                onMouseLeave={() => this.handleMouseOutTipGraf()}
                style={{
                  position: "absolute",
                  background:
                    "linear-gradient(to bottom, #143275, #225093, #3675C1)",
                  width: "11.8rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "1px",
                  left: "0.5rem",
                  top: "2.1rem",
                }}
              >
                <Button
                  style={{ color: "white", borderBottom: "1px solid white" }}
                  onClick={() => this.clickGrafPer()}
                >
                  Grafico por persona
                </Button>
                <Button
                  style={{ color: "white", borderBottom: "1px solid white" }}
                  onClick={() => this.clickGrafCesf()}
                >
                  Grafico por cesfam
                </Button>
                <Button
                  style={{ color: "white", borderBottom: "1px solid white" }}
                  onClick={() => this.clickVersusCana()}
                >
                  Versus Canales
                </Button>
              </ul>
            )}

            <span
              className="arrow-down-button2"
              onClick={() => this.handleMouseEnterComuna()}
            >
              <span className="button-text"> {this.state.com_nombre}</span>
            </span>

            <button
              className="arrow-down-button"
              onClick={() => this.handleMouseEnterCesfam()}
            >
              <span className="button-text">{this.state.ces_nombre}</span>
              <span className="arrow">&#9660;</span>
            </button>
            {this.state.listaCesfam && (
              <ul
                onMouseLeave={() => this.handleMouseOutCesfam()}
                style={{
                  position: "absolute",
                  background:
                    "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                  width: "11.8rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "1px",
                  left: "25.4rem",
                  top: "2.1rem",
                }}
              >
                {this.state.cesfams.map((cesfam) => (
                  <Button
                    value={cesfam.id}
                    style={{ borderBottom: "1px solid white", color: "white" }}
                    onClick={() => this.clickCesfam(cesfam.id, cesfam.nombre)}
                  >
                    {cesfam.nombre}
                  </Button>
                ))}
              </ul>
            )}

            <button
              className="arrow-down-button3"
              onClick={() => this.handleMouseEnterSector()}
            >
              <span className="button-text">{this.state.sec_nombre}</span>
              <span className="arrow">&#9660;</span>
            </button>
            {this.state.listaSector && (
              <ul
                onMouseLeave={() => this.handleMouseOutSector()}
                style={{
                  position: "absolute",
                  background:
                    "linear-gradient(to bottom, #40b56F, #63c189, #79d89f)",
                  width: "11.8rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "1px",
                  left: "37.9rem",
                  top: "2.1rem",
                }}
              >
                {this.state.sectores.map((sector) => (
                  <Button
                    value={sector.sectores}
                    style={{ borderBottom: "1px solid white", color: "white" }}
                    onClick={() => this.clickSector(sector.sectores)}
                  >
                    {sector.sectores}
                  </Button>
                ))}
              </ul>
            )}

            <button
              className="arrow-down-button4"
              onClick={() => this.handleMouseEnterSexo()}
            >
              <span className="button-text">{this.state.sex_nombre}</span>
              <span className="arrow">&#9660;</span>
            </button>
            {this.state.listaSexo && (
              <ul
                onMouseLeave={() => this.handleMouseOutSexo()}
                style={{
                  position: "absolute",
                  background:
                    "linear-gradient(to bottom, #D8275E, #F45175, #FF7B9E)",
                  width: "11.8rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "1px",
                  left: "50.4rem",
                  top: "2.1rem",
                }}
              >
                {this.state.sexos.map((sexo) => (
                  <Button
                    value={sexo.sexos}
                    style={{ borderBottom: "1px solid white", color: "white" }}
                    onClick={() => this.clickSexo(sexo.sexos)}
                  >
                    {sexo.sexos}
                  </Button>
                ))}
              </ul>
            )}

            <button
              className="arrow-down-button5"
              onClick={() => this.handleMouseEnterCanal()}
            >
              <span className="button-text">{this.state.can_nombre}</span>
              <span className="arrow">&#9660;</span>
            </button>
            {this.state.listaCanales && (
              <ul
                onMouseLeave={() => this.handleMouseOutCanal()}
                style={{
                  position: "absolute",
                  background:
                    "linear-gradient(to bottom, #D8B41D, #E8C42A, #F7D857)",
                  width: "11.8rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "1px",
                  left: "0.7rem",
                  top: "5.3rem",
                }}
              >
                {this.state.canales.map((canal) => (
                  <Button
                    value={canal.canal}
                    style={{ borderBottom: "1px solid white", color: "white" }}
                    onClick={() => this.clickCanal(canal.canal)}
                  >
                    {canal.canal}
                  </Button>
                ))}
              </ul>
            )}

            <button
              className="arrow-down-button6"
              onClick={() => this.handleMouseEnterEsp()}
            >
              <span className="button-text">{this.state.esp_nombre}</span>
              <span className="arrow">&#9660;</span>
            </button>
            {this.state.listaEspc && (
              <ul
                onMouseLeave={() => this.handleMouseOutEsp()}
                style={{
                  position: "absolute",
                  background:
                    "linear-gradient(to bottom, #674fa3,#9F7DCC, #CAA2F4)",
                  width: "11.8rem",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "1px",
                  left: "13.1rem",
                  top: "5.3rem",
                }}
              >
                {this.state.especialidades.map((esp) => (
                  <Button
                    value={esp.especia}
                    style={{ borderBottom: "1px solid white", color: "white" }}
                    onClick={() => this.clickEsp(esp.especia)}
                  >
                    {esp.especia}
                  </Button>
                ))}
              </ul>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ fontSize: "0.9vw", marginLeft: "19px" }}>
                Dia especifico
              </label>
              <label style={{ fontSize: "0.6vw", marginLeft: "19px" }}>
                (hasta 6 meses atras)
              </label>
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "11rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  height: "2rem",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  name="fecha"
                  value={this.state.fec}
                  onChange={this.actualizarFecha}
                  weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                  months={[
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octtubre",
                    "Noviembre",
                    "Diciembre",
                  ]}
                  className="grafico_fecha"
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  placeholder="DD/MM/YY"
                  format="DD/MM/YY"
                  maxDate={this.state.today}
                >
                  <div
                    style={{
                      background:
                        "linear-gradient(to bottom, #FCB960, #EAA028)",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Button
                      onClick={this.fechaLimpiar}
                      style={{
                        fontSize: "80%",
                        marginRight: "3%",
                        marginBottom: "3%",
                        background: "white",
                      }}
                    >
                      <span style={{ color: "#E08314" }}>Limpiar</span>
                    </Button>
                  </div>
                </DatePicker>
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0.9%",
              }}
            >
              <label style={{ fontSize: "0.9vw", marginLeft: "19px" }}>
                Desde
              </label>
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "11rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  height: "2rem",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  name="fecha_inicio"
                  value={this.state.fec_ini}
                  onChange={this.actualizarFechaInicial}
                  isClearable={true}
                  weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                  months={[
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octtubre",
                    "Noviembre",
                    "Diciembre",
                  ]}
                  className="grafico_fecha1"
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  placeholder="DD/MM/YY"
                  format="DD/MM/YY"
                  maxDate={this.state.today}
                >
                  <div
                    style={{
                      background: "linear-gradient(to bottom, #FF7DC1,#EF4597)",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Button
                      onClick={this.fechaInicialLimpiar}
                      style={{
                        fontSize: "80%",
                        marginRight: "3%",
                        marginBottom: "3%",
                        background: "white",
                      }}
                    >
                      <span style={{ color: "#DD1F95" }}>Limpiar</span>
                    </Button>
                  </div>
                </DatePicker>
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0.9%",
              }}
            >
              <label style={{ fontSize: "0.9vw", marginLeft: "19px" }}>
                Hasta
              </label>
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "11rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  height: "2rem",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  name="fecha_final"
                  value={this.state.fec_ter}
                  onChange={this.actualizarFechaFinal}
                  isClearable={true}
                  weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                  months={[
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octtubre",
                    "Noviembre",
                    "Diciembre",
                  ]}
                  className="grafico_fecha1"
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  placeholder="DD/MM/YY"
                  format="DD/MM/YY"
                  maxDate={this.state.today}
                >
                  <div
                    style={{
                      background: "linear-gradient(to bottom, #FF7DC1,#EF4597)",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Button
                      onClick={this.fechaFinalLimpiar}
                      style={{
                        fontSize: "80%",
                        marginRight: "3%",
                        marginBottom: "3%",
                        background: "white",
                      }}
                    >
                      <span style={{ color: "#DD1F95" }}>Limpiar</span>
                    </Button>
                  </div>
                </DatePicker>
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            </div>
          </div>

          <div style={{ width: "12rem" }}>
            <Button
              className="botonGeneral"
              onClick={() => {
                this.filtrarDatos();
                this.datosSelecCesfam();
                this.datosSelecEsp();
                this.datosSelecSector();
                this.datosSelecSexos();
                this.datosSelecCanales();
                this.mostrarPrincipal();
              }}
              style={{
                left: "1rem",
                top: "57%",
                height: "2rem",
              }}
            >
              {" "}
              filtrar{" "}
            </Button>

            <Button
              className="botonGeneral"
              onClick={() => this.botonBorrar()}
              style={{
                marginLeft: "16%",
                top: "57%",
                height: "2rem",
              }}
            >
              {" "}
              Limpiar{" "}
            </Button>
          </div>
        </div>

        {this.state.filtro && this.state.mostrarGraficoPrincipal && (
          <GraficoPrincipal
            fecha={this.state.fecha}
            cliente={this.state.comuna}
            cesfam={this.state.cesfam}
            especialidad={this.state.especialidad}
            sector={this.state.sectore}
            sexo={this.state.sexo}
            fecha_inicio={this.state.fecha_inicio}
            fecha_final={this.state.fecha_final}
            canal={this.state.canal}
            tipo={this.state.tipo}
            cargando={this.state.cargando}
            displayPirncipal={this.state.displayPirncipal}
            id_cliente={this.state.idCliente}
            cesfamsVisibles={this.state.cesfamsVisibles}
          />
        )}
        {this.state.filtro && this.state.mostrarGraficoCanales && (
          <GraficoVersusCanales
            fecha={this.state.fecha}
            cliente={this.state.idCliente}
            cesfam={this.state.cesfam}
            sector={this.state.sectore}
            sexo={this.state.sexo}
            fecha_inicio={this.state.fecha_inicio}
            fecha_final={this.state.fecha_final}
            esp={this.state.especialidad}
            cargando={this.state.cargando}
          />
        )}
        <div>{this.state.cargando ? <Cargando /> : null}</div>
      </div>
    );
  }
}
