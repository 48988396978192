import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "../../../assets/css/graficosD.css";

export default class GraficoCanales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      datos: [],
      fecha: props.fecha,
      cliente: props.cliente,
      cesfam: props.cesfam,
      sector: props.sector,
      sexo: props.sexo,
      fecha_inicio: props.fecha_inicio,
      fecha_final: props.fecha_final,
      heiGra: "80%",
      root: "",
      titulo: "Comparacion por canal",
      tipo: props.tipo,
      esp: props.esp,
      cargando: props.cargando,
    };
  }

  GraficoCanales() {
    this.setState({ datas: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "nspXcanal/", {
        headers: authHeader(),
        params: {
          fecha: this.state.fecha,
          fecha_inicio: this.state.fecha_inicio,
          fecha_final: this.state.fecha_final,
          comuna: this.state.cliente,
          cesfam: this.state.cesfam,
          sector: this.state.sector,
          esp: this.state.esp,
          sexo: this.state.sexo,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay NSP");
          this.setState({ cargando: false });
          return;
        }
        this.setState({ datas: res.data.datos }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv");

          root.setThemes([am5themes_Animated.new(root)]);

          root._logo.dispose();

          let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              endAngle: 270,
              layout: root.horizontalLayout,
            })
          );

          let series = chart.series.push(
            am5percent.PieSeries.new(root, {
              valueField: "NumInasistencia",
              categoryField: "canales",
              endAngle: 270,
              tooltip: am5.Tooltip.new(root, {
                autoTextColor: false,
                fill: am5.color(0xff5566),
                pointerOrientation: "left",
                labelText: "{category}: [bold]{value} Horas[/]",
              }),
            })
          );

          var legend = chart.children.push(
            am5.Legend.new(root, {
              centerY: am5.percent(50),
              y: am5.percent(50),
              layout: root.verticalLayout,
            })
          );

          legend.valueLabels.template.setAll({ textAlign: "right" });

          legend.labels.template.setAll({
            maxWidth: 140,
            width: 140,
            oversizedBehavior: "wrap",
          });

          series
            .get("colors")
            .set("colors", [
              am5.color("#FF9327"),
              am5.color("#ef4597"),
              am5.color("#4fc9ba"),
              am5.color("#225093"),
              am5.color("#23bae8"),
              am5.color("#8156c1"),
              am5.color("#71d3a4"),
              am5.color("#f45175"),
              am5.color("#e8c42a"),
              am5.color("#caa2f4"),
            ]);

          series.slices.template.states.create("active", {
            shiftRadius: 1,
            strokeWidth: 2,
          });

          series.labels.template.setAll({
            fontSize: 14,
            fill: am5.color("#808080"),
            text: " [bold]{category}[/]\n {valuePercentTotal.formatNumber('0.00')}%\n {value}",
            textAlign: "left",
          });

          series.slices.template.setAll({
            stroke: am5.color(0xffffff),
            strokeWidth: 4,
          });

          series.slices.template.events.on("click", function (ev) {
            series.slices.each(function (slice) {
              if (slice != ev.target && slice.get("active")) {
                slice.set("active", false);
              }
            });
          });

          series.data.setAll(this.state.datas);
          legend.data.setAll(series.dataItems);
          series.appear(1000, 100);
        });
      });
  }

  componentDidMount() {
    this.GraficoCanales();
  }

  render() {
    return (
      <>
        <div
          id="tablas"
          style={{
            overflowY: "scroll",
            overflowX: "scroll",
            width: "90%",
            height: "70%",
            zIndex: 1,
            position: "fixed",
            textAlign: "center",
          }}
        >
          <h2 style={{ marginTop: "20px" }}>{this.state.titulo} </h2>

          <div
            id="chartdiv"
            style={{
              display: "flex",
              width: "100%",
              height: this.state.heiGra,
              zIndex: 1,
              justifyContent: "flex-start",
            }}
          ></div>
        </div>
      </>
    );
  }
}
