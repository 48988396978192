import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import Cargando from "components/Helpers/Cargando";
import Button from "@material-ui/core/Button";
import graf from "../../../assets/css/graf.module.css";

export default class SectoresInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      datos: [],
      root: "",
      cesfam: props.cesfam,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      semana: props.semana,
      mes: props.mes,
      año: props.año,
      esp: props.esp,
      tipo_graft: props.tipo_graft,
      grafModal: false,
      añoinicio: props.añoinicio,
      añotermino: props.añotermino,
      tipo: "",
      semanaI: props.semanaInicio,
      semanaT: props.semanaTermino,
      mesI: props.mesI,
      mesT: props.mesT,
      cargando: false,
      sectores: [],
      no_agenda: [],
      grafPrincipal: true,
    };
  }
  grafico_SectoresInfo() {
    this.setState({ datas: [], sectores: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "grafico_lineas_sector/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          semana: this.state.semana,
          mes: this.state.mes,
          año: this.state.año,
          esp: this.state.esp,
          tipo: this.state.tipo_graft,
          añoi: this.state.añoinicio,
          añot: this.state.añotermino,
          semanai: this.state.semanaI,
          semanat: this.state.semanaT,
          mesI: this.state.mesI,
          mesT: this.state.mesT,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        } else {
          this.setState(
            {
              datas: res.data.datos,
              sectores: res.data.sectores,
            },
            () => {
              console.log(res.data.colores);
              let root = am5.Root.new("chartdiv222");
              root._logo.dispose();
              const myTheme = am5.Theme.new(root);

              root.locale = am5locales_es_ES;

              myTheme.rule("AxisLabel", ["minor"]).setAll({
                dy: 1,
              });

              myTheme.rule("Grid", ["minor"]).setAll({
                strokeOpacity: 0.08,
              });

              root.setThemes([am5themes_Animated.new(root), myTheme]);

              let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                  layout: root.verticalLayout,
                })
              );

              let cursor = chart.set(
                "cursor",
                am5xy.XYCursor.new(root, {
                  behavior: "none",
                })
              );
              cursor.lineY.set("visible", false);

              let xRenderer = am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 25,
              });

              xRenderer.labels.template.setAll({
                rotation: -60,
                centerY: am5.p50,
                fontSize: 12,
                maxWidth: 105,
                oversizedBehavior: "wrap",
              });

              let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                  categoryField: "Fecha",
                  renderer: xRenderer,
                  tooltip: am5.Tooltip.new(root, {}),
                })
              );

              xRenderer.grid.template.setAll({
                location: 1,
              });
              let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                  maxPrecision: 0,
                  renderer: am5xy.AxisRendererY.new(root, {
                    inversed: false,
                  }),
                })
              );

              xAxis.data.setAll(this.state.datas);

              let thiss = this;

              function createSeries(name, field, color) {
                let series = chart.series.push(
                  am5xy.LineSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: field,
                    categoryXField: "Fecha",
                    fill: color,
                    stroke: color,
                    tooltip: am5.Tooltip.new(root, {
                      pointerOrientation: "horizontal",
                      labelText:
                        "[bold]{categoryX}[/]\n{name}: Oferta final {valueY}",
                      fill: am5.color(0xff5566),
                      autoTextColor: false,
                      keepTargetHover: true,
                    }),
                  })
                );

                series.bullets.push(function () {
                  var circle = am5.Circle.new(root, {
                    radius: 5,
                    fill: series.get("fill"),
                  });

                  circle.events.on("click", function (ev) {
                    thiss.setState({
                      cargando: true,
                    });
                    axios
                      .get(
                        global.config.modulos.grafico_demanda +
                          "grafico_modal_sector/",
                        {
                          headers: authHeader(),
                          params: {
                            esp: thiss.state.esp,
                            cesfam: thiss.state.cesfam,
                            fecha: ev.target.dataItem.dataContext.Fecha,
                            tipo: thiss.state.tipo_graft,
                            sector: ev.target.dataItem.component._settings.name,
                          },
                        }
                      )
                      .then((resp) => {
                        thiss.setState({ datos: [], grafModal: true });
                        if (!resp.data.datos) {
                          NotificationManager.info("No hay datos");
                          return;
                        } else {
                          thiss.setState(
                            {
                              datos: resp.data.datos,
                            },
                            () => {
                              let root1 = am5.Root.new("chartdivModal");
                              root1._logo.dispose();
                              root1.setThemes([am5themes_Animated.new(root1)]);

                              let chart1 = root1.container.children.push(
                                am5xy.XYChart.new(root1, {})
                              );

                              let cursor1 = chart1.set(
                                "cursor",
                                am5xy.XYCursor.new(root1, {})
                              );
                              cursor1.lineY.set("visible", false);

                              let xRenderer1 = am5xy.AxisRendererX.new(root1, {
                                minGridDistance: 30,
                                minorGridEnabled: true,
                              });

                              xRenderer1.labels.template.setAll({
                                rotation: -90,
                                centerY: am5.p50,
                                centerX: am5.p100,
                                paddingRight: 15,
                                oversizedBehavior: "wrap",
                                maxWidth: 130,
                                textAlign: "center",
                              });

                              xRenderer1.grid.template.setAll({
                                location: 1,
                              });

                              let xAxis1 = chart1.xAxes.push(
                                am5xy.CategoryAxis.new(root1, {
                                  maxDeviation: 0.3,
                                  categoryField: "sector",
                                  renderer: xRenderer1,
                                  tooltip: am5.Tooltip.new(root1, {}),
                                })
                              );

                              let yRenderer1 = am5xy.AxisRendererY.new(root1, {
                                strokeOpacity: 0.1,
                              });

                              let yAxis1 = chart1.yAxes.push(
                                am5xy.ValueAxis.new(root1, {
                                  maxDeviation: 0.3,
                                  renderer: yRenderer1,
                                })
                              );

                              let series1 = chart1.series.push(
                                am5xy.ColumnSeries.new(root1, {
                                  name: "Series 1",
                                  xAxis: xAxis1,
                                  yAxis: yAxis1,
                                  valueYField: "total",
                                  sequencedInterpolation: true,
                                  categoryXField: "sector",
                                  tooltip: am5.Tooltip.new(root1, {
                                    labelText: "oferta final: {valueY}",
                                    autoTextColor: false,
                                    fill: am5.color(0xff5566),
                                  }),
                                })
                              );

                              series1.columns.template.setAll({
                                cornerRadiusTL: 5,
                                cornerRadiusTR: 5,
                                strokeOpacity: 0,
                              });
                              series1.columns.template.adapters.add(
                                "fill",
                                function (fill, target) {
                                  return chart1
                                    .get("colors")
                                    .getIndex(series1.columns.indexOf(target));
                                }
                              );

                              series1.columns.template.adapters.add(
                                "stroke",
                                function (stroke, target) {
                                  return chart1
                                    .get("colors")
                                    .getIndex(series1.columns.indexOf(target));
                                }
                              );

                              chart1
                                .get("colors")
                                .set("colors", [
                                  am5.color("#eaa028"),
                                  am5.color("#ef4597"),
                                  am5.color("#6e45af"),
                                  am5.color("#4fc9ba"),
                                ]);

                              let data1 = thiss.state.datos;

                              xAxis1.data.setAll(data1);
                              series1.data.setAll(data1);

                              series1.on("visible", function (visible, target) {
                                if (visible) {
                                  thiss.setState({ cargando: false });
                                }
                              });

                              series1.appear(1000);
                              let exporting1 =
                                am5plugins_exporting.Exporting.new(root1, {
                                  menu: am5plugins_exporting.ExportingMenu.new(
                                    root1,
                                    {}
                                  ),
                                  pngOptions: {
                                    quality: 0.8,
                                    maintainPixelRatio: true,
                                  },
                                  htmlOptions: {
                                    disabled: true,
                                  },
                                  pdfdataOptions: {
                                    disabled: true,
                                  },
                                  jsonOptions: {
                                    disabled: true,
                                  },
                                  printOptions: {
                                    disabled: true,
                                  },
                                  pdfOptions: {
                                    addURL: false,
                                    includeData: true,
                                    quality: 1,
                                    addColumnNames: true,
                                  },
                                  dataSource: data1,
                                  filePrefix: "DatosGraficoCesfam",
                                });
                              chart1.appear(1000, 100);
                            }
                          );
                        }
                      })
                      .catch((error) => {
                        console.error("error", error);
                      });
                  });

                  return am5.Bullet.new(root, {
                    sprite: circle,
                  });
                });

                series.set("setStateOnChildren", true);
                series.states.create("hover", {});

                series.mainContainer.set("setStateOnChildren", true);
                series.mainContainer.states.create("hover", {});

                series.strokes.template.states.create("hover", {
                  strokeWidth: 4,
                });

                let data = thiss.state.datas;
                series.data.setAll(data);
                series.appear(1000);
              }

              let i = 0;
              let c = 0;
              for (const item of this.state.sectores) {
                if (c < this.state.sectores.length) {
                  createSeries(item, item, res.data.colores[c]);
                }
                c = c + 1;
              }

              chart.set(
                "scrollbarX",
                am5.Scrollbar.new(root, {
                  orientation: "horizontal",
                  marginBottom: 20,
                })
              );

              let legend = chart.children.push(
                am5.Legend.new(root, {
                  centerX: am5.p50,
                  x: am5.p50,
                  clickTarget: "none",
                })
              );

              legend.itemContainers.template.states.create("hover", {});

              legend.itemContainers.template.events.on(
                "pointerover",
                function (e) {
                  e.target.dataItem.dataContext.hover();
                }
              );
              legend.itemContainers.template.events.on(
                "pointerout",
                function (e) {
                  e.target.dataItem.dataContext.unhover();
                }
              );

              legend.data.setAll(chart.series.values);

              let exporting = am5plugins_exporting.Exporting.new(root, {
                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                dataSource: this.state.datas,
                title: ``,
                pdfOptions: {
                  imageFormat: "jpg",
                  includeData: true,
                  addColumnNames: true,
                },
                htmlOptions: {
                  disabled: true,
                },
                jpgOptions: {
                  disabled: true,
                },
                csvOptions: {
                  disabled: true,
                },
                jsonOptions: {
                  disabled: true,
                },
                printOptions: {
                  disabled: true,
                },
                xlsxOptions: {
                  imageFormat: "jpg",
                  includeData: true,
                  addColumnNames: true,
                },
                pngOptions: {
                  disabled: true,
                },
                pdfdataOptions: {
                  disabled: true,
                },
                filePrefix: `Grafico Oferta por Sector`,
              });
              chart.appear(1000, 100);
            }
          );
        }
      });
  }
  componentDidMount() {
    this.grafico_SectoresInfo();
  }

  onClose = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({
        grafModal: false,
      });
    }
  };
  render() {
    return (
      <div>
        {this.state.grafPrincipal && (
          <div
            id="chartdiv222"
            style={{
              width: "84%",
              height: "70%",
              zIndex: 0,
              position: "fixed",
              left: "4%",
            }}
          ></div>
        )}

        {this.state.grafModal && (
          <div
            className="modal"
            onClick={(e) => this.onClose(e)}
            style={{
              display: "block",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 2,
              background: "rgba(0,0,0,0.5)",
            }}
          >
            <div
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
              style={{
                zIndex: 3,
                position: "absolute",
                top: "56%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                background: "#fff",
                width: "74%",
                height: "76%",
                borderRadius: "8px",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "10%",
                  marginTop: "5%",
                  marginLeft: "3%",
                }}
              >
                <h3
                  style={{
                    color: "rgb(102, 102, 102)",
                    fontSize: "1.5rem",
                    position: "relative",
                    left: "2%",
                    marginBottom: "20px",
                  }}
                >
                  Grafico de Oferta final por sectores
                </h3>
              </div>

              <div
                id="chartdivModal"
                style={{
                  width: "90%",
                  height: "77%",
                  marginLeft: "4%",
                }}
              ></div>
            </div>
            <div></div>
          </div>
        )}
        {this.state.cargando ? <Cargando style={{ width: "80%" }} /> : null}
      </div>
    );
  }
}
