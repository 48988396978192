import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
/* eslint-disable no-use-before-define */

import { store } from "store/index";
import { Grid } from "@material-ui/core";
import GridItem from "components/grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";

//IMG

import esp_confirmacion from "assets/img/solicitud_trabajo/solicitud_espera_confirmacion.svg";
import recibido from "assets/img/solicitud_trabajo/recibimos_solicitud.svg";
import recibido_gris from "assets/img/solicitud_trabajo/recibimos_solicitud_gris.svg";
import enEspera from "assets/img/solicitud_trabajo/esperando_ejecutivo.svg";
import enEspera_gris from "assets/img/solicitud_trabajo/esperando_ejecutivo_gris.svg";
import asignada from "assets/img/solicitud_trabajo/solicitud_asignada.svg";
import asignada_gris from "assets/img/solicitud_trabajo/solicitud_asignada_gris.svg";
import aprobado from "assets/img/solicitud_trabajo/solicitud_aprobada.svg";
import rechazado from "assets/img/solicitud_trabajo/solicitud_rechazada.svg";
import apr_rec_gris from "assets/img/solicitud_trabajo/solicitud_aprobada_rechazada_gris.svg";
import entregado from "assets/img/solicitud_trabajo/entregado.svg";
import entregado_gris from "assets/img/solicitud_trabajo/entregado_gris.svg";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

//JS
import IngresarSolicitud from "../Ticket/IngresarSolicitud";
import ModalAlerta from "../Ticket/modalAlerta";

export default class VisualTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [],
      contenido: [],

      estado: "",
      fecha_soli: "",
      fecha_estimada: "",
      historial: [props.historial],

      visualTicket: false,
      modalDeAlerta: false,
    };
  }

  componentDidMount() {}

  verTicket = (event) => {
    event.preventDefault();
    this.buscar_ticket();
  };

  cambiarVista = (id, tipo) => {
    if (tipo == 1) {
      this.setState(
        {
          IngresoSolicitud: false,
          buscador_ticket: id,
        },
        () => {
          this.buscar_ticket();
        }
      );
    } else if (tipo == 2) {
      this.setState({
        IngresoSolicitud: false,
      });
    }
  };

  buscar_ticket = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";

    if (this.state.buscador_ticket != "") {
      axios
        .get(apiUrl, {
          headers: authHeader(),
          params: {
            solicitud_id: this.state.buscador_ticket,
            uspId: store.getState().usp,
            tipo: 1,
          },
        })
        .then((res) => {
          const datos = res.data;
          if (datos.estado) {
            this.setState({
              IngresoSolicitud: false,
              visualTicket: true,
              info: datos.datos,
              solicitudes: datos.todo,
              historial: datos.historial,
              estado: datos.datos["sol_estado"],
              motivo_pausa: datos.datos["sol_motivo_pausa"],
            });
          } else {
            let contenido = [];
            contenido.push(
              <div>
                <h2 style={{ color: "#808080", fontSize: "2.5rem", textAlign: "center" }}>¡Atención!</h2>
                <br />
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ textAlign: "center" }} for="motivo_rechazo">
                    {datos.mensaje}
                  </label>
                </div>
                <br />
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={this.cerrar} className={SolicitudTrabajo.btnAcciones} style={{ width: "10vw", margin: "0" }}>
                    Cerrar
                  </button>
                </div>
              </div>
            );
            this.setState({ modalDeAlerta: true, contenido: contenido });
          }
        });
    } else {
      let contenido = [];
      contenido.push(
        <div>
          <h2
            style={{
              color: "#808080",
              fontSize: "2.5rem",
              textAlign: "center",
            }}>
            ¡Atención!
          </h2>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <label for="motivo_rechazo">Ingresa un número de ticket de trabajo para revisar su estado.</label>
          </div>

          <br />
          <br />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.cerrar} className={SolicitudTrabajo.btnAcciones} style={{ width: "10vw", margin: "0" }}>
              Cerrar
            </button>
          </div>
        </div>
      );
      this.setState({ modalDeAlerta: true, contenido: contenido });
    }
  };

  cerrar = () => {
    this.setState({
      modalDeAlerta: false,
    });
  };

  IngresarSolicitud = () => {
    this.setState({
      IngresoSolicitud: true,
      visualTicket: false,
    });
  };

  formateoFecha = () => {
    let fecha_estimada = this.state.historial["hsl_aprobada_cesfam"];
    let fecha_solicitud = this.state.info["sol_fec_crear_soli"];
    var nuevaFecha = fecha_solicitud.split("T");

    let updatedDate = new Date(fecha_estimada);

    let dia_semana = updatedDate.getDay();

    if (dia_semana == 5) {
      updatedDate.setDate(updatedDate.getDate() + 3);
    } else if (dia_semana == 4) {
      updatedDate.setDate(updatedDate.getDate() + 4);
    } else {
      updatedDate.setDate(updatedDate.getDate() + 2);
    }

    let nuevaFecha2 = updatedDate.toISOString().split("T");
    this.setState({
      fecha_estimada: nuevaFecha2,
      fecha_soli: nuevaFecha,
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { estado, IngresoSolicitud, visualTicket, modalDeAlerta, contenido } = this.state;
    return (
      <>
        <GridItem xs={12} sm={12} md={12} style={{ display: "flex", width: "35%", justifyContent: "space-between" }}>
          <div style={{ width: "100%" }}>
            <form onSubmit={this.verTicket} style={{ width: "100%", display: "flex" }}>
              <input
                type="number"
                id="buscador_ticket"
                name="buscador_ticket"
                placeholder="Ingresa tu número de ticket"
                onChange={this.cambiarValores}
                className={SolicitudTrabajo.buscador}
              />
              <a className={SolicitudTrabajo.botonRevisatTicket} onClick={this.verTicket}>
                Revisar
              </a>
            </form>
          </div>

          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <a className={SolicitudTrabajo.botonIngresarSolicitud} onClick={this.IngresarSolicitud} type="submit">
              Ingresar ticket
            </a>
          </div>
        </GridItem>

        {visualTicket && (
          <GridContainer>
            <Grid item xs={12} sm={12} style={{ paddingLeft: 20, width: "100%", marginTop: "2%" }}>
              <InputLabel style={{ fontSize: "2.5rem", fontWeight: "bold" }}>Ticket Nº {this.state.info["sol_codigo"]}</InputLabel>
              <div style={{ display: "flex", marginTop: "3%" }}>
                <>
                  {estado == 0 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={esp_confirmacion} />
                      <p>
                        Ticket a la espera de <br />
                        confirmación por superior.
                      </p>
                    </div>
                  ) : (
                    <div className={SolicitudTrabajo.contenedorBarra_pasado}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={esp_confirmacion} />
                      <p>
                        Ticket a la espera de <br />
                        confirmación por superior.
                      </p>
                    </div>
                  )}
                  {estado == 1 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={rechazado} />
                      <p>Tu ticket ha sido denegada porque no cuenta con aprobación de superior.</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {estado == 2 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={recibido} />
                      <p>Hemos recibido tu ticket.</p>
                    </div>
                  ) : estado < 2 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra_gris}></div>
                      <img src={recibido_gris} style={{ width: "40%" }} />
                    </div>
                  ) : (
                    <div className={SolicitudTrabajo.contenedorBarra_pasado}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={recibido} />
                      <p>Hemos recibido tu ticket.</p>
                    </div>
                  )}
                  {estado < 3 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra_gris}></div>
                      <img src={apr_rec_gris} style={{ width: "40%" }} />
                    </div>
                  ) : estado == 3 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={aprobado} />
                      <p>
                        Tu ticket ha sido <b>aprobada</b>
                      </p>
                    </div>
                  ) : estado == 4 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={rechazado} />
                      <p>
                        Tu ticket ha sido <b>rechazada</b> por el motivo de '{this.state.info["sol_motivo_reprogracion"]}'
                      </p>
                    </div>
                  ) : (
                    <div className={SolicitudTrabajo.contenedorBarra_pasado}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={aprobado} />
                      <p>
                        Tu ticket ha sido <b>aprobada</b>
                      </p>
                    </div>
                  )}

                  {estado == 5 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={asignada} />
                      <p>Tu ticket ha sido asignada a un ingeniero. Fecha estimada de entrega: '{this.state.info["sol_entrega_aprox_cliente"]}'</p>
                    </div>
                  ) : estado < 5 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra_gris}></div>
                      <img src={asignada_gris} style={{ width: "40%" }} />
                    </div>
                  ) : (
                    <div className={SolicitudTrabajo.contenedorBarra_pasado}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={asignada} />
                      <p>Tu ticket ha sido asignada a un ingeniero</p>
                    </div>
                  )}

                  {estado == 6 || estado == 8 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={enEspera} />
                      {estado == 8 && <p>Tu ticket fue pausada por: {this.state.motivo_pausa} </p>}
                      {estado == 6 && <p>Tu ticket está siendo revisada por un especialista en calidad.</p>}
                    </div>
                  ) : estado < 6 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra_gris}></div>
                      <img src={enEspera_gris} style={{ width: "40%" }} />
                    </div>
                  ) : (
                    <div className={SolicitudTrabajo.contenedorBarra_pasado}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={enEspera} />
                      <p>Tu ticket está siendo revisada por un especialista en calidad.</p>
                    </div>
                  )}

                  {estado == 7 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={entregado} />
                      <p>Entregado.</p>
                    </div>
                  ) : estado < 7 || estado == 8 ? (
                    <div className={SolicitudTrabajo.contenedorBarra}>
                      <div className={SolicitudTrabajo.barra_gris}></div>
                      <img src={entregado_gris} style={{ width: "40%" }} />
                    </div>
                  ) : (
                    <div className={SolicitudTrabajo.contenedorBarra_pasado}>
                      <div className={SolicitudTrabajo.barra}></div>
                      <img src={entregado} />
                      <p>Entregado.</p>
                    </div>
                  )}
                </>
              </div>
              <div className={SolicitudTrabajo.contendorInfo}>
                <div
                  style={{
                    marginRight: "10%",
                    width: "50%",
                  }}>
                  <h5>
                    Número de ticket: <span>{this.state.info["sol_id"]}</span>
                  </h5>
                  <h5>
                    Cesfam: <span>{this.state.info["ces_nombre"]}</span>
                  </h5>
                  <h5>
                    Fecha de ingreso solicitud: <span>{this.state.info["fecha_solicitud"]}</span>
                  </h5>
                  <h5>
                    Fecha estimada de respuesta:{" "}
                    <span>
                      {estado == 0
                        ? "En espera de confirmación de su superior."
                        : estado == 1
                        ? "La solicitud ha sido rechazada por un cargo superior, por el siguiente Motivo:" + this.state.historial["hsl_motivo"]
                        : estado == 2
                        ? "HoraSalud ha recibido tu solicitud."
                        : estado == 3
                        ? "La solicitud ha sido aprobada por HoraSalud"
                        : estado == 4
                        ? "La solicitud ha sido rechazada por HoraSalud, por el siguiente motivo: " + this.state.info["sol_motivo_reprogracion"]
                        : estado == 5
                        ? this.state.info["sol_entrega_aprox_cliente"]
                        : estado == 6
                        ? this.state.info["sol_entrega_aprox_cliente"]
                        : estado == 7
                        ? "La solicitud ha finalizado exitosamente."
                        : null}
                    </span>
                  </h5>
                </div>
                <div style={{ width: "50%" }}>
                  <h5>
                    Motivo de ticket: <span>{this.state.info["sol_motivo"]}</span>
                  </h5>
                  {estado == 7 && this.state.info["sol_obs_final_cliente"] && (
                    <h5>
                      Observación: <span>{this.state.info["sol_obs_final_cliente"]}</span>
                    </h5>
                  )}
                </div>
              </div>
            </Grid>
          </GridContainer>
        )}

        {IngresoSolicitud && <IngresarSolicitud cambiarVista={this.cambiarVista} modalIngreso={IngresoSolicitud} />}
        {modalDeAlerta ? <ModalAlerta modal={modalDeAlerta} contenido={contenido} cerrarModal={this.cerrar} /> : null}
      </>
    );
  }
}
