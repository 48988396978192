import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";

//IMG
import imgPersonalDatos from "../../assets/img/profesionales/datos_profesionales_icon.svg";
import imgMedicoDatos from "../../assets/img/profesionales/medico-datos.svg";

//CSS
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import InformacionUsuarioCSS from "./../../assets/css/usuarios.module.css";
import { Height } from "@material-ui/icons";

//JS

export default class DatosProfesional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      proId: this.props.profesional,
      horarios: [],
      especialidadesTodas: [],
      especialidades: [],
      especialidad: "",
      sectores: [],
      sector: "",
      tabla: "",
      fechaHorario: "",
      especialidadHorario: "",
      proNombre: "",
      proRut: "",
      proSexo: "",
      proCorreo: "",
      proAusente: "",
      especialidadFiltro: "",
      semanaFiltro: "",

      tamanio: props.tamanio,
      cargahorariodiario: false,
      horariodiario: false,
    };
  }

  componentDidMount() {
    this.traerDatosProfesional(this.props.profesional);
  }

  componentDidUpdate() {
    if (this.props.tamanio != this.state.tamanio) {
      this.setState(
        {
          tamanio: this.props.tamanio,
        },
        () => {
          this.cambiarTamanio(this.state.tamanio);
        }
      );
    }
  }

  cambiarTamanio = (tipo) => {
    this.removerClasses();
    const allElements = document.querySelectorAll("[name='labelTam']");
    let datos = document.getElementById("divAltura_datos").offsetHeight;
    let sectores = document.getElementById("divAltura_sectores").offsetHeight;
    console.log(datos, sectores);

    allElements.forEach((element) => {
      if (tipo == "1") {
        element.classList.add(InformacionUsuarioCSS.label_11);
      } else if (tipo == "2") {
        element.classList.add(InformacionUsuarioCSS.label_13);
      } else if (tipo == "3") {
        element.classList.add(InformacionUsuarioCSS.label_16);
      } else if (tipo == "4") {
        element.classList.add(InformacionUsuarioCSS.label_20);
      }
    });

    axios
      .patch(
        global.config.modulos.usuariospanel + "cambio_tamanio_letra/",
        {
          usuarioPanel: store.getState().usp,
          tipo_letra: tipo,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.setState({ tamanio: tipo });

        if (datos > sectores) {
          document.getElementById("divAltura_sectores").style.height = datos + "px";
        } else if (datos < sectores) {
          document.getElementById("divAltura_datos").style.height = sectores + "px";
        }
      });
  };

  removerClasses = () => {
    const allElements = document.querySelectorAll("[name='labelTam']");

    allElements.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.label_11, InformacionUsuarioCSS.label_13, InformacionUsuarioCSS.label_16, InformacionUsuarioCSS.label_20);
    });
  };
  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerDatosProfesional(proId) {
    let apiUrl = global.config.modulos.profesionales + "por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      var nombre = res.data["pro_nombre"] + " " + res.data["pro_apellido_p"];
      var rut = res.data["pro_rut"];

      if (res.data["pro_sexo"] == "M") {
        res.data["pro_sexo"] = "Masculino";
      } else {
        res.data["pro_sexo"] = "Femenino";
      }
      var sexo = res.data["pro_sexo"];

      var correo = res.data["pro_correo"];

      var ausente = "--";
      if (res.data["pro_ausente"] == 1) {
        ausente = "Vacaciones";
      } else if (res.data["pro_ausente"] == 2) {
        ausente = "Licencia";
      }

      this.setState(
        {
          proAusente: ausente,
          proNombre: nombre,
          proSexo: sexo,
          proRut: rut,
          proCorreo: correo,
        },
        this.props.asignarTam()
      );

      this.traerEspecialidades(proId);
      this.traerSectores(proId);
    });
  }

  traerEspecialidades(proId) {
    const apiUrl = global.config.modulos.profesionales + "especialidades_por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.datos && res.data.datos.length > 0) {
          this.setState({
            especialidades: res.data.datos,
            especialidad: res.data.datos[0]["esp_nombre"],
          });
        } else {
          let espResult = [];
          let esp = {
            esp_nombre: "No tiene",
          };
          espResult.push(esp);
          this.setState({
            especialidades: espResult,
            especialidad: espResult[0]["esp_nombre"],
          });
        }
      });
  }

  traerSectores(proId) {
    const apiUrl = global.config.modulos.profesionales + "sectores_por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.datos && res.data.datos.length > 0) {
          this.setState({
            sectores: res.data.datos,
            sector: res.data.datos[0]["sec_nombre"],
          });
        } else {
          let secResult = [];
          let sec = {
            sec_nombre: "No tiene",
          };
          secResult.push(sec);
          this.setState({
            sectores: secResult,
            sector: secResult[0]["sec_nombre"],
          });
        }
      });
  }

  render() {
    const { especialidades, especialidad, sectores, sector, especialidadFiltro, semanaFiltro } = this.state;

    return (
      <div>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: "1vw" }}>
          <Grid id={"vistaProfesionalDatos"} item xs={12} sm={12} md={6} style={{ flexBasis: "49%" }}>
            <div>
              <img className={this.state.tamanio > 2 ? InformacionUsuarioCSS.img_tam_2_pro : InformacionUsuarioCSS.img_tam_pro} src={imgPersonalDatos} onClick={this.vista1Ficha} style={{ width: "52px" }} />

              <div id="divAltura_datos" className={InformacionUsuarioCSS.datosProfesional}>
                <Grid container spacing={2} className="contenidoDatosUsuario">
                  <Grid className="datosIndividuales" item xs={12}>
                    <label name="labelTam" className="tituloDatosUsuario">
                      Nombre:
                    </label>
                    <label name="labelTam">{this.state.proNombre}</label>
                  </Grid>
                  <Grid className="datosIndividuales" item xs={12}>
                    <label name="labelTam" className="tituloDatosUsuario">
                      Rut:
                    </label>
                    <label name="labelTam">{this.state.proRut}</label>
                  </Grid>

                  <Grid className="datosIndividuales" item xs={12}>
                    <label name="labelTam" className="tituloDatosUsuario">
                      Sexo:
                    </label>
                    <label name="labelTam">{this.state.proSexo}</label>
                  </Grid>
                  <Grid className="datosIndividuales" item xs={12}>
                    <label name="labelTam" className="tituloDatosUsuario">
                      Correo:
                    </label>
                    <label name="labelTam">{this.state.proCorreo ? "Informado" : "No informado"}</label>
                  </Grid>
                  <Grid className="datosIndividuales" item xs={12}>
                    <label name="labelTam" className="tituloDatosUsuario">
                      Estado:
                    </label>
                    <label name="labelTam">Activo</label>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid id={"vistaProfesionalEsp"} item xs={12} sm={12} md={6} style={{ flexBasis: "49%" }}>
            <div>
              <img className={InformacionUsuarioCSS.img_tam_2} src={imgMedicoDatos} onClick={this.vista1Ficha} />
              <div id="divAltura_sectores" className={InformacionUsuarioCSS.datosProfesional}>
                <Grid container spacing={2} className="contenidoDatosUsuario">
                  <Grid className="datosIndividuales" item xs={6}>
                    <FormControl variant="outlined" className="selectProfesionales">
                      <p style={{ marginBottom: 5 }}>
                        <label name="labelTam">Sectores:</label>
                      </p>
                      {sectores &&
                        sectores.map((sec, index) => (
                          <p>
                            <label name="labelTam">{sec.sec_nombre}</label>
                          </p>
                        ))}
                    </FormControl>
                  </Grid>
                  <Grid className="datosIndividuales" item xs={6}>
                    <FormControl variant="outlined" className="selectProfesionales">
                      <p style={{ marginBottom: 5 }}>
                        <label name="labelTam">Tipos de atenciones</label>
                      </p>
                      {especialidades &&
                        especialidades.map((esp, index) => (
                          <p>
                            <label name="labelTam">{esp.esp_nombre}</label>
                          </p>
                        ))}
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
