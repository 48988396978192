import React, { useState, useEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import { connect } from "react-redux";
import { cambioCesfam } from "./../../store/actions/actions";
import { Link } from "react-router-dom";

import Titulo from "./../../components/titulo/Titulo.js";

import { socketSesion, socketNotificacion } from "../../service/socket";
import { NotificationManager } from "react-notifications";
import { handleError } from "./../../components/Helpers/helpers.js";

import { cambioNombreCesfam } from "./../../store/actions/actions";
import { logoutUser } from "./../../store/actions/actions";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useHistory } from "react-router-dom";

import LogoHS from "../../assets/img/logo_v2.svg";

import axios from "axios";
import imgUpperBar from "../../assets/css/upperBarNew.module.css";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import "./../../assets/css/upperbar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

function Upperbar({ cambioNombreCesfam, cambioCesfam, logoutUser }) {
  const history = useHistory();
  const [lista, setLista] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [rol, setRol] = useState("");
  const [cliente, setCliente] = React.useState(store.getState().cliente);
  const [cesfam, setCesfam] = React.useState(store.getState().cesfam);
  const [cesfamElegido, setCesfamElegido] = React.useState(store.getState().cesfam);
  const [usp, setUsp] = React.useState(store.getState().usp);
  const [nombreComuna, setNombreComuna] = React.useState("");
  const [nombreCesfam, setNombreCesfam] = React.useState("");
  const [tipoCesfam, setTipoCesfam] = React.useState("");
  const [imgCentro, setImgCentro] = React.useState(1);
  const [comunas, setComunas] = useState([]);
  const [tipo, setTipo] = React.useState(store.getState().tipo);
  const [idcomuna, setIdcomuna] = useState("");
  const [uspCesVisibles, setUspCesVisibles] = useState([]);
  const [TamCesVisible, setTamCesVisible] = useState([]);
  const [opcionesSelect, setOpcionesSelect] = React.useState([]);
  const [selectCesfam, setSelectCesfam] = useState(false);
  const [clickConfig, setClickConfig] = useState(false);
  const [clickNotifi, setClickNotifi] = useState(false);
  const [notificacionesSinLeer, setNotificacionesSinLeer] = useState(0);
  const [notificaciones, setNotificaciones] = useState([]);
  const [open, setOpen] = useState(true);
  const [conteoSolicitud, setconteoSolicitud] = useState(true);
  const [nombreCesfamNotificacion, setNombreCesfamNotificacion] = useState("");
  const [herramientas, setHerramientas] = React.useState(store.getState().herramientas);
  const [cesfamNotifi, SetCesfamNotifi] = React.useState();

  const traeNotificaciones = () => {
    axios
      .get(global.config.modulos.notificaciones + "por_usuario/", {
        headers: authHeader(),
        params: { usp: usp },
      })
      .then((res) => {
        if (res.data.estado) {
          setNotificaciones(res.data.datos);
          setNotificacionesSinLeer(res.data.datos.length);
        } else {
          setNotificaciones([]);
          setNotificacionesSinLeer(0);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const leerNotificacion = (notId) => {
    axios
      .patch(
        global.config.modulos.notificaciones + "por_usuario/",
        {
          nop_id: notId,
          nop_estado: 0,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        traeNotificaciones();
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const confLeerTodasNotificaciones = (usp) => {
    confirmAlert({
      title: "",
      message: "¿Deseas marcar todas las notificaciones como leídas?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            leerTodasNotificaciones(usp);
          },
        },
      ],
    });
  };

  const leerTodasNotificaciones = (usp) => {
    axios
      .patch(
        global.config.modulos.notificaciones + "leer_todas/",
        {
          usp: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        traeNotificaciones();
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const datosCesfam = () => {
    axios
      .get(global.config.modulos.cesfams, { headers: authHeader(), params: { cesid: cesfam } })
      .then((res) => {
        setImgCentro(res.data.ces_tipo);
        setNombreCesfam(res.data.ces_nombre);
        setCesfamElegido(cesfam + ";" + res.data.ces_comuna);
        if (res.data.ces_comuna === 4) {
          setNombreComuna("Cristo Vive");
        } else {
          setNombreComuna(res.data.com_nombre);
        }
        setTipoCesfam(res.data.tipo);
        cambioNombreCesfam(res.data.ces_nombre);
        if (idcomuna === "") {
          setIdcomuna(cliente);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const datosUsu = (usp) => {
    axios
      .get(global.config.modulos.usuariospanel + `por_id/` + usp, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data) {
          setUspCesVisibles(res.data["usp_cesfams_visibles"]);
          setTamCesVisible(res.data["usp_cesfams_visibles"].split(",").length);

          setUsuario(res.data["usp_nombre"] + " " + res.data["usp_apellido_p"]);
          setRol(res.data["rol"]);
          if (res.data["usp_tipo"] !== 2) {
            axios.get(global.config.modulos.cesfams + "cambio_comunas/", { headers: authHeader() }).then((resp) => {
              if (!resp.data.estado) {
                return;
              }
              setComunas(resp.data.datos);
            });
          }
        }
      });
  };

  const handleUpdate = (event) => {
    let cesfam = event.target.value.split(";");
    setCesfam(event.target.value);
    cambioCesfam(cesfam[0], cesfam[1]);

    var data = {
      usp_cesfam: cesfam[0],
      usp_comuna: cesfam[1],
    };
    let apiUrl = global.config.modulos.usuariospanel + "por_id/" + usp;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      window.location.reload();
    });
  };

  const clickSelectorCes = () => {
    setSelectCesfam(true);
    setClickConfig(false);
    setClickNotifi(false);
    if (selectCesfam == false) {
      obtenerCesfams(idcomuna);
    }
  };

  const handleMouseLeave = () => {
    setSelectCesfam(false);
  };

  const handleSelectChange = (event) => {
    setIdcomuna(event.target.value);
    obtenerCesfams(event.target.value);
  };

  const clickConfiguraciones = () => {
    setClickConfig(true);
    setSelectCesfam(false);
    setClickNotifi(false);
  };

  const handleMouseConfig = () => {
    setClickConfig(false);
  };

  const handleMousenoti = () => {
    setClickNotifi(false);
  };

  const clickNotifica = () => {
    setClickConfig(false);
    setSelectCesfam(false);
    setClickNotifi(true);
    traeNotificaciones();
  };

  function obtenerCesfams(comuna) {
    axios
      .get(global.config.modulos.cesfams + "cesfams_metricas/", {
        headers: authHeader(),
        params: { cesCom: comuna },
      })
      .then((res) => {
        const cesfamsVisibles = uspCesVisibles.split(",");
        var opciones = [];
        if (cesfamsVisibles.length <= 1) {
          return false;
        }
        res.data.datos.map((reg) => {
          if (cesfamsVisibles.includes(reg.ces_id.toString())) {
            opciones.push({
              value: reg.ces_id + ";" + reg.ces_comuna_id,
              nombre: reg.ces_nombre,
            });
          }
        });

        setOpcionesSelect(opciones);
      });
  }

  const btnCambiarClave = () => {
    window.location.href = "/cambiarClave";
  };

  useEffect(() => {
    datosUsu(usp);
    traeNotificaciones();
    socketSesion.emit("sessionAlive", { usuario_panel: usp });

    socketSesion.on("stayAlive", () => {
      socketSesion.emit("sessionAlive", { usuario_panel: usp });
    });

    socketSesion.on("mensajeUsp" + usp, (data) => {
      NotificationManager.info(data.mensaje);
    });

    socketSesion.on("cerrarSesion" + usp, () => {
      logoutUser();
    });
    socketSesion.on("mensajeUsp" + usp, (data) => {
      NotificationManager.info(data.mensaje);
    });

    socketNotificacion.on("notificacion", (data) => {
      if (data.uspId == usp) {
        // if (data.tipo == 2) {
        setOpen(true);
        document.getElementById("divModal").style.transitionDuration = "2s";
        document.getElementById("divModal").style.top = "5%";
        //}
        traeNotificaciones();
        SolicitudesEstado0();
      }
    });
  }, []);

  useEffect(() => {
    datosCesfam();
    SolicitudesEstado0();
  }, []);

  const salir = () => {
    document.getElementById("divModal").style.top = "-22%";

    cambioCesfam(cesfamNotifi, cliente);

    if (parseInt(cesfam) != cesfamNotifi) {
      var data = {
        usp_cesfam: cesfamNotifi,
        usp_password: "",
        usp_comuna: parseInt(cliente),
        usp_id: usp,
        gestorAcceso: "saltarGacceso",
      };
      let apiUrl = global.config.modulos.usuariospanel + "por_id/" + usp;
      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options)
        .then((res) => {
          history.push("/confirmacion_solicitud");
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      history.push("/confirmacion_solicitud");
    }
  };

  const SolicitudesEstado0 = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "notificacion_barra_superior/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
          cliente: cliente,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          setOpen(true);
          setconteoSolicitud(res.data.conteo);
          setNombreCesfamNotificacion(res.data.ces_nombre);
          if (herramientas.includes("H74")) {
            document.getElementById("divModal").style.transitionDuration = "2s";
            document.getElementById("divModal").style.top = "5%";

            if (!res.data.ids_cesfams.includes(parseInt(cesfam))) {
              SetCesfamNotifi(res.data.ids_cesfams[0]);
            } else {
              SetCesfamNotifi(cesfam);
            }
          }
        }
      });
  };

  const redirigirDesarrollo = (url) => {
    history.push(url);
  };

  return (
    <>
      <div className="upper-area">
        <div className={TamCesVisible == 1 ? imgUpperBar.titulobajoComuna : imgUpperBar.titulobajoMasComunas}>
          <Titulo className="upper-nombre-herramienta" links={[]} linkActual="" titulo="" descripcion="SassaSA" id="upper-nombre-herramienta" />
        </div>
        <img className="upper-logo" src={LogoHS}></img>
        {
          tipo !== 2 ? (
            <select className={imgUpperBar.btnComuna} value={idcomuna} onChange={handleSelectChange}>
              {comunas.map((com) => (
                <option className={imgUpperBar.bComuna} value={com.com_id}>
                  {com.cli_nombre}
                </option>
              ))}
            </select>
          ) : null
          /*  <div className={imgUpperBar.divspancomuna}>
            <span className={imgUpperBar.espanComuna}>{nombreComuna}</span>
          </div> */
        }
        <div className={imgUpperBar.upperselectcesfam} style={TamCesVisible == 1 ? { top: "1vh" } : { top: "2vh" }}>
          <label className={imgUpperBar.datosUsu}>
            {TamCesVisible == 1 && <p className={imgUpperBar.p1}>{nombreCesfam}</p>}
            <p className={TamCesVisible == 1 ? imgUpperBar.p2 : imgUpperBar.p1}>{usuario}</p>
            <p className={TamCesVisible == 1 ? imgUpperBar.p3 : imgUpperBar.p2}>{rol}</p>
          </label>
          <div className={imgUpperBar.divImg}>
            {TamCesVisible == 1 ? null : (
              <button className={imgUpperBar.imgCesfam} onClick={() => clickSelectorCes()}>
                {selectCesfam && (
                  <ClickAwayListener onClickAway={handleMouseLeave}>
                    <div className={imgUpperBar.divListaCesfam}>
                      <select className={imgUpperBar.selectCesfams} onChange={handleUpdate}>
                        <option
                          selected
                          disabled
                          style={{
                            color: "rgb(255, 153, 0)",
                            display: "none",
                          }}
                          className={imgUpperBar.optionsCesfam}
                        >
                          {nombreCesfam}
                        </option>
                        {opcionesSelect.map((ces) => (
                          <option className={imgUpperBar.optionsCesfam} selected={ces.value == cesfamElegido ? true : false} value={ces.value}>
                            {ces.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </ClickAwayListener>
                )}
              </button>
            )}
            <button
              className={imgUpperBar.imgConfig}
              onClick={() => {
                clickConfiguraciones();
              }}
            >
              {clickConfig && (
                <ClickAwayListener onClickAway={handleMouseConfig}>
                  <div className={imgUpperBar.divConfig}>
                    <button
                      className={imgUpperBar.butonCambioContr}
                      onClick={() => {
                        btnCambiarClave();
                      }}
                    >
                      Cambiar Contraseña
                    </button>

                    <button className={imgUpperBar.butonCerrarSesion} onClick={() => logoutUser()}>
                      Cerrar Sesion
                    </button>
                  </div>
                </ClickAwayListener>
              )}
            </button>
            <button
              className={imgUpperBar.imgNotificacion}
              onClick={() => {
                clickNotifica();
              }}
            >
              {clickNotifi && (
                <ClickAwayListener onClickAway={handleMousenoti}>
                  <div className={imgUpperBar.divNoti}>
                    <label className={imgUpperBar.labelNot}>Notificaciones</label>
                    <a style={{ cursor: "pointer", color: "#ea9f29" }} className={imgUpperBar.cerrarbtn} onClick={() => confLeerTodasNotificaciones(usp)}>
                      x
                    </a>
                    {notificaciones.map((not) => (
                      <>
                        <div className={imgUpperBar.div2noti} style={{ backgroundColor: not.color }}>
                          <p>
                            <a style={{ cursor: "pointer" }} className={imgUpperBar.cerrarbtn} onClick={() => leerNotificacion(not.nop_id)}>
                              x
                            </a>
                          </p>
                          <h1 className={imgUpperBar.h1noty}>{not.nop_nombre_vista}</h1>
                          <h2 className={imgUpperBar.h2noty}>{not.nop_fecha_registro.replace("T", " ")}</h2>
                          {not.nop_nombre_vista === "Confirmación de solicitud" ? (
                            <label
                              className={imgUpperBar.labelNoty}
                              style={{
                                whiteSpace: "pre-wrap",
                                textAlign: "initial",
                              }}
                            >
                              {not.nop_descripcion.split("<br/>").join("\n")}
                            </label>
                          ) : (
                            <label style={{ textAlign: "start" }} className={imgUpperBar.labelNoty}>
                              {not.nop_descripcion}
                            </label>
                          )}
                          {not.nop_nombre_vista === "Confirmación de solicitud" ? (
                            <div
                              style={{
                                display: "flex",
                                marginBottom: "5%",
                                justifyContent: "center",
                              }}
                            >
                              <a onClick={() => salir()} style={{ width: "25%" }}>
                                <label
                                  className={SolicitudTrabajo.btnSi_No_notificacion}
                                  style={{
                                    background: "transparent",
                                    border: "solid white 3px",
                                    color: "white",
                                    width: "100%",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Ir
                                </label>
                              </a>
                            </div>
                          ) : not.nop_nombre_vista === "Nuevo desarrollo" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginBottom: "5%",
                              }}
                            >
                              <a onClick={() => redirigirDesarrollo(not.nop_url)} style={{ width: "25%" }}>
                                <label
                                  className={SolicitudTrabajo.btnSi_No_notificacion}
                                  style={{
                                    background: "transparent",
                                    border: "solid white 3px",
                                    color: "white",
                                    width: "100%",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Ir
                                </label>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ))}
                  </div>
                </ClickAwayListener>
              )}
              {notificacionesSinLeer > 0 && <span className={imgUpperBar.spanNotSin}>{notificacionesSinLeer}</span>}
            </button>
          </div>
        </div>
      </div>
      {open && herramientas.includes("H74") ? (
        <div className={SolicitudTrabajo.popapSolicitudTrabajo} id="divModal">
          <div style={{ padding: "40px" }}>
            <h1 className={SolicitudTrabajo.h1Modal}>¡Atención!</h1>
            <p className={SolicitudTrabajo.p1Modal}>{conteoSolicitud == 1 ? "Tienes una solicitud pendiente para revisión del cesfam " + nombreCesfamNotificacion + "." : "Tienes " + conteoSolicitud + " solicitudes pendientes para revisión en " + nombreCesfamNotificacion + "."}</p>
          </div>
          <div>
            <button className={SolicitudTrabajo.btnModal} onClick={salir}>
              Ver
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default connect(null, { cambioNombreCesfam, cambioCesfam, logoutUser })(Upperbar);
