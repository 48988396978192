import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Button from "@material-ui/core/Button";
import "assets/css/graficosDM.css";
import "assets/css/usuarios.css";
import { store } from "store/index";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import GridContainer from "components/grid/GridContainer";
import { Grid } from "@material-ui/core";
import graf from "assets/css/graf.module.css";
import GraficoOferta from "../../../components/Graficos/Datos/graficoOferta";
import GraficoReservas from "../../../components/Graficos/Datos/graficoReservas";
import GraficoRecordatorio from "components/Graficos/Datos/graficoRecordatorio";
import { NotificationManager } from "react-notifications";
import Tooltip from "@material-ui/core/Tooltip";
import img from "../../../assets/img/icono calendario.svg";
import Titulo from "components/titulo/Titulo.js";
import signo from "assets/img/signo.svg";
export default class DatosGenerales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      oferta: [],
      ofertaFiltrado: [],
      filtro: "",
      fecha_inicio: "",
      fecha_termino: "",
      id_ces: store.getState().cesfam,
      nombreCesfam: store.getState().nombre_cesfam,
      filtrosFechas: true,
      mostrarTablas: false,
      mostrarOferta: false,
      modalOferta: false,
      modalReservas: false,
      modalRecordatorios: false,
    };
  }

  traerDatos = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "datos_generales/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info(res.data.error);
        } else {
          this.setState(
            { datos: [], mostrarTablas: false, mostrarOferta: false },
            () => {
              this.setState({
                datos: res.data.datos,
                mostrarTablas: true,
                mostrarOferta: true,
              });
            }
          );
        }
      });
  };

  traerDatosOferta = (i) => {
    axios
      .get(global.config.modulos.grafico_demanda + "datos_detalle_oferta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          tipo: i,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info(res.data.error);
        } else {
          this.setState(
            {
              oferta: [],
              modalOferta: false,
              modalReservas: false,
              modalRecordatorios: false,
            },
            () => {
              this.setState(
                {
                  oferta: res.data.datos,
                  ofertaFiltrado: res.data.datos,
                },
                () => {
                  if (i === 1) {
                    this.setState({
                      modalOferta: true,
                    });
                  } else if (i === 2) {
                    this.setState({
                      modalReservas: true,
                    });
                  } else {
                    this.setState({
                      modalRecordatorios: true,
                    });
                  }
                }
              );
            }
          );
        }
      });
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML =
      "Gestión de agendas<span title=''><img src=" + signo + "></img></span>";
  };

  actualizarFechaInicial = (fecha) => {
    this.setState({
      fecha_inicio: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
    });
  };

  actualizarFechaTerminal = (fecha) => {
    this.setState({
      fecha_termino: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
    });
  };

  Limpiar = () => {
    this.setState(
      {
        fecha_inicio: "",
        fecha_termino: "",
        filtrosFechas: false,
        mostrarTablas: false,
        mostrarOferta: false,
      },
      () => {
        this.setState({
          filtrosFechas: true,
        });
      }
    );
  };

  onclickModalOferta = (i) => {
    this.traerDatosOferta(i);
  };

  onclosModalOferta = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({
        modalOferta: false,
      });
    }
  };

  onclosModalReservas = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({
        modalReservas: false,
      });
    }
  };

  onclosModalRecordatorios = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({
        modalRecordatorios: false,
      });
    }
  };

  handleFiltroChange = (e) => {
    const filtro = e.target.value.toLowerCase();
    const ofertaFiltrado = this.state.oferta.filter((resultado) => {
      if (Object.keys(resultado)[0].toLowerCase().includes(filtro)) {
        return resultado;
      }
    });
    this.setState({ ofertaFiltrado: ofertaFiltrado, filtro: filtro }, () => {});
  };

  componentDidMount() {
    this.titulo();
  }

  render() {
    const { ofertaFiltrado } = this.state;
    return (
      <>
        <div className="middle-area">
          <GridContainer>
            <Grid style={{ display: "flex" }}>
              {this.state.filtrosFechas && (
                <Grid
                  style={{
                    display: "flex",
                    textAlign: "left",
                    background: "transparent",
                    color: "rgb(128, 128, 128)",
                    width: "10rem",
                    marginLeft: "12px",
                    borderRadius: "2px",
                    border: "2px solid orange",
                    fontSize: "1vw",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    placeholder="Fecha de inicio"
                    weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                    onChange={this.actualizarFechaInicial}
                    months={[
                      "Enero",
                      "Febrero",
                      "Marzo",
                      "Abril",
                      "Mayo",
                      "Junio",
                      "Julio",
                      "Agosto",
                      "Septiembre",
                      "Octtubre",
                      "Noviembre",
                      "Diciembre",
                    ]}
                    style={{
                      background: "transparent",
                      border: "none",
                      width: "100%",
                    }}
                    format="DD/MM/YYYY"
                  ></DatePicker>
                  <img
                    style={{
                      width: "17px",
                      position: "absolute",
                      right: "4%",
                      top: "19%",
                      zIndex: "-5",
                    }}
                    src={img}
                  ></img>
                </Grid>
              )}
              {this.state.filtrosFechas && (
                <Grid
                  style={{
                    display: "flex",
                    textAlign: "left",
                    background: "transparent",
                    color: "rgb(128, 128, 128)",
                    width: "10rem",
                    marginLeft: "12px",
                    borderRadius: "2px",
                    border: "2px solid orange",
                    fontSize: "1vw",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    placeholder="Fecha de Termino"
                    weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                    onChange={this.actualizarFechaTerminal}
                    months={[
                      "Enero",
                      "Febrero",
                      "Marzo",
                      "Abril",
                      "Mayo",
                      "Junio",
                      "Julio",
                      "Agosto",
                      "Septiembre",
                      "Octtubre",
                      "Noviembre",
                      "Diciembre",
                    ]}
                    style={{
                      background: "transparent",
                      border: "none",
                      width: "100%",
                    }}
                    format="DD/MM/YYYY"
                  />

                  <img
                    style={{
                      width: "17px",
                      position: "absolute",
                      right: "4%",
                      top: "19%",
                      zIndex: "-5",
                    }}
                    src={img}
                  ></img>
                </Grid>
              )}
              <Grid>
                <Button
                  style={{
                    marginLeft: "12px",
                    background: "#eaa028",
                    color: "white",
                    height: "2rem",
                  }}
                  onClick={() => {
                    this.traerDatos();
                  }}
                >
                  Buscar
                </Button>
                <Button
                  style={{
                    marginLeft: "12px",
                    background: "#40c6dd",
                    color: "white",
                    height: "2rem",
                  }}
                  onClick={() => {
                    this.Limpiar();
                  }}
                >
                  Limpiar
                </Button>
              </Grid>
            </Grid>
          </GridContainer>
          <br></br>
          <div style={{ overflow: "scroll", height: "80%" }}>
            <GridContainer>
              <Grid item xs={10} className={graf.grid1}>
                <Grid>
                  {this.state.mostrarTablas && (
                    <Button
                      className={graf.detalles}
                      onClick={() => this.onclickModalOferta(1)}
                    >
                      Ver detalles.
                    </Button>
                  )}
                </Grid>
                {this.state.mostrarTablas && (
                  <Grid className={graf.tablaGrid0}>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "11rem" }}>Oferta</th>
                          <th>Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.datos.map((item, index) => {
                          return (
                            <>
                              <tr key="1" className={graf.trBottom0}>
                                <Tooltip
                                  title={
                                    "Cupos de atención programados inicialmente por el Cesfam"
                                  }
                                >
                                  <td>Inicial</td>
                                </Tooltip>

                                <td>{item.oferta_inicial.toLocaleString()}</td>
                              </tr>
                              <tr key="2" className={graf.trBottom0}>
                                <Tooltip
                                  title={
                                    "Atención suspendida, principalmente por licencia médica, ausencia de profesional, reunión y otros. HoraSalud contacta a los pacientes para informar y reagendar la hora si existe disponibilidad en el Cesfam."
                                  }
                                >
                                  <td>Bloqueada</td>
                                </Tooltip>

                                <td>
                                  {item.oferta_bloqueada.toLocaleString()}
                                </td>
                              </tr>
                              <tr key="3">
                                <Tooltip
                                  title={
                                    "Cupos de atención que quedan realmente disponibles para los pacientes."
                                  }
                                >
                                  <td>Final</td>
                                </Tooltip>

                                <td>{item.oferta_final.toLocaleString()}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </Grid>
                )}
                {this.state.mostrarOferta && (
                  <GraficoOferta
                    cesfam={this.state.id_ces}
                    fecha_inicio={this.state.fecha_inicio}
                    fecha_termino={this.state.fecha_termino}
                  />
                )}
              </Grid>
            </GridContainer>
            <br></br>
            <GridContainer>
              <Grid item xs={10} className={graf.grid1}>
                <Grid>
                  {this.state.mostrarTablas && (
                    <Button
                      className={graf.detalles1}
                      onClick={() => this.onclickModalOferta(2)}
                    >
                      Ver detalles.
                    </Button>
                  )}
                </Grid>
                {this.state.mostrarTablas && (
                  <Grid className={graf.tablaGrid}>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "11rem" }}>Reservas</th>
                          <th>Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.datos.map((item, index) => {
                          return (
                            <>
                              <tr key="1" className={graf.trBottom}>
                                <Tooltip
                                  title={
                                    "Cupos de atención reservados por los pacientes para ser atendidos."
                                  }
                                >
                                  <td>Totales</td>
                                </Tooltip>
                                <td>{item.reservas.toLocaleString()}</td>
                              </tr>
                              <tr key="2" className={graf.trBottom}>
                                <Tooltip
                                  title={
                                    "Cupos de atención donde los pacientes No Se Presentaron (NSP), no anularon y esta se perdió sin posibilidad de reagendar a otro paciente."
                                  }
                                >
                                  <td>Pérdidas</td>
                                </Tooltip>
                                <td>
                                  {item.reservadas_perdidas.toLocaleString()}
                                </td>
                              </tr>
                              <tr key="3" className={graf.trBottom}>
                                <Tooltip
                                  title={
                                    "Cupos de atención anulados por los pacientes, los cuales permiten citar a nuevos pacientes aumentando la capacidad de atención del centro de salud al reutilizar la hora anulada."
                                  }
                                >
                                  <td>Anuladas </td>
                                </Tooltip>
                                <td>
                                  {item.reservadas_anuladas.toLocaleString()}
                                </td>
                              </tr>
                              <tr key="4" className={graf.trBottom}>
                                <Tooltip
                                  title={
                                    "Cupos de atención donde los pacientes se presentaron a su cita."
                                  }
                                >
                                  <td>Asistidas </td>
                                </Tooltip>

                                <td>
                                  {item.reservadas_asistidas.toLocaleString()}
                                </td>
                              </tr>
                              <tr key="5">
                                <Tooltip
                                  title={
                                    "Cupos de atención reservados por los pacientes que después fueron bloqueados."
                                  }
                                >
                                  <td>Bloqueadas </td>
                                </Tooltip>
                                <td>
                                  {item.reservadas_bloqueadas.toLocaleString()}{" "}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </Grid>
                )}
                {this.state.mostrarOferta && (
                  <GraficoReservas
                    cesfam={this.state.id_ces}
                    fecha_inicio={this.state.fecha_inicio}
                    fecha_termino={this.state.fecha_termino}
                  />
                )}
              </Grid>
            </GridContainer>
            <br></br>
            <GridContainer>
              <Grid item xs={10} className={graf.grid1}>
                <Grid>
                  {this.state.mostrarTablas && (
                    <Button
                      className={graf.detalles2}
                      onClick={() => this.onclickModalOferta(3)}
                    >
                      Ver detalles.
                    </Button>
                  )}
                </Grid>
                {this.state.mostrarTablas && (
                  <Grid className={graf.tablaGrid1}>
                    <table>
                      <thead>
                        <tr>
                          <th>Recordatorios</th>
                          <th>Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.datos.map((item, index) => {
                          return (
                            <>
                              <tr key="1" className={graf.trBottom1}>
                                <Tooltip
                                  title={
                                    "Todos los mensajes y llamadas informativas enviadas recurrentemente para que los pacientes recuerden sus horas reservadas, las confirmen o anulen su atención."
                                  }
                                >
                                  <td>Totales</td>
                                </Tooltip>

                                <td>{item.recordatorios.toLocaleString()}</td>
                              </tr>
                              <tr key="2" className={graf.trBottom1}>
                                <Tooltip
                                  title={
                                    "Los mensajes y llamadas informativas enviadas considerando Rut único para que los pacientes recuerden sus horas reservadas, las confirmen o anulen su atención."
                                  }
                                >
                                  <td>RUT únicos</td>
                                </Tooltip>

                                <td>
                                  {item.recordatorios_unicos.toLocaleString()}
                                </td>
                              </tr>
                              <tr key="3">
                                <Tooltip
                                  title={
                                    "Confirmación de asistencia del paciente considerando Rut único."
                                  }
                                >
                                  <td>Confirmados RUT únicos </td>
                                </Tooltip>
                                <td>
                                  {item.recordatorios_confirmados.toLocaleString()}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </Grid>
                )}
                {this.state.mostrarOferta && (
                  <GraficoRecordatorio
                    cesfam={this.state.id_ces}
                    fecha_inicio={this.state.fecha_inicio}
                    fecha_termino={this.state.fecha_termino}
                  />
                )}
              </Grid>
            </GridContainer>
          </div>
        </div>
        {this.state.modalOferta && (
          <Grid
            className={graf.modal3}
            onClick={(e) => this.onclosModalOferta(e)}
          >
            <Grid
              className={graf.modalcontent2}
              onClick={(e) => e.stopPropagation()}
            >
              <Grid style={{ position: "absolute", top: "5%", width: "95%" }}>
                <h3
                  style={{
                    color: "rgb(102, 102, 102)",
                    fontSize: "1.5rem",
                    position: "relative",
                    padding: "2%",
                  }}
                >
                  Detalle de la Oferta
                </h3>
              </Grid>
              <Grid>
                <input
                  className={graf.inputfilter}
                  style={{ position: "absolute", top: "18%", left: "4%" }}
                  type="text"
                  placeholder="Filtrar por tipo de atención"
                  onChange={this.handleFiltroChange}
                />
              </Grid>
              <Grid className={graf.tablaModalGrid}>
                <table>
                  <thead>
                    <tr>
                      <th>Tipo de atención</th>

                      <th>Oferta Inicial</th>

                      <th>Oferta Bloqueada</th>
                      <th>Oferta Final</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ofertaFiltrado.map((item, index) => {
                      const nombreConsulta = Object.keys(item)[0];
                      const datosConsulta = item[nombreConsulta];

                      return (
                        <tr key={index}>
                          <td>{nombreConsulta}</td>
                          <Tooltip
                            title={
                              "Cupos de atención programados inicialmente por el Cesfam"
                            }
                          >
                            <td>
                              {datosConsulta["oferta_inicial"].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Atención suspendida, principalmente por licencia médica, ausencia de profesional, reunión y otros. HoraSalud contacta a los pacientes para informar y reagendar la hora si existe disponibilidad en el Cesfam."
                            }
                          >
                            <td>
                              {datosConsulta[
                                "oferta_bloqueada"
                              ].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Cupos de atención que quedan realmente disponibles para los pacientes."
                            }
                          >
                            <td>
                              {datosConsulta["oferta_final"].toLocaleString()}
                            </td>
                          </Tooltip>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Grid>
        )}
        {this.state.modalReservas && (
          <Grid
            className={graf.modal3}
            onClick={(e) => this.onclosModalReservas(e)}
          >
            <Grid
              className={graf.modalcontent2}
              onClick={(e) => e.stopPropagation()}
            >
              <Grid style={{ position: "absolute", top: "5%", width: "95%" }}>
                <h3
                  style={{
                    color: "rgb(102, 102, 102)",
                    fontSize: "1.5rem",
                    position: "relative",
                    padding: "2%",
                  }}
                >
                  Detalles de las Reservas
                </h3>
              </Grid>
              <Grid>
                <input
                  className={graf.inputfilter}
                  style={{ position: "absolute", top: "18%", left: "4%" }}
                  type="text"
                  placeholder="Filtrar por tipo de atención"
                  onChange={this.handleFiltroChange}
                />
              </Grid>
              <Grid className={graf.tablaModalGrid}>
                <table>
                  <thead>
                    <tr>
                      <th>Tipo de atención</th>
                      <th>Reservas Totales</th>
                      <th>Reservas Pérdidas</th>
                      <th>Reservas Anuladas</th>
                      <th>Reservas Asistidas</th>
                      <th>Reservas Bloqueadas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ofertaFiltrado.map((item, index) => {
                      const nombreConsulta = Object.keys(item)[0];
                      const datosConsulta = item[nombreConsulta];

                      return (
                        <tr key={index}>
                          <td>{nombreConsulta}</td>
                          <Tooltip
                            title={
                              "Cupos de atención reservados por los pacientes para ser atendidos."
                            }
                          >
                            <td>
                              {datosConsulta["reservas"].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Cupos de atención donde los pacientes No Se Presentaron (NSP), no anularon y esta se perdió sin posibilidad de reagendar a otro paciente."
                            }
                          >
                            <td>
                              {datosConsulta[
                                "reservadas_perdidas"
                              ].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Cupos de atención anulados por los pacientes, los cuales permiten citar a nuevos pacientes aumentando la capacidad de atención del centro de salud al reutilizar la hora anulada."
                            }
                          >
                            <td>
                              {datosConsulta[
                                "reservadas_anuladas"
                              ].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Cupos de atención donde los pacientes se presentaron a su cita."
                            }
                          >
                            <td>
                              {datosConsulta[
                                "reservadas_asistidas"
                              ].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Cupos de atención reservados por los pacientes que después fueron bloqueados."
                            }
                          >
                            <td>
                              {datosConsulta[
                                "reservadas_bloqueadas"
                              ].toLocaleString()}
                            </td>
                          </Tooltip>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Grid>
        )}
        {this.state.modalRecordatorios && (
          <Grid
            className={graf.modal3}
            onClick={(e) => this.onclosModalRecordatorios(e)}
          >
            <Grid
              className={graf.modalcontent2}
              onClick={(e) => e.stopPropagation()}
            >
              <Grid style={{ position: "absolute", top: "5%", width: "95%" }}>
                <h3
                  style={{
                    color: "rgb(102, 102, 102)",
                    fontSize: "1.5rem",
                    position: "relative",
                    padding: "2%",
                  }}
                >
                  Detalles de los Recordatorios
                </h3>
              </Grid>
              <Grid>
                <input
                  className={graf.inputfilter}
                  style={{ position: "absolute", top: "18%", left: "4%" }}
                  type="text"
                  placeholder="Filtrar por tipo de atención"
                  onChange={this.handleFiltroChange}
                />
              </Grid>
              <Grid className={graf.tablaModalGrid}>
                <table>
                  <thead>
                    <tr>
                      <th>Tipo de atención</th>
                      <th>Recordatorios Totales</th>
                      <th>Recordatorios RUT Único</th>
                      <th>Recordatorios confirmados RUT único</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ofertaFiltrado.map((item, index) => {
                      const nombreConsulta = Object.keys(item)[0];
                      const datosConsulta = item[nombreConsulta];

                      return (
                        <tr key={index}>
                          <td>{nombreConsulta}</td>
                          <Tooltip
                            title={
                              "Todos los mensajes y llamadas informativas enviadas recurrentemente para que los pacientes recuerden sus horas reservadas, las confirmen o anulen su atención."
                            }
                          >
                            <td>
                              {datosConsulta["recordatorios"].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Los mensajes y llamadas informativas enviadas considerando Rut único para que los pacientes recuerden sus horas reservadas, las confirmen o anulen su atención."
                            }
                          >
                            <td>
                              {datosConsulta[
                                "recordatorios_unicos"
                              ].toLocaleString()}
                            </td>
                          </Tooltip>
                          <Tooltip
                            title={
                              "Confirmación de asistencia del paciente considerando Rut único."
                            }
                          >
                            <td>
                              {datosConsulta[
                                "recordatorios_confirmados"
                              ].toLocaleString()}
                            </td>
                          </Tooltip>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}
