// core components
import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "store/index";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import axios from "axios";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import Countdown from "react-countdown";

//JS
import ModalSolicitud from "../Dialog/ModalSolicitud";

// IMG
import imgObservacion from "assets/img/solicitud_trabajo/iconos/motivo.svg";

//CSS
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

export default class SolicitudesRealizadas extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.solicitudes();
  }

  solicitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";

    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
          tipo: 2,
          filtro: 1,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            todas_solicitudes: res.data.datos,
          });
        } else {
          this.setState({
            todas_solicitudes: [],
          });
        }
      });
  };

  irTicket = (cod) => {
    this.props.history.push({
      pathname: "/solicitud_trabajo",
      params: { cod },
    });
  };

  render() {
    return (
      <>
        <GridContainer style={{ height: "85%", width: "100%" }}>
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <div style={{ marginBottom: 100, overflowY: "scroll" }}>
                <Grid item xs={12} sm={12}>
                  <div className="divBordeTablaHistorialHoras" style={{ paddingLeft: "20px" }}>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: "Fecha de ingreso",
                          field: "fecha_solicitud",
                          cellStyle: {
                            padding: "5px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Fecha confirmación ticket",
                          field: "fecha_recepcion",
                          cellStyle: {
                            padding: "5px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "N° de Ticket",
                          render: (rowData) => (
                            <React.Fragment>
                              <p onClick={() => this.irTicket(rowData.sol_codigo)}>{rowData.sol_codigo}</p>
                            </React.Fragment>
                          ),
                          field: "sol_codigo",
                          cellStyle: {
                            padding: "5px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Nombre",
                          field: "sol_autoriza",
                          cellStyle: {
                            padding: "5px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "CESFAM",
                          field: "ces_nombre",
                          cellStyle: {
                            padding: "5px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Motivo",
                          render: (rowData) => (
                            <React.Fragment>
                              <textarea
                                className={SolicitudTrabajo.textAreaIngresado}
                                style={{ background: "transparent" }}
                                value={rowData.sol_motivo}
                                disabled></textarea>
                            </React.Fragment>
                          ),
                          field: "sol_motivo",

                          cellStyle: (rowData) => ({
                            width: "300px",
                            padding: "5px", // Aplica tus estilos aquí
                          }),
                        },

                        {
                          title: "Fecha de entrega",
                          render: (rowData) => (
                            <React.Fragment>{rowData.sol_entrega_aprox_cliente ? rowData.sol_entrega_aprox_cliente : "--"}</React.Fragment>
                          ),
                          field: "sol_entrega_aprox_cliente",
                          cellStyle: {
                            padding: "5px", // Aplica tus estilos aquí
                          },
                        },

                        {
                          title: "Estado",
                          render: (rowData) => (
                            <React.Fragment>
                              {rowData.sol_estado == 0
                                ? "En espera de aprobación de Cesfam"
                                : rowData.sol_estado == 1
                                ? "Rechazado por el Cesfam"
                                : rowData.sol_estado == 2
                                ? "Solicitud recibida por HoraSalud"
                                : rowData.sol_estado == 3
                                ? "Solicitud aprobada"
                                : rowData.sol_estado == 4
                                ? "Solicitud rechazada"
                                : rowData.sol_estado == 5
                                ? "Solicitud asignada a un ejecutivo"
                                : rowData.sol_estado == 6
                                ? "Solicitud asignada a un especialista en calidad"
                                : rowData.sol_estado == 7
                                ? "Entregada"
                                : rowData.sol_estado == 8
                                ? "Solicitud en pausa"
                                : rowData.sol_estado == 9
                                ? "Solicitud asignada a un especialista en calidad"
                                : null}
                            </React.Fragment>
                          ),
                          field: "sol_estado",
                          cellStyle: {
                            padding: "5px", // Aplica tus estilos aquí
                          },
                        },
                      ]}
                      data={this.state.todas_solicitudes}
                      options={{
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        rowStyle: (rowData) => ({
                          padding: 5,
                        }),
                      }}
                      localization={{
                        header: {
                          actions: "Acciones",
                        },
                        pagination: {
                          labelDisplayedRows: "{from}-{to} de {count}",
                          labelRowsSelect: "filas",
                          firstTooltip: "primera",
                          previousTooltip: "anterior",
                          nextTooltip: "siguiente",
                          lastTooltip: "última",
                        },
                        toolbar: {
                          nRowsSelected: "{0} fila(s) seleccionadas",
                          searchTooltip: "Buscar",
                          searchPlaceholder: "Buscar",
                        },
                        body: {
                          emptyDataSourceMessage: <span style={{ fontSize: "1.5rem" }}>No tiene tickets pendientes</span>,

                          filterRow: {
                            filterTooltip: "Buscar",
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>
              </div>
            </div>
          </GridItem>
        </GridContainer>

        {this.state.abrirModal && (
          <ModalSolicitud
            data={this.state.dataSolicitud}
            titulo={this.state.tituloModalSolicitud}
            salir={this.cerrarModal}
            vista={this.state.vistaModal}
            solicitudes={this.solicitudes}
            prioridades={this.state.prioridades}
            desarrolladores={this.state.desarrolladores}
          />
        )}
      </>
    );
  }
}
